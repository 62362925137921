<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>QUERY</em>文件内容验证
			</div>
			<div class="block-content">
				<p>
					<span>
						可进行文件哈希值校验以及含电子签名的PDF文件签名验签。
					</span>
				</p>
				<div class="tabList">
					<div :class="'tab '+(tabIndex == index ? 'tab-selected' : '')" v-for="(item,index) in tabList"
						:key="index" @click="tabIndex = index, fileName = ''">
						{{item}}
					</div>
				</div>
				<div class="query">
					<div class="query-box">
						<div class="query-file">
							<div class="file-name">
								{{fileName == '' ? (tabIndex == 0 ? '请选择需要校验的证据' : '请上传含电子签名的 PDF文件 (电子合同/签名证书等)') : fileName}}
							</div>
							<div class="file-button">选择文件</div>
							<input type="file" @change="fileChange" accept=".pdf">
						</div>
					</div>
					<div class="query-box" v-show="tabIndex == 0">
						<el-select v-model="hashType" placeholder="请选择哈希类型">
							<el-option v-for="(item,index) in hashTypeList" :key="index" :label="item"
								:value="item"></el-option>
						</el-select>
						<!-- <el-input type="text" v-model="author" placeholder="请输入著作权人"></el-input> -->
					</div>
					<!-- <div class="query-box" v-show="tabIndex == 0">
						<el-input type="text" v-model="authorCode" placeholder="请输入著作权人身份证号或社会统一信用代码"></el-input>
					</div> -->
					<div class="query-hint" v-show="tabIndex == 0">
						温馨提示：<br>
						1、上传需要校验的证据文件，选择需要校验的哈希类型。<br>
						2、点击开始校验。<br>
					</div>
					<div class="query-hint" v-show="tabIndex == 1">
						温馨提示：<br>
						1、验证内容为：包含电子签名的PDF文件，如电子合同、签名证书等文件的真实性和有效性。<br>
						2、验证方式为：上传PDF文件，点击开始验证。
						<!-- 3、通过验证的条件为：签名信息的查询结果真实可靠。 -->
					</div>
					<div class="query-button">
						<el-button type="primary" round @click="submit">{{tabIndex == 0 ? '开始校验' : '开始验证'}}</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- <el-dialog :visible="flag" title="安全验证" width="600px" @close="flag = false">
      <el-form ref="elForm" :model="verification" label-width="120px">
        <el-form-item prop="identity" label="身份证号:">
          <el-input type="text" v-model="verification.identity" placeholder="请输入身份证号后六位"></el-input>
        </el-form-item>
        <el-form-item prop="code" label="核验码:">
          <el-input type="text" v-model="verification.code" placeholder="请输入短信中的核验码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="submitVerificationInfo">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible="errorFlag" title="错误提示" width="400px" @close="errorFlag = false">
      <div class="error-type">
        <span v-show="errorType == 1">无法完成操作</span>
        <span v-show="errorType == 2">错误次数已达3次</span>
        <span v-show="errorType == 3">当日验证次数达到上限</span>
      </div>
      <div class="error-hint">
        <span v-show="errorType == 1">请输入验证信息</span>
        <span v-show="errorType == 2">今日禁止查询</span>
        <span v-show="errorType == 3">请明天再试</span>
      </div>
      <div class="error-button">
        <el-button type="primary" round @click="errorFlag = false">
          确定
        </el-button>
      </div>
    </el-dialog> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tabList: [
					'哈希值校验', '电子签名验证'
				],
				tabIndex: 0,
				keyword: '',
				flag: false,
				verification: {
					identity: '',
					code: ''
				},
				errorFlag: false,
				errorType: 1,
				fileName: '',
				author: '',
				authorCode: '',
				hashType: '',
				hashTypeList: [
					'SHA256', 'MD5', 'SHA1', 'SHA512', 'SHA3-224', 'SHA3-256', 'SHA3-384', 'SHA3-512', 'RIPEMD-160'
				],
				cache: {}
			}
		},
		methods: {
			submit() {
				if (this.tabIndex == 0) {
					if (this.fileName == '') {
						this.$message({
							message: '请选择需要校验的证据',
							duration: 2000,
							type: 'error'
						})
						return
					}
					if (this.hashType == '') {
						this.$message({
							message: '请选择哈希类型',
							duration: 2000,
							type: 'error'
						})
						return
					}
					this.submitDCIVerify()
				} else {
					if (this.fileName == '') {
						this.$message({
							message: '请上传含电子签名的 PDF文件 (电子合同/签名证书等)',
							duration: 2000,
							type: 'error'
						})
						return
					}
					this.submitPDFVerify()
				}
				this.$notify({
					type: 'warning',
					message: '文件上传需要一些时间，请您稍候...',
					onClose: () => {}
				})
			},
			submitDCIVerify() {
				let formData = this.formData
				formData.append('hashType', this.hashType)
				let dci_result = {
					hash: '',
					hashType: this.hashType,
					filename: this.fileName
				}
				this.$axios.post(this.$urlConfig.requestUrl + 'verificationApi/getFileHash', formData).then(res => {
					if (res.data.code == 200) {
						dci_result.hash = res.data.data.hash
						localStorage.setItem('dci_result', JSON.stringify(dci_result))
					} else {
						dci_result.hash = 'error'
						localStorage.setItem('dci_result', JSON.stringify(dci_result))
					}
					this.$router.push({
						path: '/centre/result-3'
					})
				}).catch(err => {
					dci_result.hash = 'timeout'
					localStorage.setItem('dci_result', JSON.stringify(dci_result))
					this.$router.push({
						path: '/centre/result-3'
					})
				})
			},
			async submitPDFVerify() {
				let result = ''
				// this.$axios.post('http://authenapi.bjzjtp.com/authen-sign/verify/pdfVerify', this.formData).then(res => {
				this.$axios.post(this.$urlConfig.requestUrl + '/verificationApi/checkPdf', this.formData).then(res => {
					if (res.data.code == 0) {
						let signInfo = res.data.signInfo
						localStorage.setItem('signInfo', JSON.stringify(signInfo))
						result = 'success'
					} else {
						result = 'error'
					}
					this.$router.push({
						path: '/centre/result-4?result=' + result
					})
				}).catch(err => {
					result = 'timeout'
					this.$router.push({
						path: '/centre/result-4?result=' + result
					})
				})

			},
			courtChange(e) {
				window.open(e)
			},
			submitVerificationInfo() {

			},
			fileChange(e) {
				let $target = e.target || e.srcElement
				let file = $target.files[0]
				let formData = new FormData()
				formData.append('file', file, file.name)
				this.fileName = file.name
				this.formData = formData
			}
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				p {
					width: 100%;
					line-height: 32px;
					font-size: @vue-font-size-sm;
					color: @vue-text-color-grey;

					span {
						text-indent: 2em;
						display: inline-block;
					}

					img {
						display: block;
						margin: @vue-spacing-col-lg auto;
					}
				}

				.tabList {
					width: 100%;
					height: 50px;
					display: flex;
					justify-content: space-between;
					margin: @vue-spacing-col-lg*2 0px @vue-spacing-col-lg 0px;
					background: #f6f8fc;
					border-radius: @vue-border-radius-base;

					.tab {
						width: 50%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
					}

					.tab-selected {
						border-radius: @vue-border-radius-base;
						background: #1a3071;
						color: @vue-text-color-gold;
					}
				}

				.query {
					width: 100%;
					background: url(../../../assets/centre-query-1.png) no-repeat;
					background-size: 100% 100%;
					padding: @vue-spacing-col-lg*3 @vue-spacing-row-lg*5 @vue-spacing-col-lg @vue-spacing-row-lg*5;
					box-sizing: border-box;

					.query-box,
					.query-hint,
					.query-button {
						width: 100%;
						margin-bottom: @vue-spacing-col-lg;
					}

					.query-file {
						width: 100%;
						height: 40px;
						display: flex;
						justify-content: space-between;
						border: 1px solid @vue-border-color;
						box-sizing: border-box;
						background: @vue-bg-color;
						border-radius: @vue-border-radius-base;
						position: relative;

						.file-name {
							width: calc(100% - 140px);
							height: 100%;
							display: flex;
							align-items: center;
							font-size: @vue-font-size-sm - 2;
							color: #c3c3c3;
							padding-left: @vue-spacing-row-base - 4;
							box-sizing: border-box;
						}

						.file-button {
							width: 140px;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							color: @vue-text-color-inverse;
							background: #1a3071;
							border-radius: 0px @vue-border-radius-base @vue-border-radius-base 0px;
							font-size: @vue-font-size-sm - 2;
						}

						input {
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0px;
							top: 0px;
							z-index: 9;
							opacity: 0;
						}
					}

					.query-box {
						.el-select {
							width: 100%;
						}
					}

					.query-hint {
						line-height: 32px;
						font-size: @vue-font-size-sm - 2;
						color: @vue-text-color-grey;
					}

					.query-button {
						display: flex;
						justify-content: center;

						.el-button {
							width: 300px;
						}
					}
				}

				.court {
					width: 100%;
					margin-top: @vue-spacing-col-lg;
					display: flex;
					flex-wrap: wrap;

					.court-item {
						width: 30%;
						height: 150px;
						background: url(../../../assets/centre-query-2.jpg) no-repeat;
						background-size: 100% 100%;
						margin-right: 5%;
						margin-bottom: @vue-spacing-col-base*2;
						padding: 0px @vue-spacing-row-lg @vue-spacing-col-lg @vue-spacing-row-lg;
						box-sizing: border-box;
						display: flex;
						align-items: flex-end;

						.el-select {
							width: 100%;
						}
					}

					.court-item:nth-child(3n) {
						margin-right: 0px;
					}
				}
			}
		}

		.error-type,
		.error-hint,
		.error-button {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.error-type {
			font-size: @vue-font-size-sm + 2;
			font-weight: bold;
			color: @vue-text-color-active;
		}

		.error-hint {
			color: @vue-text-color-grey;
			font-size: @vue-font-size-sm;
		}

		.error-button {
			height: 60px;
			align-items: flex-end;

			.el-button {
				width: 140px;
			}
		}
	}
</style>