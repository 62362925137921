<template>
	<div class="product">
		<div class="fixation">
			<div class="category">
				<div class="title">
					<span>司法进度</span>存证文件
				</div>
				<ul>
					<li :class="categoryIndex == index ? 'category-selected' : ''" v-for="(item,index) in category"
						:key="index" @click="categoryChange(index)">
						<div class="selected-line" v-show="categoryIndex == index"></div>
						<span>{{item.name}}</span>
					</li>
				</ul>
			</div>
			<div class="content">
				<div class="bread">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/centre' }">司法存证验证</el-breadcrumb-item>
						<el-breadcrumb-item>{{category[categoryIndex]['name']}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="article">
					<div class="operation">
						<el-button type="primary" @click="prePage" size="mini">上一页</el-button>
						<div class="pager">{{ pageNum }} / {{ pageTotalNum }}</div>
						<el-button type="primary" @click="nextPage" size="mini">下一页</el-button>
					</div>
					<pdf ref="pdf" :src="pdfUrl" :page="pageNum" :rotate="pageRotate" @password="password"
						@progress="loadedRatio = $event" @page-loaded="pageLoaded($event)"
						@num-pages="pageTotalNum = $event" @error="pdfError($event)" @link-clicked="page = $event">
					</pdf>
				</div>
			</div>
			<div class="form">
				<div class="form-title">
					期望还款方案
				</div>
				<div class="form-body">
					<el-form :model="caseForm" ref="caseForm" :rules="rules" label-position="top">
						<el-form-item prop="repayment_sum" label="期望还款总额">
							<el-input type="number" placeholder="请输入期望还款的总金额" v-model="caseForm.repayment_sum">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="repayment_cycle" label="期望还款周期">
							<el-input type="number" placeholder="请输入期望还款周期为多少个月" v-model="caseForm.repayment_cycle">
								<template slot="append">月</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="repayment_day" label="期望月还款日">
							<el-select v-model="caseForm.repayment_day" placeholder="请选择期望每个月哪天还款">
								<el-option v-for="i in 31" :label="i" :value="i"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item prop="repayment_num" label="计划月还款金额">
							<el-input type="number" placeholder="请输入计划每个月的还款金额" v-model="caseForm.repayment_num">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitCase">确定方案</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import pdf from 'vue-pdf'
	export default {
		name: 'product',
		components: {
			pdf
		},
		data() {
			return {
				category: [{
					name: '',
					url: ''
				}],
				categoryIndex: 0,
				pdfUrl: '',
				pageTotalNum: 1,
				pageNum: 1,
				loadedRatio: 0,
				curPageNum: 0,
				scale: 100,
				pageRotate: 0,
				page: 0,
				caseForm: {
					repayment_sum: '',
					repayment_cycle: '',
					repayment_day: '',
					repayment_num: ''
				},
				type: '',
				cid: '',
				rules: {
					repayment_sum: [{
						required: true,
						trigger: 'blur',
						message: '请输入期望还款的总金额'
					}],
					repayment_cycle: [{
						required: true,
						trigger: 'blur',
						message: '请输入期望还款周期为多少个月'
					}],
					repayment_day: [{
						required: true,
						trigger: 'change',
						message: '请选择期望每个月哪天还款'
					}],
					repayment_num: [{
						required: true,
						trigger: 'blur',
						message: '请输入计划每个月的还款金额'
					}]
				}
			}
		},
		methods: {
			pageLoaded(e) {
				this.$emit("current", e);
				this.curPageNum = e;
			},
			pdfError(error) {
				console.error(error);
			},
			password(updatePassword, reason) {
				updatePassword(prompt('password is "test"'));
				console.log("...reason...");
				console.log(reason);
				console.log("...reason...");
			},
			prePage() {
				var p = this.pageNum;
				p = p > 1 ? p - 1 : this.pageTotalNum;
				this.pageNum = p;
			},
			nextPage() {
				var p = this.pageNum;
				p = p < this.pageTotalNum ? p + 1 : 1;
				this.pageNum = p;
			},
			// 加载文件列表
			fetchData() {
				this.$verify.verify_financial_file({
					type: this.type,
					cid: this.cid
				}, res => {
					// if (res.code == 1) {
					if (res.code == 200) {
						this.category = res.data
						this.pdfUrl = res.data[0]['url']
						// this.category = [res.data]
						// this.pdfUrl = res.data.url
						this.$forceUpdate()
					}
				})
			},
			// 切换文件
			categoryChange(index) {
				this.pdfUrl = this.category[index]['url']
				this.categoryIndex = index
				this.$forceUpdate()
			},
			// 提交还款方案
			submitCase() {
				this.$refs['caseForm'].validate(valid => {
					if (valid) {
						let data = this.caseForm
						data['type'] = this.type
						data['cid'] = this.cid
						this.$verify.verify_financial_case(data, res => {
							// if (res.code == 1) {
							if (res.code == 200) {
								this.$message({
									message: '提交还款方案成功',
									type: 'success',
									duration: 2000
								})
							}
						})
					}
				})
			}
		},
		mounted() {
			this.type = this.$route.query.t
			this.cid = this.$route.query.id
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.product {
		width: 100%;
		padding: @vue-spacing-col-lg*2 0px;
		box-sizing: border-box;
		background: #f6f8fc;
	}

	.fixation {
		display: flex;
		justify-content: space-between;

		.category {
			width: 240px;

			.title {
				width: 100%;
				height: 50px;
				background: #1a3071;
				border-radius: @vue-border-radius-base @vue-border-radius-base 0px 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: @vue-text-color-inverse;
				font-size: @vue-font-size-sm;
				font-weight: bold;
				letter-spacing: 2px;

				span {
					color: #d3b071;
				}
			}

			ul {
				width: 100%;

				li {
					width: 100%;
					height: 50px;
					background: #ebeef5;
					font-size: @vue-font-size-sm;
					position: relative;
					margin-bottom: @vue-spacing-col-sm;

					.selected-line {
						width: 8px;
						height: 40px;
						position: absolute;
						left: 0px;
						top: 5px;
						background: #1a3071;
						z-index: 1;
						border-radius: 0px 8px 8px 0px;
					}

					span {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						color: @vue-text-color;
						cursor: pointer;
					}
				}

				.category-selected {
					color: #1a3071;
				}
			}
		}

		.content {
			width: calc(100% - 630px);
			height: 100%;
			background: url(../../assets/about-background.png);
			background-size: 100%;
			border-radius: @vue-border-radius-base;
			padding: 0px @vue-spacing-col-lg;
			box-sizing: border-box;
			box-shadow: 0px 0px 10px @vue-border-color;

			.bread {
				width: 100%;
				height: 50px;
				border-bottom: 1px solid @vue-border-color;
				display: flex;
				align-items: center;
			}

			.article {
				width: 100%;

				.operation {
					width: 100%;
					height: 70px;
					display: flex;
					justify-content: center;
					align-items: center;

					.pager {
						padding: 0px @vue-spacing-row-lg;
					}
				}
			}
		}

		.form {
			width: 300px;
			background: @vue-bg-color;
			border-radius: @vue-border-radius-base;
			box-shadow: 0px 0px 10px @vue-border-color;
			overflow: hidden;

			.form-title {
				width: 100%;
				height: 50px;
				padding: 0px @vue-spacing-col-lg;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				background: @vue-bg-color-grey;
				border-bottom: 1px solid @vue-border-color;
			}

			.form-body {
				width: 100%;
				padding: @vue-spacing-col-base @vue-spacing-col-lg 0px @vue-spacing-col-lg;
				box-sizing: border-box;

				.el-select,
				.el-button {
					width: 100%;
				}
			}
		}
	}
</style>
