<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>CONTENT</em>服务内容
			</div>
			<div class="block-content">
				<div class="list">
					<div class="item" v-for="(item,index) in list" :key="index">
						<div class="item-title">
							<div class="item-icon">
								{{index+1>9?index+1:'0'+(index+1)}}
							</div>
							{{item.title}}
						</div>
						<div class="item-word">
							{{item.word}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
						title: '电子数据固定和获取',
						word: '对存储介质或网络中现存的电子数据进行提取与固定，防止由于电子数据的易失性导致的电子数据被删除或修改，为案件侦破提供证据支持。包括计算机电子数据提取固定，存储介质数据提取固定，手机数据获取及固定等。'
					},
					{
						title: '电子数据分析与鉴定',
						word: '对存储介质中无法正常显示（如隐藏、修改文件类型、损坏等）的电子信息进行恢复，依据特定电子信息或记录的形成原理，通过存储介质中获得的信息或记录，判断行为人在计算机上曾经进行的操作。包括计算机数据深度挖掘，存储介质数据深度挖掘，计算机系统操作行为鉴定，手机信息数据恢复及固定等。'
					},
					{
						title: '信息系统分析与鉴定',
						word: '根据委托人要求，对信息系统的功能进行分析，确认信息系统是否具备某些功能，或者某项功能是否符合要求，并出具司法鉴定意见书或者报告。包括软件相似性鉴定，文件相似性鉴定，软件功能鉴定，计算机软件开发情况及程度鉴定，抓取网络数据包等。'
					},
					{
						title: '数据恢复',
						word: '对保存在涉案的电子数据设备里因各种原因丢失的电子数据进行抢救和恢复，提取相关数据，辅助案件侦破工作。包括逻辑故障,物理故障,磁盘阵列故障,数据库修复,文件修复,加密文件解密等。'
					},
					{
						title: '企业技术秘密存证鉴定',
						word: '为企业提供的电子数据调查服务。主要场景包括：企业商业秘密泄露、反舞弊、反欺诈、内部违规。采用取证手段固定和提取相关数据，出具司法鉴定意见书或取证报告（根据需要），用作企业后续采取法律手段的证据。'
					},
					{
						title: '数据采集和数据分析',
						word: '根据委托人要求，对台式机硬盘、笔记本硬盘、移动硬盘、服务器硬盘、服务器磁盘阵列、数据库、网络硬盘、网络数据、录音笔、数码照相机、数码摄像机、传真机、打印机、U盘、存储卡、光盘、SIM卡、手机、平板电脑以及GPS等电子存储介质和司法取证和数据分析。'
					},
					{
						title: '手机及计算机密码破解',
						word: '实验室手机及计算机密码解锁服务。'
					},
					{
						title: '一站式版权服务鉴定',
						word: '针对委托人的版权保护和侵权取证提供一站式服务，包含针对原始版权登记与数据固化、侵权监测分析、侵权远程取证勘验以及维权下架服务，以及侵权下架调解等，一站式版权维权服务鉴定。'
					},
					{
						title: '区块链合约鉴定',
						word: '针对公链的智能合约的约定以及履约情况规则进行鉴定与分析。'
					},
					{
						title: '数字资产交易流向鉴定',
						word: '协助公安机关针对数字资产在公链信息以及交易合约的鉴定与分析，针对委托人提供公链数据情况进行分析，针对提供的交易地址进行追溯分析以及取证，方便当事人进行立案分析使用。'
					}
				]
			}
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				.list {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					margin-top: @vue-spacing-col-lg;

					.item {
						width: 510px;
						height: 250px;
						margin-bottom: @vue-spacing-col-lg;
						padding: 0px @vue-spacing-row-lg @vue-spacing-col-lg @vue-spacing-row-lg;
						box-sizing: border-box;

						.item-title {
							width: 100%;
							height: 80px;
							display: flex;
							align-items: center;

							.item-icon {
								width: 50px;
								height: 100%;
								background: url(@/assets/guide-content-3.png) no-repeat;
								background-position: left center;
								display: flex;
								justify-content: center;
								align-items: center;
								color: @vue-text-color-inverse;
								font-size: @vue-font-size-sm + 4;
								font-weight: bold;
								margin-right: @vue-spacing-row-sm;
							}
						}

						.item-word {
							line-height: 26px;
						}
					}

					.item:nth-child(2n-1) {
						background: url(@/assets/guide-content-1.png) no-repeat;
						background-size: 100% 100%;
					}

					.item:nth-child(2n) {
						background: url(@/assets/guide-content-2.png) no-repeat;
						background-size: 100% 100%;
						color: @vue-text-color-inverse;
					}
				}
			}
		}
	}
</style>
