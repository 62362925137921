// 身份证
function verify_identity($str) {
	let reg = /(^([\d]{15}|[\d]{18}|[\d]{17}x|[\d]{17}X)$)/
	// let reg = /(^[1-9]\\d{5}(18|19|20)\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$)|(^[1-9]\\d{5}\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}$)/
	return reg.test($str)
}
// 邮箱
function verify_email($str) {
	let reg = /^[_\.0-9a-z-]+@([0-9a-z][0-9a-z-]+\.)+[a-z]{2,4}$/
	return reg.test($str)
}
// 邮编
function verify_zip($str) {
	let reg = /^[1-9]\d{5}$/
	return reg.test($str)
}
// 手机号
function verify_phone($str) {
	let reg = /^1[3456789]\d{9}$/
	return reg.test($str)
}
// 密码
function verify_password($str) {
	let reg = /^(\w){5,18}$/
	return reg.test($str)
}

export default {
	verify_identity: verify_identity,
	verify_email: verify_email,
	verify_zip: verify_zip,
	verify_phone: verify_phone,
	verify_password: verify_password
}
