<template>
	<div class="banner">
		<div class="swiper">
			<el-carousel height="540px">
				<el-carousel-item v-for="(item,index) in swiper" :key="index">
					<a :href="item.link" target="_blank"><img :src="item.carouselImg" alt=""></a>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="block-chain">
			<div class="block-chain-title">
				区块链存证记录
			</div>
			<div class="block-chain-number">
				<div class="item" v-for="(item,index) in blockChainHeight" :key="index">
					{{item}}
				</div>
			</div>
			<div class="block-chain-list">
				<div class="block-chain-list-title">
					<span>区块高度</span>
					<span>存证时间</span>
				</div>
				<ul>
					<li v-for="(item,index) in blockChainList" :key="index">
						<span>{{item.ledger_seq}}</span>
						<span>{{item.close_time}}</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['swiper', 'blockChainHeight', 'blockChainList'],
		name:'banner',
		data() {
			return {}
		}
	}
</script>

<style lang="less">
	.banner {
		width: 100%;
		height: 540px;
		position: relative;

		.swiper {

			a,
			img {
				width: 100%;
				height: 100%;
				display: block;
			}
		}

		.block-chain {
			width: 290px;
			height: 410px;
			background: @vue-bg-color;
			border-radius: @vue-border-radius-lg;
			position: absolute;
			right: calc((100vw - 1440px) / 2);
			z-index: 9;
			top: 65px;
			box-shadow: 0px 0px 10px @vue-border-color;
			overflow: hidden;

			.block-chain-title {
				width: 100%;
				height: 50px;
				background: linear-gradient(#3870e5, #0b1a53);
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: @vue-font-size-sm;
				color: @vue-text-color-gold;
			}

			.block-chain-number {
				width: 100%;
				height: 90px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: @vue-spacing-col-base;
				box-sizing: border-box;

				.item {
					width: 40px;
					height: 50px;
					background: url(@/assets/block-chain-number.png) no-repeat;
					background-size: 100% 100%;
					color: @vue-text-color-gold;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: @vue-font-size-sm + 2;
					margin-right: @vue-spacing-row-sm / 2;
				}

				.item:last-child {
					margin-right: 0px;
				}
			}

			.block-chain-list {
				width: 100%;
				height: calc(100% - 140px);

				.block-chain-list-title {
					width: 100%;
					height: 30px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0px @vue-spacing-row-sm + 4;
					box-sizing: border-box;
					background: url(@/assets/block-chain-link.png) no-repeat;
					background-position: center;
				}

				ul {
					width: 100%;
					height: calc(100% - 30px);

					li {
						width: 100%;
						height: calc(100% / 6);
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0px @vue-spacing-row-sm + 4;
						box-sizing: border-box;
						color: @vue-text-color-grey;
						font-size: @vue-font-size-sm - 2;
					}

					li:nth-child(2n - 1) {
						background: @vue-bg-color-grey;
					}
				}
			}
		}
	}
</style>
