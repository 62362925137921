<template>
	<div class="result">
		<div class="result-icon">
			<img src="../../../assets/centre-result-success.png" alt="" v-if="flag == 'success'">
			<img src="../../../assets/centre-result-fail.png" alt="" v-else-if="flag == 'error'">
			<img src="../../../assets/centre-result-timeout.png" alt="" v-else>
		</div>
		<div class="result-word">
			<span v-if="flag == 'success'" class="result-word-success">
				<i class="el-icon-success"></i>电子签名信息查询成功！<br>
				<!-- <i class="el-icon-success"></i>电子数据包完整性校验通过，数据未被篡改 -->
			</span>
			<span v-else-if="flag == 'error'" class="result-word-fail">
				<i class="el-icon-error"></i>未查询出有效的电子签名信息，请核实信息后再进行查询！
			</span>
			<span v-else="flag == 'timeout'" class="result-word-timeout">
				<i class="el-icon-warning"></i>对不起，查询超时，请稍后重试！
			</span>
		</div>
		<div class="info" v-if="flag == 'success'">
			<div class="info-block">
				<div class="info-title">
					<span></span>文件信息
				</div>
				<div class="info-text">
					<li>
						<span class="point"></span>
						您的文件共包含{{signList.length}}个签名，详细信息如下：
					</li>
				</div>
			</div>
			<div class="info-block" v-for="(item,index) in signList" :key="index">
				<div class="info-title">
					<span></span>签名信息
				</div>
				<div class="info-text">
					<li>
						<span class="point"></span>
						1.文档签名有效
					</li>
					<li>
						<span class="point"></span>
						2.签署时间：{{item.signedDate}}
					</li>
				</div>
				<div class="info-title">
					<span></span>证书信息
				</div>
				<div class="info-text">
					<li>
						<span class="point"></span>
						1.该证书的拥有者为：{{item.certOwner}}
					</li>
					<li>
						<span class="point"></span>
						2.该证书的颁发者为：{{item.certIssuer}}
					</li>
					<li>
						<span class="point"></span>
						3.该证书的有效期为：{{item.effectiveDate}}
					</li>
					<li class="benefit" v-if="item.certOwner != '北京中经天平科技有限公司'">
						<!-- <span class="point"></span> -->
						* 该结果仅基于验证文件本身，更多签名信息及签名内容解释，请自行联系证书颁发者进行了解
					</li>
				</div>
			</div>
			<!-- <div class="info-img">
        <img src="@/assets/sign.png" alt="">
      </div> -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				flag: '',
				signList: []
			}
		},
		methods: {
			fetchData() {
				this.signList = JSON.parse(localStorage.getItem('signInfo'))
			}
		},
		mounted() {
			this.flag = this.$route.query.result
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.result {
		width: 100%;
		padding-bottom: @vue-spacing-col-lg;
		box-sizing: border-box;

		.result-icon {
			width: 100%;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: flex-end;

			img {
				width: 149px;
			}
		}

		.result-word {
			width: 100%;
			height: 100px;
			text-align: center;
			margin-top: @vue-spacing-col-base;
			line-height: 36px;

			i {
				margin-right: @vue-spacing-row-sm;
				font-size: @vue-font-size-sm + 2;
			}

			.result-word-success {
				i {
					color: @vue-text-color-green;
				}
			}

			.result-word-fail {
				i {
					color: @vue-text-color-red;
				}
			}

			.result-word-timeout {
				i {
					color: orange;
				}
			}
		}

		.result-table {
			padding-bottom: @vue-spacing-col-lg;

			.check,
			a {
				color: @vue-text-color-active;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		.info {
			width: 100%;
			background: #f6f8fc;
			border-radius: @vue-border-radius-lg;
			padding: @vue-spacing-col-lg 0px;

			.info-block,
			.info-img {
				width: 620px;
				margin: 0px auto;
			}

			.info-block {
				.info-title {
					width: 100%;
					height: 50px;
					display: flex;
					align-items: center;
					font-size: @vue-font-size-sm + 4;
					font-weight: bold;
					border-bottom: 1px dashed @vue-bg-color-active;

					span {
						display: block;
						width: 4px;
						height: 24px;
						background: @vue-bg-color-active;
						margin-right: @vue-spacing-row-sm;
					}
				}

				.info-text {
					width: 100%;

					li {
						width: 100%;
						height: 50px;
						line-height: 30px;
						padding: 10px 0px;
						box-sizing: border-box;

						.point {
							width: 10px;
							height: 10px;
							background: @vue-bg-color-active;
							display: inline-block;
							border-radius: @vue-border-radius-circle;
							margin-right: @vue-spacing-row-sm;
						}
					}

					.benefit {
						color: orange;

						.point {
							background: orange;
						}
					}
				}
			}

			.info-img {
				background: #eef3fd;
				border: 1px dashed @vue-bg-color-active;
				box-sizing: border-box;
				margin-top: @vue-spacing-col-base;
				height: 165px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
</style>