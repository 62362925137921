<template>
	<div class="result">
		<div class="result-icon">
			<img src="../../../assets/centre-result-fail.png" alt="" v-if="obj.hash == 'error'">
			<img src="../../../assets/centre-result-timeout.png" alt="" v-else-if="obj.hash == 'timeout'">
			<img src="../../../assets/centre-result-success.png" alt="" v-else>
		</div>
		<div class="result-word">
			<span v-if="obj.hash == 'error'" class="result-word-fail">
				<i class="el-icon-error"></i>文件哈希获取失败
			</span>
			<span v-else-if="obj.hash == 'timeout'" class="result-word-timeout">
				<i class="el-icon-warning"></i>对不起，查询超时，请稍后重试！
			</span>
			<span v-else class="result-word-success">
				<i class="el-icon-success"></i>文件哈希获取成功<br>
			</span>
		</div>
		<div class="info" v-if="obj.hash!='error' && obj.hash != 'timeout'">
			<div class="line">
				<div class="label">文件名：</div>
				<div class="value">{{obj.filename}}</div>
			</div>
			<div class="line">
				<div class="label">哈希类型：</div>
				<div class="value">{{obj.hashType}}</div>
			</div>
			<div class="line">
				<div class="label">哈希信息：</div>
				<div class="value">{{obj.hash}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				obj: {
					hash: '',
					hashType: '',
					filename: ''
				}
			}
		},
		methods: {

		},
		created() {
			let dci_result = JSON.parse(localStorage.getItem('dci_result'))
			this.obj = dci_result
		}
	}
</script>

<style lang="less" scoped>
	.result {
		width: 100%;

		.result-icon {
			width: 100%;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: flex-end;

			img {
				width: 149px;
			}
		}

		.result-word {
			width: 100%;
			height: 100px;
			text-align: center;
			margin-top: @vue-spacing-col-base;
			line-height: 36px;

			i {
				margin-right: @vue-spacing-row-sm;
				font-size: @vue-font-size-sm + 2;
			}

			.result-word-success {
				i {
					color: @vue-text-color-green;
				}
			}

			.result-word-fail {
				i {
					color: @vue-text-color-red;
				}
			}

			.result-word-timeout {
				i {
					color: orange;
				}
			}
		}

		.result-table {
			padding-bottom: @vue-spacing-col-lg;

			.check,
			a {
				color: @vue-text-color-active;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		.info {
			width: 100%;
			padding: @vue-spacing-col-lg @vue-spacing-row-lg*3;
			box-sizing: border-box;

			.line {
				width: 100%;
				height: 60px;
				display: flex;
				justify-content: space-between;

				.label {
					width: 160px;
					height: 40px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}

				.value {
					width: calc(100% - 170px);
					height: 40px;
					display: flex;
					align-items: center;
					background: @vue-bg-color-grey;
					padding-left: @vue-spacing-row-sm;
					box-sizing: border-box;
					border-radius: @vue-border-radius-base;
					color: @vue-text-color-grey;
				}
			}

			.operation {
				width: 100%;
				height: 200px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: @vue-spacing-col-lg*2;
				box-sizing: border-box;

				.el-button {
					width: 43%;
					height: 50px;
					font-size: @vue-font-size-sm;
				}
			}
		}
	}
</style>