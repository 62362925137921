<template>
	<div class="product">
		<div class="fixation">
			<div class="category">
				<div class="title">
					<span>行业</span>案例
				</div>
				<ul>
					<li :class="categoryIndex == index ? 'category-selected' : ''" v-for="(item,index) in category"
						:key="index" @click="categoryChange(index)">
						<div class="selected-line" v-show="categoryIndex == index"></div>
						<span>{{item.name}}</span>
					</li>
				</ul>
			</div>
			<div class="content">
				<div class="bread">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>{{category[categoryIndex]['name']}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="article">
					<div class="block">
						<!-- <div class="block-title">
							<em>CASE</em>司法存证
						</div> -->
						<div class="block-content">
							<el-table ref="singleTable" :data="tableData" highlight-current-row
								@current-change="handleCurrentChange" style="width: 100%"
								:header-cell-style="{background:'#1a3071',color:'#FFFFFF'}">
								<el-table-column type="index" width="80" label="序号" align="center">
								</el-table-column>
								<el-table-column property="title" label="名称" align="center">
								</el-table-column>
								<el-table-column property="createAt" label="日期" width="300" align="center">
								</el-table-column>
							</el-table>
							<div class="pager">
								<el-pagination background layout="total, prev, pager, next, jumper"
									:total="pageConfig.total" :pager-count="11" :page-size="pageConfig.pageSize"
									:current-page="pageConfig.currentPage" @current-change="pageChange">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				category: [{
					name:''
				}],
				categoryIndex: 0,
				pageConfig: {
					total: 0,
					pageSize: 12,
					currentPage: 1
				},
				tableData: [],
				currentRow: {}
			}
		},
		methods: {
			handleCurrentChange(row) {
				this.currentRow = row
				this.$router.push({
					path: '/case/' + row.id
				})
			},
			pageChange(e) {
				this.pageConfig.currentPage = e
				this.fetchData()
			},
			async fetchData() {
				let {
					data
				} = await this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/queryIndustryCaseList', {
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage,
					category: this.category[this.categoryIndex]['id']
				})
				if (data.code == 200) {
					this.tableData = data.data.list
					this.pageConfig.total = data.data.total
				}
			},
			categoryChange(index) {
				this.categoryIndex = index
				this.pageConfig.currentPage = 1
				this.fetchData()
			},
			async fetchCategory() {
				let {
					data
				} = await this.$axios.get(this.$urlConfig.requestUrl + 'findSystemDictonaryList', {
					params: {
						dictType: 5
					}
				})
				if (data.code == 200) {
					this.category = data.data
					this.fetchData()
				}
			}
		},
		mounted() {
			this.categoryIndex = this.$route.query.id
			this.fetchCategory()
		}
	}
</script>

<style lang="less" scoped>
	.product {
		margin-top: @vue-spacing-col-lg;
	}

	.fixation {
		display: flex;
		justify-content: space-between;

		.category {
			width: 240px;

			.title {
				width: 100%;
				height: 50px;
				background: #1a3071;
				border-radius: @vue-border-radius-base @vue-border-radius-base 0px 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: @vue-text-color-inverse;
				font-size: @vue-font-size-sm;
				font-weight: bold;
				letter-spacing: 2px;

				span {
					color: #d3b071;
				}
			}

			ul {
				width: 100%;

				li {
					width: 100%;
					height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #ebeef5;
					font-size: @vue-font-size-sm;
					position: relative;
					color: @vue-text-color;
					margin-bottom: @vue-spacing-col-sm;
					cursor: pointer;

					.selected-line {
						width: 8px;
						height: 40px;
						position: absolute;
						left: 0px;
						top: 5px;
						background: #1a3071;
						z-index: 1;
						border-radius: 0px 8px 8px 0px;
					}
				}

				.category-selected {
					color: #1a3071;
				}
			}
		}

		.content {
			width: calc(100% - 290px);
			height: 100%;
			background: @vue-bg-color;
			border-radius: @vue-border-radius-base;
			padding: 0px @vue-spacing-col-lg;
			box-sizing: border-box;
			box-shadow: 0px 0px 10px @vue-border-color;
			margin-bottom: @vue-spacing-col-lg;

			.bread {
				width: 100%;
				height: 50px;
				border-bottom: 1px solid @vue-border-color;
				display: flex;
				align-items: center;
			}
		}
	}

	.block {
		width: 100%;
		padding-bottom: @vue-spacing-col-lg;

		.block-title {
			width: 100%;
			height: 80px;
			display: flex;
			align-items: center;
			color: #0a2242;
			font-size: @vue-font-size-base;

			em {
				font-size: @vue-font-size-lg + 8;
				font-family: '宋体';
				font-weight: bold;
				margin-right: @vue-spacing-row-sm;
			}
		}

		.block-content {
			.pager {
				width: 100%;
				height: 80px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}
		}
	}
</style>
