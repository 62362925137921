<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default{
		mounted() {
			this.$store.state.current = this.$route.path
		}
	}
</script>

<style lang="less">
	@import url("./public/variable.less");


	* {
		margin: 0px;
		padding: 0px;
		font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
	}

	ul,
	li {
		list-style: none;
	}

	a {
		text-decoration: none;
	}

	.fixation {
		width: 1440px;
		height: 100%;
		margin: 0 auto;
	}

	.scope-normal {
		color: @vue-text-color;
	}

	.scope-blue {
		color: @vue-text-color-active;
		text-decoration: underline;
		cursor: pointer;
	}

	.scope-grey {
		color: @vue-text-color-grey;
	}

	.scope-green {
		color: #53c41a;
	}

	.scope-red {
		color: #bd0101;
	}

	.scope-step {
		height: 100%;
		display: flex;
		align-items: center;

		img {
			width: 14px;
			height: 14px;
			margin-right: @vue-spacing-row-sm;
		}

		.scope-point {
			width: 14px;
			height: 14px;
			border-radius: @vue-border-radius-circle;
			margin-right: @vue-spacing-row-sm;
		}

		.scope-point-blue {
			background: @vue-bg-color-active;
		}

		.scope-point-green {
			background: @vue-bg-color-green;
		}

		.scope-point-red {
			background: @vue-bg-color-red;
		}

	}

	.scope-button {
		display: flex;

		.scope-button-item {
			cursor: pointer;
			margin-right: @vue-spacing-row-base;
		}

		.scope-button-grey {
			color: @vue-text-color-grey;
		}

		.scope-button-blue {
			color: @vue-text-color-active;
		}

		.scope-button-green {
			color: @vue-text-color-green;
		}

		.scope-button-red {
			color: @vue-text-color-red;
		}
	}

	.space-button {
		width: 100%;

		.space-button-item {
			width: 100%;
			height: 20px;
			display: flex;
			align-items: center;
			cursor: pointer;

			i {
				margin-right: @vue-spacing-row-sm / 2;
			}
		}

		.space-button-grey {
			color: @vue-text-color-grey;
		}

		.space-button-blue {
			color: @vue-text-color-active;
		}

		.space-button-green {
			color: @vue-text-color-green;
		}

		.space-button-red {
			color: @vue-text-color-red;
		}
	}

	.scope-image-breviary {
		display: block;
		height: 90px;
		display: flex;
		justify-content: center;
		img{
			height: 100%;
		}
	}

	.custom-scroll-bar::-webkit-scrollbar {
		width: 8px;
		height: 1px;
	}

	.custom-scroll-bar::-webkit-scrollbar-thumb {
		border-radius: 8px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: #E3E3E3;
	}

	.custom-scroll-bar::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 8px;
		background: #F3F3F3;
	}

	.page-container {
		width: 100%;
		height: 100%;
		background: @vue-bg-color;
		border-radius: @vue-border-radius-lg;
		box-shadow: 0px 0px 10px @vue-border-color;
	}
</style>
