<template>
	<div class="news-page">
		<div class="fixation">
			<div class="news-content">
				<div class="bread">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/news' }">新闻动态</el-breadcrumb-item>
						<el-breadcrumb-item>新闻详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="article">
					<div class="title">
						{{article.title}}
					</div>
					<div class="time-source">
						<span>
							发布日期：{{article.time}}
						</span>
						<span>
							作者：{{article.author}}
						</span>
					</div>
					<div class="rich-text">
						<div v-html="article.nodes"></div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				article: {
					title: '',
					time: '',
					author: '',
					nodes: ''
				},
				id: ''
			}
		},
		methods: {
			async fetchData() {
				let {
					data
				} = await this.$axios.get(this.$urlConfig.requestUrl + 'newsApi/newsDetail', {
					params: {
						id: this.id
					}
				})
				if (data.code == 200) {
					this.article = {
						title: data.data.newsTitle,
						time: data.data.createAt,
						author: data.data.creaeBy,
						nodes: data.data.content
					}
				}
			}
		},
		mounted() {
			this.id = this.$route.params.id
			this.fetchData()
		}
	}
</script>

<style lang="less">
	.news-page {
		width: 100%;
		padding: @vue-spacing-col-lg*2 0px;
		box-sizing: border-box;
		background: #f6f8fc;

		.fixation {
			display: flex;
			justify-content: space-between;

			.category {
				width: 240px;

				.title {
					width: 100%;
					height: 50px;
					background: #1a3071;
					border-radius: @vue-border-radius-base @vue-border-radius-base 0px 0px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: @vue-text-color-inverse;
					font-size: @vue-font-size-sm;
					font-weight: bold;
					letter-spacing: 2px;

					span {
						color: #d3b071;
					}
				}

				ul {
					width: 100%;

					li {
						width: 100%;
						height: 50px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #ebeef5;
						font-size: @vue-font-size-sm;
						position: relative;
						color: @vue-text-color;
						margin-bottom: @vue-spacing-col-sm;

						.selected-line {
							width: 8px;
							height: 40px;
							position: absolute;
							left: 0px;
							top: 5px;
							background: #1a3071;
							z-index: 1;
							border-radius: 0px 8px 8px 0px;
						}
					}

					.category-selected {
						color: #1a3071;
					}
				}
			}

			.news-content {
				// width: calc(100% - 290px);
				width: 100%;
				height: 100%;
				background: @vue-bg-color;
				border-radius: @vue-border-radius-base;
				padding: 0px @vue-spacing-col-lg;
				box-sizing: border-box;
				box-shadow: 0px 0px 10px @vue-border-color;

				.bread {
					width: 100%;
					height: 50px;
					border-bottom: 1px solid @vue-border-color;
					display: flex;
					align-items: center;
				}

				.article {
					width: 100%;

					.title {
						width: 100%;
						line-height: 42px;
						font-size: @vue-font-size-base+2;
						color: @vue-text-color;
						text-align: center;
						padding: @vue-spacing-col-base 0px;
						box-sizing: border-box;
					}

					.time-source {
						width: 100%;
						height: 50px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: @vue-font-size-sm - 2;
						border-bottom: 1px dashed @vue-border-color;

						span {
							color: @vue-text-color-grey;
						}
					}

					.rich-text {
						width: 100%;
						padding: @vue-spacing-col-base 0px @vue-spacing-col-lg*2 0px;
						box-sizing: border-box;
						text-indent: 2em;
						line-height: 1.5em;

						img {
							max-width: 100%;
							height: auto;
							display: block;
							margin: 1.5em auto;
						}
					}
				}
			}
		}
	}
</style>