<template>
	<div class="layout">
		<div class="layout-title">
			<div class="layout-logo">
				<img src="@/assets/logo.png" alt="">
				欢迎使用后台管理系统
			</div>
			<div class="layout-operation">
				<div class="layout-operation-item">
					<div class="layout-operation-icon" @click="openPasswordEdit()">
						<i class="el-icon-lock"></i>
					</div>
				</div>
				<div class="layout-operation-item">
					<div class="layout-operation-icon" @click="doLogout()">
						<i class="el-icon-switch-button"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="layout-main">
			<div class="layout-navigate">
				<div class="layout-router">
					<img src="@/assets/menu.png">
					导航菜单
				</div>
				<div class="layout-navigate-list">
					<div class="layout-navigate-item" v-for="(item,index) in navigate" :key="index">
						<router-link :to="item.path" :class="item.path==admNavigateIndex?'layout-item-select':''">
							<span class="icon_normal" v-if="item.path==admNavigateIndex"><i
									:class="item.icon"></i></span>
							<span class="icon_select" v-else><i :class="item.icon"></i></span>
							{{item.name}}
						</router-link>
					</div>
				</div>
			</div>
			<div class="layout-view">
				<router-view></router-view>
			</div>
		</div>
		<el-dialog title="修改用户密码" :visible="dialogVisible" @close="dialogVisible = false" width="600px">
			<el-form :model="ruleForm" ref="ruleForm" :rules="rule">
				<el-form-item label="新密码" prop="password">
					<el-input type="text" v-model="ruleForm.password" placeholder="请输入新密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="rptpassword">
					<el-input type="text" v-model="ruleForm.rptpassword" placeholder="请确认密码"></el-input>
				</el-form-item>
				<el-form-item>
					<div class="dialog-button">
						<el-button type="primary" plain @click="resetForm('ruleForm')">重置</el-button>
						<el-button type="primary" @click="submitForm('ruleForm')">修改密码</el-button>
					</div>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex'
	export default {
		name: 'layout',
		data() {
			let verify_password = (rule, value, callback) => {
				if (!this.$ver.verify_password(this.ruleForm.password)) {
					callback(new Error('请输入6-18位密码'))
				} else {
					callback()
				}
			}
			let verify_rptpassword = (rule, value, callback) => {
				if (!this.$ver.verify_password(this.ruleForm.rptpassword)) {
					callback(new Error('请输入6-18位密码'))
				} else if (this.ruleForm.password != this.ruleForm.rptpassword) {
					callback(new Error('两次输入的密码不一致'))
				} else {
					callback()
				}
			}
			return {
				navigate: [{
						path: '/admin/news',
						icon: 'el-icon-s-data',
						name: '新闻动态'
					},
					{
						path: '/admin/standard',
						icon: 'el-icon-user',
						name: '鉴定标准'
					},
					{
						path: '/admin/data',
						icon: 'el-icon-goods',
						name: '资料下载'
					},
					{
						path: '/admin/case',
						icon: 'el-icon-trophy',
						name: '行业案例'
					},
					{
						path: '/admin/certification',
						icon: 'el-icon-notebook-2',
						name: '资质证书'
					},
					{
						path: '/admin/clerk',
						icon: 'el-icon-user-solid',
						name: '调解员管理'
					},
					{
						path: '/admin/apply',
						icon: 'el-icon-menu',
						name: '鉴定申请'
					},
					{
						path: '/admin/message',
						icon: 'el-icon-s-check',
						name: '咨询留言'
					},
					{
						path: '/admin/paper',
						icon: 'el-icon-document',
						name: '报告申请'
					},
					{
						path: '/admin/swiper',
						icon: 'el-icon-trophy',
						name: '轮播图管理'
					},
					{
						path: '/admin/config',
						icon: 'el-icon-trophy',
						name: '网站设置'
					}
				],
				admNavigateIndex: '/admin/news',
				dialogVisible: false,
				ruleForm: {
					password: '',
					rptpassword: '',
				},
				rule: {
					password: [{
						required: true,
						trigger: 'blur',
						validator: verify_password
					}],
					rptpassword: [{
						required: true,
						trigger: 'blur',
						validator: verify_rptpassword
					}]
				},
			};
		},
		computed: {
			getUserInfo() {
				return this.$store.state.userInfo
			}
		},
		watch: {
			getUserInfo(userInfo) {
				this.username = userInfo.name
			},
			$route(to, from) {
				let path = to.path
				let array = ['/admin/news', '/admin/standard', '/admin/data', '/admin/case', '/admin/certification',
					'/admin/clerk',
					'/admin/apply', '/admin/message', '/admin/paper', '/admin/swiper', '/admin/config'
				]
				if (array.indexOf(path) >= 0) {
					this.admNavigateIndex = path
					this.$pub.setStorage('admNavigateIndex', path)
				}
			}
		},
		methods: {
			...mapMutations(['admLogout']),
			// 退出登录
			doLogout() {
				this.$confirm('请问是否确认退出登录？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					distinguishCancelAndClose: true,
					closeOnClickModal: false
				}).then(() => {
					this.$message({
						type: 'success',
						message: '退出登录成功',
						duration: 1500,
						onClose: () => {
							this.admLogout()
						}
					});
				}).catch(() => {});
			},
			// 用户信息
			fetchUserInfo() {
				this.$api.admGetUserInfo({}, res => {
					if (res.code == 200) {
						let userinfo = {
							name: res.data.realName
						}
					}
				})
			},
			// 打开修改密码
			openPasswordEdit() {
				this.dialogVisible = true
			},
			// 重置表单
			resetForm(formName) {
				this.$refs[formName].resetFields()
			},
			// 校验表单并保存
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.savePassword()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 提交修改密码
			savePassword() {
				this.$api.admEditPassword({
					againPassword: this.ruleForm.rptpassword,
					newPassword: this.ruleForm.password
				}, res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '修改成功，请重新登录',
							duration: 2000,
							onClose: () => {
								// this.admLogout()
							}
						})
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			// this.fetchUserInfo()
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			// 查询保存
			if (this.$pub.getStorage('admNavigateIndex')) {
				this.admNavigateIndex = this.$pub.getStorage('admNavigateIndex')
			}
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.layout {
		width: 100vw;
		height: 100vh;
		min-width: 1600px;
		min-height: 920px;

		.layout-title {
			width: 100%;
			height: 100px;
			display: flex;
			justify-content: space-between;

			.layout-logo {
				width: calc(100% - 160px);
				height: 100%;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base + 4;
				padding-left: @vue-spacing-row-base * 2;
				box-sizing: border-box;

				img {
					height: 40px;
					display: block;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.layout-operation {
				width: 160px;
				height: 100%;
				display: flex;
				justify-content: flex-end;

				.layout-operation-item {
					width: 80px;
					height: 100%;
					display: flex;
					align-items: center;

					.layout-operation-icon {
						width: 40px;
						height: 40px;
						color: @vue-text-color-grey;
						border-radius: @vue-border-radius-circle;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;

						i {
							font-size: @vue-font-size-base;
						}

						.layout-point {
							width: 8px;
							height: 8px;
							border-radius: @vue-border-radius-circle;
							background: @vue-bg-color-red;
							position: absolute;
							right: 5px;
							top: 5px;
						}
					}

					.layout-operation-icon:hover {
						background: @vue-bg-color-grey;
						cursor: pointer;
					}
				}
			}

		}

		.layout-main {
			width: 100%;
			height: calc(100% - 100px);
			display: flex;
			justify-content: space-between;
			background: linear-gradient(#edf3fa, #f7fbff);

			.layout-navigate {
				width: 200px;
				height: 100%;

				.layout-router {
					width: 100%;
					height: 80px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: @vue-font-size-base - 2;
					color: #7988ad;
					letter-spacing: @vue-font-size-min / 3;

					img {
						width: 22px;
						display: block;
						margin-top: 2px;
						margin-right: @vue-spacing-row-sm;
					}
				}

				.layout-navigate-list {
					width: 100%;

					.layout-navigate-item {
						width: 160px;
						height: 36px;
						margin: 0 auto;
						border-radius: 18px;
						overflow: hidden;
						margin-bottom: @vue-spacing-col-sm + 4;

						a {
							width: 100%;
							height: 100%;
							display: block;
							display: flex;
							align-items: center;
							font-size: @vue-font-size-sm - 2;
							color: @vue-text-color-grey;

							span {
								margin: 0px @vue-spacing-row-sm 0px @vue-spacing-row-base;

								i {
									margin-top: 3px;
									font-size: @vue-font-size-sm;
								}
							}

						}

						.layout-item-select {
							background: linear-gradient(to right, #00a6ff, #006dff);
							color: @vue-text-color-inverse;
						}
					}

					.layout-navigate-item:hover {
						background: linear-gradient(to right, #d1efff, #92d9ff);
					}
				}
			}

			.layout-view {
				width: calc(100% - 200px);
				height: 100%;
				padding: @vue-spacing-col-lg @vue-spacing-row-lg;
				box-sizing: border-box;
				overflow: auto;

				.admin-page {
					width: 100%;
					height: 100%;
					background: @vue-bg-color;
					border-radius: @vue-border-radius-lg;
					box-shadow: 0px 0px 10px @vue-border-color;
				}

			}
		}
	}
</style>
