import { render, staticRenderFns } from "./customTable.vue?vue&type=template&id=46d0714b&scoped=true&"
import script from "./customTable.vue?vue&type=script&lang=js&"
export * from "./customTable.vue?vue&type=script&lang=js&"
import style0 from "./customTable.vue?vue&type=style&index=0&id=46d0714b&prod&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d0714b",
  null
  
)

export default component.exports