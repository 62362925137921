<template>
	<div class="tools-fixed">
		<div class="tools-fixed-item slideLeft">
			<div class="tools-rectangle">
				<div class="cousult-icon">
					<i class="el-icon-phone"></i>
				</div>
				<div class="tools-value">
					{{config.phone}}
				</div>
			</div>
		</div>
		<div class="tools-fixed-item slideLeft">
			<div class="tools-rectangle">
				<div class="cousult-icon">
					<i class="el-icon-message"></i>
				</div>
				<div class="tools-value">
					{{config.email}}
				</div>
			</div>
		</div>
		<div class="tools-fixed-item qrcode">
			<div class="tools-square">
				<i class="el-icon-service"></i>
			</div>
			<div class="tools-qrcode">
				<img :src="config.erCodeTwo" alt="">
			</div>
		</div>
		<div class="tools-fixed-item qrcode">
			<div class="tools-square">
				<img src="@/assets/public-service.png" alt="">
			</div>
			<div class="tools-qrcode">
				<img :src="config.erCodeOne" alt="">
			</div>
		</div>
		<div class="tools-fixed-item" @click="backToTop">
			<div class="tools-square">
				<i class="el-icon-arrow-up"></i>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'tools',
		props: ['config'],
		data() {
			return {

			};
		},
		methods: {
			backToTop() {
				let scrollTop = window.pageYOffset
				let timer = setInterval(() => {
					scrollTop -= 40
					window.scrollTo(0, scrollTop)
					if (scrollTop <= 0) {
						clearInterval(timer)
					}
				},5)
				// document.body.scrollTop = 0;
				// document.documentElement.scrollTop = 0;
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	@keyframes slideLeft {
		from {
			width: 60px;
			right: 0px;
		}

		to {
			width: 230px;
			right: 170px;
		}
	}

	.tools-fixed {
		width: 60px;
		position: fixed;
		z-index: 99;
		top: 600px;
		right: 50px;
		box-shadow: 0px 0px 10px #D3D3D3;

		.tools-fixed-item {
			width: 100%;
			height: 60px;
			border-bottom: 1px solid #D3D3D3;
			cursor: pointer;
			color: #333333;
			background: #FFFFFF;
			position: relative;

			.tools-rectangle {
				width: 230px;
				height: 100%;
				display: flex;

				.cousult-icon {
					width: 60px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 30px;
					font-weight: bold;
				}

				.tools-value {
					width: calc(100% - 60px);
					height: 100%;
					display: flex;
					align-items: center;
				}
			}

			.tools-square {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 30px;
				font-weight: bold;

				img {
					width: 30px;
					height: 30px;
				}
			}
		}

		.tools-fixed-item:hover {
			background: #F9F9F9;
		}

		.slideLeft {
			overflow: hidden;
		}

		.slideLeft:hover {
			animation: slideLeft 0.5s forwards;
			box-shadow: 0px 0px 10px #D3D3D3;
		}

		.qrcode {
			.tools-qrcode {
				width: 120px;
				height: 120px;
				padding: 10px;
				box-sizing: border-box;
				background: #FFFFFF;
				box-shadow: 0px 0px 10px #D3D3D3;
				display: none;
				position: absolute;
				right: 60px;
				top: 0px;
				z-index: 99;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.qrcode:hover {
			.tools-qrcode {
				display: block;
			}
		}
	}
</style>