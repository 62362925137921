<template>
	<div class="article admin-page">
		<div class="article-left">
			<div class="category-title">
				栏目导航
			</div>
			<div class="category-tree">
				<el-tree :data="category" node-key="id" :default-checked-keys="[0]" :highlight-current="true"
					:props="defaultProps" @node-click="handleNodeClick"></el-tree>
			</div>
		</div>
		<div class="article-list">
			<custom-table :pageConfig="pageConfig" :multipleSelection="multipleSelection" :buttonGroup="buttonGroup"
				:filterValue="filterValue" :tableFilter="tableFilter" :tableConfig="tableConfig" :tableData="tableData"
				@pageChange="pageChange" @buttonClick="buttonClick" @filterChange="filterChange"
				@scopeButtonClick="scopeButtonClick">
			</custom-table>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'article',
		data() {
			return {
				category: [],
				categoryId: '',
				defaultProps: {
					children: 'childrens',
					label: 'name',
					value: 'id'
				},
				// 表格相关
				pageConfig: {
					totalWord: '全部数据',
					total: 0,
					pageSize: 13,
					currentPage: 1,
				},
				multipleSelection: [],
				buttonGroup: [{
					type: 'primary',
					size: 'mini',
					plain: true,
					icon: 'el-icon-plus',
					word: '添加文章',
					sign: 'new'
				}],
				filterValue: {},
				tableFilter: [],
				tableConfig: {
					border: true,
					selection: false,
					showOverflowTooltip: true,
					tableHeader: [{
							prop: 'newsTitle',
							label: '标题',
							width: 300
						},
						{
							prop: 'newsTypeName',
							label: '所属类别',
							width: 300
						},
						{
							prop: 'creaeBy',
							label: '作者',
							width: 200
						},
						{
							prop: 'createAt',
							label: '发布时间',
							width: 250
						}
					],
					tableEvent: [{
							icon: 'el-icon-edit',
							name: '编辑',
							color: 'blue',
							sign: 'edit'
						},
						{
							icon: 'el-icon-delete',
							name: '删除',
							color: 'red',
							sign: 'delete'
						}
					],
					emptyDescription: '当前没有数据',
				},
				tableData: []
			};
		},
		methods: {
			handleNodeClick(e) {
				this.categoryId = e.id
				this.pageConfig.currentPage = 1
				this.fetchData()
			},
			pageChange() {
				this.fetchData()
			},
			buttonClick(sign) {
				if (sign === 'new') {
					this.$router.push({
						path: '/admin/issue',
						query: {
							id: ''
						}
					})
				}
			},
			filterChange() {
				this.fetchData()
			},
			scopeButtonClick(row, sign) {
				if (sign === 'edit') {
					this.$router.push({
						path: '/admin/issue',
						query: {
							id: row.id
						}
					})
				} else {
					this.$confirm('确认要删除这个分类吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$api.newsDelete({
							id: row.id
						}, res => {
							if (res.code == 200) {
								this.fetchData()
								this.$message({
									message: '删除成功',
									type: 'success',
									duration: 2000
								})
							}
						})
					}).catch(() => {});
				}
			},
			fetchCategory() {
				this.$api.findSystemDictonaryList({
					dictType: 3
				}, res => {
					if (res.code == 200) {
						this.category = res.data
						this.categoryId = res.data[0].id
						this.fetchData()
					}
				})
			},
			fetchData() {
				this.$api.newsList({
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage,
					newsType: this.categoryId
				}, res => {
					if (res.code == 200) {
						this.pageConfig.total = res.data.total
						this.tableData = res.data.list
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchCategory()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.article {
		display: flex;
		justify-content: space-between;

		.article-left {
			width: 300px;
			border-right: 1px solid @vue-border-color;
			box-sizing: border-box;

			.category-title {
				width: 100%;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: @vue-font-size-sm + 2;
				border-bottom: 1px solid @vue-border-color;
				box-sizing: border-box;
			}

			.category-tree {
				width: 100%;
				height: calc(100% - 60px);
				padding: @vue-spacing-col-sm 0px 0px @vue-spacing-row-sm;
				box-sizing: border-box;
			}
		}

		.article-list {
			width: calc(100% - 300px);
			height: 100%;
		}
	}
</style>
