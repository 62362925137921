<template>
	<div class="issue admin-page custom-scroll-bar">
		<div class="block">
			<div class="block-title">
				标准信息
			</div>
			<div class="block-area">
				<div class="line">
					<div class="key">
						<span>*</span>
						标题：
					</div>
					<div class="value">
						<el-input v-model="title" placeholder="请输入文章标题"></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						编号：
					</div>
					<div class="value">
						<el-input v-model="code" placeholder="请输入编号"></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						生效日期：
					</div>
					<div class="value">
						<el-date-picker v-model="dateValue" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"
							placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
			</div>
		</div>
		<div class="block">
			<div class="block-title">
				标准内容
			</div>
			<div class="block-area">
				<div class="article-content">
					<div class="article-key">
						<span>*</span>内容：
					</div>
					<div class="article-value">
						<tinymce id="richtext" v-model="richText" :other_options="init"></tinymce>
					</div>
				</div>
			</div>
		</div>
		<div class="submit">
			<el-button type="primary" @click="saveInfo">保存</el-button>
			<el-button @click="reset">重置</el-button>
		</div>
	</div>
</template>

<script>
	import tinymce from 'vue-tinymce-editor'
	export default {
		name: 'edit',
		components:{
			tinymce
		},
		data() {
			return {
				id: '',
				title: '',
				code: '',
				dateValue: '',
				richText: '',
				flag: false,
				init: {
					language_url: "https://sfjdzx.org.cn/zh_CN.js", // 语言包的路径
					language: "zh_CN", //语言P
					height: 600, //编辑器高度
					// plugins: this.plugins,
					toolbar: [
						'searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample',
						'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'
					],
					branding: false,
					fontsize_formats: "8px 10px 12px 13px 14px 15px 16px 17px 18px 19px 20px 24px 36px",
					font_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings;NotoSansSC-Regular=NotoSansSC-Regular",
					automatic_uploads: true,
					images_upload_credentials: true,
					images_upload_handler: (blobInfo, success, failure, progress) => {
						var xhr, formData;
						xhr = new XMLHttpRequest();
						xhr.withCredentials = false;
						xhr.open('POST', this.$api.requestUrl + 'uploadImg');
					
						xhr.upload.onprogress = function(e) {
							progress(e.loaded / e.total * 100);
						}
					
						xhr.onload = function() {
							var json;
							if (xhr.status == 403) {
								failure('HTTP Error: ' + xhr.status, {
									remove: true
								});
								return;
							}
							if (xhr.status < 200 || xhr.status >= 300) {
								failure('HTTP Error: ' + xhr.status);
								return;
							}
							json = JSON.parse(xhr.responseText);
							if (!json || typeof json.data.fileUrl != 'string') {
								failure('Invalid JSON: ' + xhr.responseText);
								return;
							}
							success(json.data.fileUrl);
						};
					
						xhr.onerror = function() {
							failure('Image upload failed due to a XHR Transport error. Code: ' + xhr
								.status);
						}
					
						formData = new FormData();
						formData.append('file', blobInfo.blob(), blobInfo.filename());
					
						xhr.send(formData);
					}
				}
			};
		},
		methods: {
			fetchData() {
				this.$api.standardDetail({
					id: this.id
				}, res => {
					this.title = res.data.title
					this.code = res.data.standardNo
					this.dateValue = res.data.validAt
					this.richText = res.data.content
				})
			},
			saveInfo() {
				if (this.title == '') {
					this.$message.error('请输入标题')
					return
				}
				if (this.code == '') {
					this.$message.error('请输入编号')
					return
				}
				if (this.validAt == '') {
					this.$message.error('请输入生效日期')
					return
				}
				if (this.richText == '') {
					this.$message.error('请输入内容')
					return
				}
				this.$api.standardSave({
					title: this.title,
					standardNo: this.code,
					validAt: this.dateValue,
					content: this.richText,
					id: this.id
				}, res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: (this.id == '' ? '新增' : '编辑') + '文章成功！',
							duration: 1500,
							onClose: () => {
								this.$router.back()
							}
						});
					}
				})
			},
			reset() {
				this.title = ''
				this.code = ''
				this.dateValue = ''
				this.richText = ''
				this.id = ''
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.id = this.$route.query.id
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.$message({
				type: 'warning',
				message: '内容编辑器第一次加载需要时间，请稍候...'
			})
			if (this.id != '') {
				this.fetchData()
			} else {
				this.flag = true
			}
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.issue {
		overflow-y: auto;
	}

	.block {
		width: 100%;

		.block-title {
			width: 100%;
			height: 50px;
			background: @vue-bg-color-grey;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;
			display: flex;
			align-items: center;
		}

		.block-area {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;

			.line {
				width: 50%;
				height: 60px;
				display: flex;

				.key {
					width: 120px;
					height: 100%;
					display: flex;
					align-items: center;
					font-size: @vue-font-size-min + 2;

					span {
						color: @vue-text-color-red;
						font-size: @vue-font-size-base;
					}
				}

				.value {
					width: calc(100% - 150px);
					height: 100%;
					display: flex;
					align-items: center;

					.el-input,
					.el-cascader {
						width: 350px;
						margin-right: @vue-spacing-row-base;
					}
				}
			}

			.article-content {
				width: 100%;
				height: 720px;
				margin-top: @vue-spacing-col-base;
				display: flex;
				justify-content: space-between;

				.article-key {
					width: 100px;
					height: 100%;

					span {
						color: @vue-text-color-red;
					}
				}

				.article-value {
					width: calc(100% - 100px);
					height: 100%;
				}
			}

			.upload-image {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.upload-key {
					width: 100px;
					height: 100%;
				}

				.upload-value {
					width: calc(100% - 100px);
					height: 100%;

					.avatar-uploader {
						width: 178px;
						border: 1px dashed #d9d9d9;

						.el-upload {
							border: 1px dashed #d9d9d9;
							border-radius: 6px;
							cursor: pointer;
							position: relative;
							overflow: hidden;
						}

						.el-upload:hover {
							border-color: #409EFF;
						}
					}

					.avatar-uploader-icon {
						font-size: 28px;
						color: #8c939d;
						width: 178px;
						height: 178px;
						line-height: 178px;
						text-align: center;
					}

					.avatar {
						width: 178px;
						height: 178px;
						display: block;
					}
				}
			}
		}
	}

	.submit {
		width: 100%;
		margin-top: @vue-spacing-col-lg*2;
		margin-bottom: @vue-spacing-col-lg*2;
		display: flex;
		justify-content: center;

		.el-button {
			width: 200px;
		}

		.el-button:last-child {
			margin-left: @vue-spacing-row-lg*2;
		}
	}
</style>
