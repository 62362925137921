import axios from 'axios'
import config from '@/api/config.js'
import {
	Notification
} from 'element-ui'

const requestUrl = config.verifyUrl

const disposeError = (msg) => {
	Notification.error({
		title: '发生错误',
		message: msg,
		duration: 2000
	})
}

axios.defaults.timeout = 60000
axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
	let config = err.config
	if (!config || !config.retry) {
		return Promise.reject(err)
	}
})


// 统一请求方法
const sendPost = (url, data, type, callback) => {
	if (type == 'GET') {
		axios.get(requestUrl + url, {
			params: data
		}).then((res) => {
			if (res.data.code == 203) {
				disposeNologin()
			// } else if (res.data.code != 1) {
			} else if (res.data.code != 200) {
				disposeError(res.data.msg)
			}
			callback(res.data)
		}).catch((err) => {
			callback('err')
		})
	} else {
		axios.post(requestUrl + url, data).then((res) => {
			if (res.data.code == 203) {
				disposeNologin()
			// } else if (res.data.code != 1) {
			} else if (res.data.code != 200) {
				disposeError(res.data.msg)
			}
			callback(res.data)
		}).catch((err) => {
			callback('err')
		})
	}
}

// 金融调解验证
// 列表
const verify_financial_list = (data, callback) => sendPost('jdzxList', data, 'POST', callback)
// 存证信息
const verify_financial_cert = (data, callback) => sendPost('jdzxInfo', data, 'POST', callback)
// 校验核验码
const verify_financial_code = (data, callback) => sendPost('jdzxCode', data, 'POST', callback)
// 文件列表
const verify_financial_file = (data, callback) => sendPost('jdzxShow', data, 'POST', callback)
// 期望还款方案
const verify_financial_case = (data, callback) => sendPost('jdzxRepayment', data, 'POST', callback)
/* // 列表
const verify_financial_list = (data, callback) => sendPost('h5List', data, 'POST', callback)
// 存证信息
const verify_financial_cert = (data, callback) => sendPost('h5Info', data, 'POST', callback)
// 校验核验码
const verify_financial_code = (data, callback) => sendPost('h5Code', data, 'POST', callback)
// 文件列表
const verify_financial_file = (data, callback) => sendPost('h5Show', data, 'POST', callback)
// 期望还款方案
const verify_financial_case = (data, callback) => sendPost('h5Repayment', data, 'POST', callback)
 */
export default {
	verify_financial_list,
	verify_financial_cert,
	verify_financial_code,
	verify_financial_file,
	verify_financial_case
}