<template>
	<div class="admin-page">
		<custom-table :pageConfig="pageConfig" :multipleSelection="multipleSelection" :buttonGroup="buttonGroup"
			:filterValue="filterValue" :tableFilter="tableFilter" :tableConfig="tableConfig" :tableData="tableData"
			@pageChange="pageChange" @buttonClick="buttonClick" @filterChange="filterChange"
			@scopeButtonClick="scopeButtonClick">
		</custom-table>
		<el-dialog :visible="visibleFlag" title="上传文件" @close="closeDialog" width="600px">
			<el-form ref="fileForm" class="fileForm" :model="fileForm" label-width="120px">
				<el-form-item prop="fileName" label="资料名">
					<el-input v-model="fileForm.fileName" placeholder="请输入资料名"></el-input>
				</el-form-item>
				<el-form-item prop="file" label="上传文件">
					<el-upload class="avatar-uploader" :action="uploadFileUrl" :show-file-list="false"
						:on-success="handleDatumSuccess" :before-upload="beforeDatumUpload" :headers="{Authtoken:token}"
						accept=".pdf">
						<img class="avatar" src="@/assets/pdf.png" alt="" v-if="datumUrl!=''">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" round @click="saveFile">确认</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'data',
		data() {
			return {
				uploadFileUrl: '',
				pageConfig: {
					totalWord: '全部数据',
					total: 0,
					pageSize: 13,
					currentPage: 1,
				},
				multipleSelection: [],
				buttonGroup: [{
					type: 'primary',
					size: 'mini',
					plain: true,
					icon: 'el-icon-plus',
					word: '添加文件',
					sign: 'new'
				}],
				filterValue: {
					keyword: '',
					criteria: '',
					date: '',
					daterange: ''
				},
				tableFilter: [],
				tableConfig: {
					border: true,
					selection: false,
					showOverflowTooltip: true,
					activityMenu: false,
					tableHeader: [{
						prop: 'fileName',
						label: '资料名',
						width: 800
					}, {
						prop: 'createAt',
						label: '上传时间',
						width: 400
					}],
					tableEvent: [{
							icon: 'el-icon-edit',
							name: '编辑',
							color: 'blue',
							sign: 'edit'
						},
						{
							icon: 'el-icon-delete',
							name: '删除',
							color: 'red',
							sign: 'delete'
						}
					],
					emptyDescription: '当前没有数据',
				},
				tableData: [],
				visibleFlag: false,
				fileForm: {
					fileName: ''
				},
				datumUrl: '',
				fileName: '',
				fileId: '',
				token: ''
			};
		},
		methods: {
			// 上传资料
			handleDatumSuccess(res, file) {
				if (res.code == 200) {
					this.datumUrl = res.data.fileUrl
					this.fileName = file.name
				}
			},
			// 上传资料前
			beforeDatumUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 50;
				if (!isLt2M) {
					this.$message.error('上传文件大小不能超过 50MB!');
				}
				return isLt2M;
			},
			// 页面变化
			pageChange() {
				this.fetchData()
			},
			// 右上按钮
			buttonClick(sign) {
				if (sign === 'new') {
					this.visibleFlag = true
				}
			},
			// 筛选条件变化
			filterChange() {
				console.log(this.filterValue);
			},
			// 操作按钮
			scopeButtonClick(row, sign) {
				if (sign == 'edit') {
					this.fileId = row.id
					this.fileForm.fileName = row.fileName
					this.datumUrl = row.fileUrl
					this.visibleFlag = true
				}
				if (sign === 'delete') {
					this.deleteFile(row)
				}
			},
			fetchData() {
				this.$api.fileList({
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage
				}, res => {
					if (res.code == 200) {
						this.pageConfig.total = res.data.total
						this.tableData = res.data.list
					}
				})
			},
			deleteFile(row) {
				this.$confirm('确认要删除这个文件吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.fileDelete({
						id: row.id
					}, res => {
						if (res.code == 200) {
							this.fetchData()
							this.$message({
								message: '删除成功',
								type: 'success',
								duration: 2000
							})
						}
					})
				}).catch(() => {});
			},
			saveFile() {
				this.$api.fileSave({
					fileName: this.fileForm.fileName,
					fileUrl: this.datumUrl,
					id: this.fileId
				}, res => {
					if (res.code == 200) {
						this.closeDialog()
						this.fetchData()
						this.$message({
							message: '保存成功',
							type: 'success',
							duration: 2000
						})
					}
				})
			},
			closeDialog() {
				this.fileForm.fileName = ''
				this.datumUrl = ''
				this.fileId = ''
				this.visibleFlag = false
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.token = this.$pub.getStorage('adm_token')
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.uploadFileUrl = this.$api.requestUrl + 'uploadImg'
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped>
	.fileForm {
		.el-button {
			width: 200px;
		}

		.avatar-uploader .el-upload {
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 178px;
			height: 178px;
			line-height: 178px;
			text-align: center;
			border: 1px dashed #d9d9d9;
			box-sizing: border-box;
			border-radius: @vue-border-radius-base;
			background: #fbfdff;
		}

		.avatar-uploader-icon:hover {
			border-color: #409EFF;
		}

		.avatar {
			width: 178px;
			height: 178px;
			display: block;
		}
	}
</style>
