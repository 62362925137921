import { render, staticRenderFns } from "./way.vue?vue&type=template&id=49e623b3&scoped=true&"
import script from "./way.vue?vue&type=script&setup=true&lang=js&"
export * from "./way.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./way.vue?vue&type=style&index=0&id=49e623b3&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e623b3",
  null
  
)

export default component.exports