<template>
	<div class="config admin-page custom-scroll-bar">
		<div class="title">
			网站信息设置
		</div>
		<el-form ref="configForm" :model="configForm" label-position="left" label-width="150px">
			<el-form-item prop="address" label="公司地址">
				<el-input type="text" placeholder="请输入公司地址" v-model="configForm.address"></el-input>
			</el-form-item>
			<el-form-item prop="gps" label="公司GPS">
				<div class="line">
					<el-input type="number" placeholder="请输入经度" v-model="configForm.longitude"></el-input>
					<el-input type="number" placeholder="请输入纬度" v-model="configForm.latitude"></el-input>
				</div>
			</el-form-item>
			<el-form-item prop="phone" label="电话">
				<el-input type="text" placeholder="请输入联系电话" v-model="configForm.phone"></el-input>
			</el-form-item>
			<el-form-item prop="email" label="邮箱">
				<el-input type="email" placeholder="请输入邮箱" v-model="configForm.email"></el-input>
			</el-form-item>
			<el-form-item prop="line" label="交通线路">
				<div class="line" v-for="(item, index) in configForm.line" :key="index">
					<el-input type="text" placeholder="请输入交通线路" v-model="item.detail"></el-input>
					<el-button type="primary" plain circle size="mini" icon="el-icon-minus" @click="removeLine(index)"
						v-if="index>0">
					</el-button>
				</div>
				<el-button class="add-link" type="primary" plain size="mini" @click="addLine">增加交通方式</el-button>
			</el-form-item>
			<el-form-item prop="serviceCode" label="公众号二维码">
				<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
					:on-success="handleServiceSuccess" :before-upload="beforeAvatarUpload">
					<img v-if="configForm.serviceCode!=''" :src="configForm.serviceCode" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<el-form-item prop="staffCode" label="企业微信客服">
				<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
					:on-success="handleStaffSuccess" :before-upload="beforeStaffUpload">
					<img v-if="configForm.staffCode!=''" :src="configForm.staffCode" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<!-- <el-form-item prop="jwgaba" label="京网公安备案">
				<el-input type="text" placeholder="请输入京网公安备案" v-model="configForm.jwgaba"></el-input>
			</el-form-item>
			<el-form-item prop="jwxb" label="京网信备">
				<el-input type="text" placeholder="请输入京网信备" v-model="configForm.jwxb"></el-input>
			</el-form-item> -->
			<el-form-item prop="icp" label="冀ICP备">
				<el-input type="text" placeholder="请输入冀ICP备案号" v-model="configForm.icp"></el-input>
			</el-form-item>
			<el-form-item prop="friendLink" label="友情链接">
				<div class="line" v-for="(item, index) in configForm.friendLink" :key="index">
					<el-input type="text" placeholder="友情链接名称" v-model="item.name"></el-input>
					<el-input type="text" placeholder="友情链接地址" v-model="item.link"></el-input>
					<el-switch v-model="item.follow" active-text="允许追踪" inactive-text="禁止追踪">
					</el-switch>
					<el-button type="primary" plain circle size="mini" icon="el-icon-minus"
						@click="removeFriendLink(index)" v-if="index>0">
					</el-button>
				</div>
				<el-button class="add-link" type="primary" plain size="mini" @click="addFriendLink">增加友情链接</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" plain @click="resetForm">重置</el-button>
				<el-button type="primary" @click="saveInfo">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		name: 'config',
		data() {
			return {
				configForm: {
					address: '',
					longitude: '',
					latitude: '',
					phone: '',
					email: '',
					line: [{
						detail: ''
					}],
					serviceCode: '',
					staffCode: '',
					jwgaba: '',
					jwxb: '',
					icp: '',
					friendLink: [{
						name: '',
						link: '',
						follow: false
					}]
				},
				uploadUrl: ''
			};
		},
		methods: {
			resetForm() {
				this.configForm = {
					address: '',
					longitude: '',
					latitude: '',
					phone: '',
					email: '',
					line: [{
						detail: ''
					}],
					serviceCode: '',
					staffCode: '',
					jwgaba: '',
					jwxb: '',
					icp: '',
					friendLink: [{
						name: '',
						link: '',
						follow: false
					}]
				}
			},
			saveInfo() {
				this.$api.saveConfig({
					address: this.configForm.address,
					coordinate: this.configForm.longitude + ',' + this.configForm.latitude,
					phone: this.configForm.phone,
					email: this.configForm.email,
					line: JSON.stringify(this.configForm.line),
					erCodeOne: this.configForm.serviceCode,
					erCodeTwo: this.configForm.staffCode,
					recordNoJwga: this.configForm.jwgaba,
					recordNoJwxb: this.configForm.jwxb,
					recordNoJicp: this.configForm.icp,
					friendlyLink: JSON.stringify(this.configForm.friendLink),
					id: 'zjtp'
				}, res => {
					if (res.code == 200) {
						this.$message({
							message: '保存设置成功',
							duration: 2000,
							type: 'success'
						})
						this.fetchData()
					}
				})
			},
			addLine() {
				this.configForm.line.push({
					detail: ''
				})
			},
			removeLine(index) {
				this.configForm.line.splice(index, 1)
			},
			addFriendLink() {
				this.configForm.friendLink.push({
					name: '',
					link: '',
					follow: false
				})
			},
			removeFriendLink(index) {
				this.configForm.friendLink.splice(index, 1)
			},
			handleServiceSuccess(res) {
				if (res.code == 200) {
					this.configForm.serviceCode = res.data.fileUrl
				}
			},
			beforeAvatarUpload(file) {
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isLt5M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isLt5M;
			},
			handleStaffSuccess(res) {
				if (res.code == 200) {
					this.configForm.staffCode = res.data.fileUrl
				}
			},
			beforeStaffUpload(file) {
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isLt5M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isLt5M;
			},
			fetchData() {
				this.$api.configDetail({
					id: 'zjtp'
				}, res => {
					if (res.code == 200) {
						let gps = res.data.coordinate.split(',')
						this.configForm = {
							address: res.data.address,
							longitude: gps[0],
							latitude: gps[1],
							phone: res.data.phone,
							email: res.data.email,
							line: JSON.parse(res.data.line),
							serviceCode: res.data.erCodeOne,
							staffCode: res.data.erCodeTwo,
							jwgaba: res.data.recordNoJwga,
							jwxb: res.data.recordNoJwxb,
							icp: res.data.recordNoJicp,
							friendLink: JSON.parse(res.data.friendlyLink)
						}
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {

		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.uploadUrl = this.$api.requestUrl + 'uploadImg'
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.config {
		overflow-y: auto;
		padding: @vue-spacing-col-base @vue-spacing-row-base;
		box-sizing: border-box;

		.title {
			width: 100%;
			height: 50px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid @vue-border-color;
			font-size: @vue-font-size-base;
			font-weight: bold;
			margin-bottom: 20px;
		}
	}

	.avatar-uploader .el-upload {
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
		border: 1px dashed #d9d9d9;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.el-input {
		width: 400px;
	}

	.line {
		width: 100%;
		display: flex;
		align-items: center;
		margin-top: 20px;

		.el-input,.el-switch {
			margin-right: 20px;
		}
	}

	.line:first-child {
		margin: 0px;
	}

	.add-link {
		margin-top: 20px;
	}
</style>
