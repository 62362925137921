import axios from 'axios'
import config from '@/api/config.js'
import {
	Notification
} from 'element-ui'
import store from '../store/index.js'
import pubFunction from '../public/index.js'

const requestUrl = config.requestUrl

// 非必须token接口
const optionalApi = [

]
// 后台接口
const adminApi = [
	'findSystemDictonaryList',
	'findCarouselList',
	'addOrUpdateCarousel',
	'deleteCarousel',
	'findMediatorList',
	'addOrUpdateMediator',
	'deleteMediator',
	'findAppraisalList',
	'deleteAppraisal',
	'updateAppraisalStatus',
	'updateAppraisalStatusList',
	'findNewsList',
	'deleteNews',
	'findNewsById',
	'addOrUpdateNews',
	'findArchiveList',
	'addOrUpdateArchive',
	'deleteArchive',
	'findStandardList',
	'addOrUpdateStandard',
	'deleteStandard',
	'findStandardById',
	'findCaseInfoList',
	'addOrUpdateCaseInfo',
	'deleteCaseInfo',
	'findCaseInfoById',
	'findConsultingList',
	'deleteConsulting',
	'updateConsultingStatus',
	'updateAllConsultingStatus',
	'addOrUpdateCompany',
	'findCompanyById',
	'findQualificationsList',
	'deleteQualifications',
	'addOrUpdateQualifications'
]
// 本地token验证
const doRequest = (api, data, type, callback) => {
	let token = ''
	// 必需token验证
	if (adminApi.indexOf(api) >= 0) {
		token = pubFunction.getStorage('adm_token')
		if (!token) {
			disposeNologin()
			return false
		} else {
			axios.defaults.headers.common["Authtoken"] = token
		}
	}
	let url = requestUrl + api
	sendPost(url, data, type, callback)
}
// 统一处理登录失效方法
const disposeNologin = () => {
	resetHeader()
	Notification.error({
		title: '发生错误',
		message: '当前登录状态已失效，请您重新登录。',
		duration: 2000,
		onClose: () => {
			store.commit('admLogout')
		}
	})
}
const resetHeader = () => {
	delete axios.defaults.headers.common['Authtoken']
}
const disposeError = (msg) => {
	Notification.error({
		title: '发生错误',
		message: msg,
		duration: 2000
	})
}

// 超时时间
axios.defaults.timeout = 60000
axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
	let config = err.config
	if (!config || !config.retry) {
		return Promise.reject(err)
	}
})

// 统一请求方法
const sendPost = (url, data, type, callback) => {
	if (type == 'GET') {
		axios.get(url, {
			params: data
		}).then((res) => {
			if (res.data.code == 203) {
				disposeNologin()
			} else if (res.data.code != 200) {
				disposeError(res.data.msg)
			}
			callback(res.data)
		}).catch((err) => {})
	} else {
		axios.post(url, data).then((res) => {
			if (res.data.code == 203) {
				disposeNologin()
			} else if (res.data.code != 200) {
				disposeError(res.data.msg)
			}
			callback(res.data)
		}).catch((err) => {
			console.log(err)
		})
	}
}

// 管理端登陆
const admlogin = (data, callback) => doRequest('login', data, 'POST', callback)
// 字典接口
const findSystemDictonaryList = (data, callback) => doRequest('findSystemDictonaryList', data, 'GET', callback)
// 轮播图列表
const bannerList = (data, callback) => doRequest('findCarouselList', data, 'POST', callback)
// 添加或修改轮播图
const addBanner = (data, callback) => doRequest('addOrUpdateCarousel', data, 'POST', callback)
// 删除轮播图
const deleteBanner = (data, callback) => doRequest('deleteCarousel', data, 'GET', callback)
// 调解员列表
const mediatorList = (data, callback) => doRequest('findMediatorList', data, 'POST', callback)
// 添加或修改调解员
const addMediator = (data, callback) => doRequest('addOrUpdateMediator', data, 'POST', callback)
// 删除调解员
const deleteMediator = (data, callback) => doRequest('deleteMediator', data, 'GET', callback)
// 鉴定申请列表
const applyList = (data, callback) => doRequest('findAppraisalList', data, 'POST', callback)
// 删除申请
const applyDelete = (data, callback) => doRequest('deleteAppraisal', data, 'GET', callback)
// 修改状态
const applyDispose = (data, callback) => doRequest('updateAppraisalStatus', data, 'GET', callback)
// 标记全部已处理
const applyDisposeAll = (data, callback) => doRequest('updateAppraisalStatusList', data, 'GET', callback)
// 咨询列表
const consultList = (data, callback) => doRequest('findConsultingList', data, 'POST', callback)
// 咨询删除
const consultDelete = (data, callback) => doRequest('deleteConsulting', data, 'GET', callback)
// 修改状态
const consultDispose = (data, callback) => doRequest('updateConsultingStatus', data, 'GET', callback)
// 标记全部已处理
const consultDisposeAll = (data, callback) => doRequest('updateAllConsultingStatus', data, 'GET', callback)
// 新闻动态
const newsList = (data, callback) => doRequest('findNewsList', data, 'POST', callback)
// 删除新闻
const newsDelete = (data, callback) => doRequest('deleteNews', data, 'GET', callback)
// 详情
const newsDetail = (data, callback) => doRequest('findNewsById', data, 'GET', callback)
// 保存
const newsSave = (data, callback) => doRequest('addOrUpdateNews', data, 'POST', callback)
// 资料下载
const fileList = (data, callback) => doRequest('findArchiveList', data, 'POST', callback)
// 新增&编辑
const fileSave = (data, callback) => doRequest('addOrUpdateArchive', data, 'POST', callback)
// 删除
const fileDelete = (data, callback) => doRequest('deleteArchive', data, 'GET', callback)
// 鉴定标准
const standardList = (data, callback) => doRequest('findStandardList', data, 'POST', callback)
// 新增&编辑
const standardSave = (data, callback) => doRequest('addOrUpdateStandard', data, 'POST', callback)
// 删除
const standardDelete = (data, callback) => doRequest('deleteStandard', data, 'GET', callback)
// 详情
const standardDetail = (data, callback) => doRequest('findStandardById', data, 'GET', callback)
// 案例
const caseList = (data, callback) => doRequest('findCaseInfoList', data, 'POST', callback)
// 新增&编辑
const caseSave = (data, callback) => doRequest('addOrUpdateCaseInfo', data, 'POST', callback)
// 删除
const caseDelete = (data, callback) => doRequest('deleteCaseInfo', data, 'GET', callback)
// 详情
const caseDetail = (data, callback) => doRequest('findCaseInfoById', data, 'GET', callback)
// 网站设置
const saveConfig = (data, callback) => doRequest('addOrUpdateCompany', data, 'POST', callback)
// 获取详情
const configDetail = (data, callback) => doRequest('findCompanyById', data, 'GET', callback)
// 鉴定资质
const certification = (data, callback) => doRequest('findQualificationsList', data, 'POST', callback)
// 删除资质
const certifyDelete = (data, callback) => doRequest('deleteQualifications', data, 'GET', callback)
// 保存资质
const certifySave = (data, callback) => doRequest('addOrUpdateQualifications', data, 'POST', callback)
// 纸质报告申请
const paperList = (data, callback) => doRequest('findPaperRecordList', data, 'POST', callback)
// 删除
const paperDelete = (data, callback) => doRequest('deletePaperRecord', data, 'GET', callback)
// 修改状态
const paperDispose = (data, callback) => doRequest('updatePaperRecordStatus', data, 'GET', callback)
// 全部处理
const paperDisposeAll = (data, callback) => doRequest('updatePaperRecordsStatusList', data, 'GET', callback)

export default {
	resetHeader,
	requestUrl,
	admlogin,
	findSystemDictonaryList,
	bannerList,
	addBanner,
	deleteBanner,
	mediatorList,
	addMediator,
	deleteMediator,
	applyList,
	applyDelete,
	applyDispose,
	applyDisposeAll,
	consultList,
	consultDelete,
	consultDispose,
	consultDisposeAll,
	newsList,
	newsDelete,
	newsDetail,
	newsSave,
	fileList,
	fileSave,
	fileDelete,
	standardList,
	standardSave,
	standardDelete,
	standardDetail,
	caseList,
	caseSave,
	caseDelete,
	caseDetail,
	saveConfig,
	configDetail,
	certification,
	certifyDelete,
	certifySave,
	paperList,
	paperDelete,
	paperDispose,
	paperDisposeAll
}