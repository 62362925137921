<template>
	<div class="product">
		<div class="fixation">
			<div class="category">
				<div class="title">
					<span>关于</span>我们
				</div>
				<ul>
					<li :class="categoryIndex == index ? 'category-selected' : ''" v-for="(item,index) in category"
						:key="index" @click="categoryIndex = index">
						<div class="selected-line" v-show="categoryIndex == index"></div>
						<router-link :to="item.path">{{item.name}}</router-link>
					</li>
				</ul>
			</div>
			<div class="content">
				<div class="bread">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>{{category[categoryIndex]['name']}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="article">
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'product',
		data() {
			return {
				category: [{
						name: '中心简介',
						path: '/about/desc'
					},
					{
						name: '资质证书',
						path: '/about/license'
					},
					{
						name: '联系我们',
						path: '/about/contact'
					}
				],
				categoryIndex: 0
			}
		},
		watch: {
			$route(to, from) {
				let path = to.path
				this.disposeRouter(path)
			}
		},
		methods:{
			disposeRouter(path){
				let array = ['/about/desc', '/about/license', '/about/contact']
				let index = array.indexOf(path)
				if (index >= 0) {
					this.categoryIndex = index
					this.$pub.setStorage('aboutIndex', index)
				}
			}
		},
		created() {
			this.disposeRouter(this.$route.path)
		}
	}
</script>

<style lang="less" scoped>
	.product {
		width: 100%;
		padding: @vue-spacing-col-lg*2 0px;
		box-sizing: border-box;
		background: #f6f8fc;
	}

	.fixation {
		display: flex;
		justify-content: space-between;

		.category {
			width: 240px;

			.title {
				width: 100%;
				height: 50px;
				background: #1a3071;
				border-radius: @vue-border-radius-base @vue-border-radius-base 0px 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: @vue-text-color-inverse;
				font-size: @vue-font-size-sm;
				font-weight: bold;
				letter-spacing: 2px;

				span {
					color: #d3b071;
				}
			}

			ul {
				width: 100%;

				li {
					width: 100%;
					height: 50px;
					background: #ebeef5;
					font-size: @vue-font-size-sm;
					position: relative;
					margin-bottom: @vue-spacing-col-sm;

					.selected-line {
						width: 8px;
						height: 40px;
						position: absolute;
						left: 0px;
						top: 5px;
						background: #1a3071;
						z-index: 1;
						border-radius: 0px 8px 8px 0px;
					}

					a {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						color: @vue-text-color;
					}
				}

				.category-selected {
					color: #1a3071;
				}
			}
		}

		.content {
			width: calc(100% - 290px);
			height: 100%;
			background: url(../../assets/about-background.png);
			background-size: 100%;
			border-radius: @vue-border-radius-base;
			padding: 0px @vue-spacing-col-lg;
			box-sizing: border-box;
			box-shadow: 0px 0px 10px @vue-border-color;

			.bread {
				width: 100%;
				height: 50px;
				border-bottom: 1px solid @vue-border-color;
				display: flex;
				align-items: center;
			}

			.article {
				width: 100%;
			}
		}
	}
</style>
