<template>
	<div class="result">
		<div class="result-loading" v-if="flag == 0">
			<div class="loading-icon" v-loading="flag==0"></div>
			<div class="loading-word">查询中，请稍候...</div>
		</div>
		<div class="result-content" v-else>
			<div class="result-empty" v-if="flag == 2">
				<div class="result-icon">
					<img src="../../../assets/centre-result-fail.png" alt="">
				</div>
				<div class="result-word">
					<span class="result-word-fail">
						<i class="el-icon-error"></i>信息不匹配或查询不到数据，请重新核对数据后查询
					</span>
				</div>
			</div>
			<div class="result-empty" v-if="flag == 3">
				<div class="result-icon">
					<img src="../../../assets/centre-result-timeout.png" alt="">
				</div>
				<div class="result-word">
					<span class="result-word-timeout">
						<i class="el-icon-warning"></i>对不起，查询超时，请稍后重试！
					</span>
				</div>
			</div>
			<div class="result-have" v-if="flag == 1 && tableData.length > 0">
				<div class="result-icon">
					<img src="../../../assets/centre-result-success.png" alt="">
				</div>
				<div class="result-word">
					<span class="result-word-success">
						<i class="el-icon-success"></i>电子证据已在司法区块链存证<br>
						<i class="el-icon-success"></i>电子数据包完整性校验通过，数据未被篡改
					</span>
				</div>
				<div class="result-table">
					<el-table :data="tableData" border :header-cell-style="{background:'#1a3071',color:'#FFFFFF'}"
						stripe style="width: 100%">
						<el-table-column prop="to_title" label="存证主体" width="250">
						</el-table-column>
						<el-table-column prop="source" label="数据来源" width="250">
						</el-table-column>
						<el-table-column prop="addtime" label="存证时间" width="250">
						</el-table-column>
						<el-table-column prop="security_lvl" label="验证" width="100">
							<template v-slot="scope">
								<slot :row="scope.row" :column="scope.column">
									<span>{{scope.row.security_lvl == 1 ? '不需验证' : '需要验证'}}</span>
								</slot>
							</template>
						</el-table-column>
						<el-table-column label="存证信息" width="100">
							<template v-slot="scope">
								<slot :row="scope.row" :column="scope.column">
									<div class="check" @click="rowClick(scope.row)">
										查看
									</div>
								</slot>
							</template>
						</el-table-column>
						<el-table-column label="存证文件">
							<template v-slot="scope">
								<slot :row="scope.row" :column="scope.column">
									<div class="check" @click="checkFile(scope.row)">
										存证文件
									</div>
								</slot>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<el-dialog :visible="visibleFlag" @close="visibleFlag = false">
			<div class="line">
				<div class="label">存证编号：</div>
				<div class="value">{{obj.cert_id}}</div>
			</div>
			<div class="line">
				<div class="label">存证主体：</div>
				<div class="value">{{obj.to_title}}</div>
			</div>
			<div class="line">
				<div class="label">存证哈希：</div>
				<div class="value">{{obj.file_hash}}</div>
			</div>
			<div class="line">
				<div class="label">数据来源：</div>
				<div class="value">{{obj.source}}</div>
			</div>
			<div class="line">
				<div class="label">存证时间：</div>
				<div class="value">{{obj.addtime}}</div>
			</div>
			<!-- <div class="line">
				<div class="label">可信时间认证机构：</div>
				<div class="value">{{obj.jigou}}</div>
			</div> -->
		</el-dialog>
		<el-dialog title="验证码" :visible="formFlag" @close="formClose" width="600px">
			<el-form :model="formData" label-position="left" label-width="120px">
				<el-form-item prop="code" label="核验码">
					<el-input type="text" v-model="formData.code" placeholder="请输入核验码"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain @click="formClose">
						关闭
					</el-button>
					<el-button type="primary" @click="submitCode">
						确认
					</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '',
				tableData: [],
				visibleFlag: false,
				obj: {},
				formFlag: false,
				verifyObj: {},
				formData: {
					obj: ''
				},
				flag: 0
			}
		},
		methods: {
			// 提交核验码
			submitCode() {
				if (this.formData.code == '') {
					this.$message({
						type: 'error',
						message: '请输入正确的核验码',
						duration: 2000
					})
					return
				}
				this.$verify.verify_financial_code({
					type: this.verifyObj.type,
					cid: this.verifyObj.cid,
					code: this.formData.code
				}, res => {
					// if (res.code == 1) {
					if (res.code == 200) {
						this.formClose()
						this.$message({
							message: '校验成功',
							duration: 2000,
							type: 'success',
							onClose: () => {
								this.goFileList(this.verifyObj)
							}
						})
					}
				})
			},
			// 关闭表单
			formClose() {
				this.formData = {
					code: ''
				}
				this.formFlag = false
			},
			// 检查是否需要核验，不需要直接去文件列表
			checkFile(row) {
				if (row.security_lvl == 1) {
					this.goFileList(row)
				} else {
					this.verifyObj = row
					this.formFlag = true
				}
			},
			// 去文件列表
			goFileList(row) {
				this.$router.push({
					path: '/package',
					query: {
						id: row.cid,
						t: row.type
					}
				})
			},
			// 查看证书
			rowClick(row) {
				this.$verify.verify_financial_cert({
					type: row.type,
					cid: row.cid
				}, res => {
					// if (res.code == 1) {
					if (res.code == 200) {
						this.obj = res.data
						this.visibleFlag = true
					}
				})
			},
			// 请求数据
			fetchData() {
				this.$verify.verify_financial_list({
					search: this.keyword
				}, res => {
					if (res == 'err') {
						this.flag = 3
					} else {
						if (res.code == 200 && res.data.list.length > 0) {
							this.tableData = res.data.list
						// if (res.code == 1 && res.data.length > 0) {
						// 	this.tableData = res.data
							this.flag = 1
							return
						}
						this.flag = 2
					}
				})
			}
		},
		mounted() {
			this.keyword = this.$route.query.keyword
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.result {
		width: 100%;

		.result-loading,
		.result-word {
			width: 100%;
		}

		.result-empty {
			width: 100%;
			height: 400px;
			text-align: center;
			line-height: 36px;
			padding-top: 50px;
			box-sizing: border-box;

			i {
				margin-right: @vue-spacing-row-sm;
				font-size: @vue-font-size-sm + 2;
			}

			.result-word-fail {
				i {
					color: @vue-text-color-red;
				}
			}

			.result-word-timeout {
				i {
					color: orange;
				}
			}
		}

		.result-loading {
			height: 400px;
			display: flex;
			align-items: center;
			align-content: center;
			flex-wrap: wrap;
			padding-bottom: 50px;
			box-sizing: border-box;

			.loading-icon {
				width: 100%;
				height: 80px;
			}

			.loading-word {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}

		.result-icon {
			width: 100%;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: flex-end;

			img {
				width: 149px;
			}
		}

		.result-word {
			width: 100%;
			height: 100px;
			text-align: center;
			margin-top: @vue-spacing-col-base;
			line-height: 36px;

			i {
				margin-right: @vue-spacing-row-sm;
				font-size: @vue-font-size-sm + 2;
			}

			.result-word-success {
				i {
					color: @vue-text-color-green;
				}
			}

			.result-word-fail {
				i {
					color: @vue-text-color-red;
				}
			}

			.result-word-timeout {
				i {
					color: orange;
				}
			}
		}

		.result-table {
			padding-bottom: @vue-spacing-col-lg;

			.check,
			a {
				color: @vue-text-color-active;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		.line {
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: space-between;

			.label {
				width: 160px;
				height: 40px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			.value {
				width: calc(100% - 170px);
				height: 40px;
				display: flex;
				align-items: center;
				background: @vue-bg-color-grey;
				padding-left: @vue-spacing-row-sm;
				box-sizing: border-box;
				border-radius: @vue-border-radius-base;
				color: @vue-text-color-grey;
			}
		}
	}
</style>