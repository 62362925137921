<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>DATA</em>资料下载
			</div>
			<div class="block-content">
				<ul>
					<li v-for="(item,index) in list" :key="index">
						<a :href="item.fileUrl" target="_blank">
							<div class="point"></div>
							<div class="name">{{item.fileName}}</div>
							<div class="date">{{item.createAt}}</div>
						</a>
					</li>
				</ul>
				<div class="pager">
					<el-pagination background layout="total, prev, pager, next, jumper" :total="pageConfig.total"
						:pager-count="11" :page-size="pageConfig.pageSize" :current-page="pageConfig.currentPage"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pageConfig: {
					total: 0,
					pageSize: 12,
					currentPage: 1
				},
				list: [],
				currentRow: {}
			}
		},
		methods: {
			pageChange(e) {
				this.pageConfig.currentPage = e
			},
			async fetchData() {
				let {
					data
				} = await this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/queryDownloadList', {
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage
				})
				if (data.code == 200) {
					this.list = data.data.list
					this.pageConfig.total = data.data.total
				}
			}
		},
		mounted() {
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				ul {
					width: 100%;

					li {
						width: 100%;
						height: 50px;

						a {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: space-between;
							align-items: center;
							color: @vue-text-color;

							.point {
								width: 12px;
								height: 12px;
								margin: 0px 19px;
								border-radius: 50%;
								background: #ffd171;
							}

							.name {
								width: calc(100% - 250px);
								height: 100%;
								display: flex;
								align-items: center;
							}

							.date {
								width: 200px;
								height: 100%;
								display: flex;
								justify-content: flex-end;
								align-items: center;
								padding-right: @vue-spacing-row-base;
								box-sizing: border-box;
							}
						}

						a:hover {
							color: @vue-text-color-active;
						}
					}

					li:nth-child(2n) {
						background: #f6f8fc;
					}
				}

				.pager {
					width: 100%;
					height: 80px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}
			}
		}
	}
</style>