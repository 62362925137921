<template>
	<div class="product-content">
		<div class="fixation">
			<div class="block">
				<div class="block-title">
					<em>CASE</em>{{title}}
				</div>
				<div class="block-content">
					<div class="rich-text" v-html="richText"></div>
					<div class="list">
						<div class="cont">
							<div class="swiper" v-show="switchIndex == '图片' && swiper.length>0">
								<el-carousel height="500px" :autoplay="false" @change="swiperChange">
									<el-carousel-item v-for="(item,index) in swiper" :key="index">
										<img :src="item" alt="">
									</el-carousel-item>
								</el-carousel>
							</div>
							<div class="video" v-show="switchIndex == '视频' && videoUrl != ''">
								<video :src="videoUrl" controls></video>
							</div>
						</div>
						<div class="switch" v-show="switcher.length > 0">
							<div class="switch-box">
								<div :class="'switch-item '+(item == switchIndex ? 'switch-item-selected' : '')"
									v-for="(item,index) in switcher" :key="index" @click="switchIndex = item">
									{{item}}
								</div>
							</div>
							<div class="swiper-index" v-show="switchIndex == 1">
								{{swiperIndex+1}}/{{swiper.length}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				switcher: [],
				videoUrl: '',
				swiper: [],
				swiperIndex: 0,
				switchIndex: '',
				title: '',
				richText: '',
				id: ''
			}
		},
		methods: {
			swiperChange(e) {
				this.swiperIndex = e
			},
			async fetchData() {
				let {
					data
				} = await this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/queryIndustryCaseDetail', {
					params: {
						id: this.id
					}
				})
				if (data.code == 200) {
					this.title = data.data.title
					this.richText = data.data.content
					let videoUrl = data.data.videoUrl
					let swiper = data.data.imgUrl
					if (videoUrl == '' && swiper != '') {
						this.swiper = swiper.split(',')
						this.switchIndex = '图片'
					} else if (videoUrl != '' && swiper == '') {
						this.videoUrl = videoUrl
						this.switchIndex = '视频'
					} else {
						this.videoUrl = videoUrl
						this.swiper = swiper.split(',')
						this.switcher = ['图片', '视频']
						this.switchIndex = '图片'
					}
				}
			}
		},
		mounted() {
			this.id = this.$route.params.id
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				.rich-text {
					width: 100%;
					font-size: @vue-font-size-sm;
					color: @vue-text-color-grey;
					text-indent: 2em;
					line-height: 1.5em;

					span {
						text-indent: 2em;
						display: inline-block;
					}

					img {
						max-width: 100%;
						height: auto;
						display: block;
						margin: @vue-spacing-col-lg auto;
					}
				}

				.list {
					width: 100%;

					.cont {
						width: 100%;
						height: 500px;
						margin: @vue-spacing-col-lg 0px;

						.video,
						.swiper {
							width: 100%;
							height: 100%;

							video,
							img {
								height: 100%;
							}

							img {
								max-width: 100%;
								display: block;
								margin: 0 auto;
							}

							video {
								width: 100%;
							}
						}
					}

					.switch {
						width: 100%;
						height: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;

						.switch-box {
							width: 120px;
							height: 36px;
							background: @vue-bg-color-mask;
							border-radius: 18px;
							display: flex;
							justify-content: space-between;

							.switch-item {
								width: 50%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: @vue-font-size-sm - 2;
								color: @vue-text-color-inverse;
								cursor: pointer;
							}

							.switch-item-selected {
								background: #748cd1;
								border-radius: 18px;
							}
						}

						.swiper-index {
							width: 80px;
							height: 28px;
							background: @vue-bg-color-mask;
							border-radius: 14px;
							display: flex;
							justify-content: center;
							align-items: center;
							color: @vue-text-color-inverse;
							position: absolute;
							right: 0px;
							top: 35px;
						}
					}
				}
			}
		}
	}
</style>