<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>CONTACT</em>资质证书
			</div>
			<div class="block-content">
				<div class="flex">
					<div class="item">
						<img src="../../../assets/about-left-image.png" alt="">
					</div>
					<div class="item">
						<div class="map">
							<div class="map-title">
								<span>河北分部</span>/{{address}}
							</div>
							<div class="map-content">
								<baidu-map class="bdwindow" :dragging="dragging" :center="center" :zoom="zoom"
									style="height: 100%;" :scroll-wheel-zoom='scroll'>
									<bm-marker :position="center">
										<bm-label content="河北中经天平司法鉴定中心" :labelStyle="{color: 'red', fontSize : '12px'}"
											:offset="{width: -60, height: 30}" />
									</bm-marker>
								</baidu-map>
							</div>
						</div>
					</div>
				</div>
				<div class="flex">
					<div class="item">
						<div class="key">
							<img src="../../../assets/contact-icon-1.png" alt="">
							公司地址
						</div>
						<div class="value">
							{{address}}
						</div>
					</div>
					<div class="item">
						<div class="key">
							<img src="../../../assets/contact-icon-2.png" alt="">
							联系方式
						</div>
						<div class="value">
							电话：{{phone}} <br>
							邮箱：{{email}}
						</div>
					</div>
					<div class="item">
						<div class="key">
							<img src="../../../assets/contact-icon-3.png" alt="">
							交通线路
						</div>
						<div class="value">
							<span v-for="(item,index) in lines" :key="index">{{item.detail}}<br></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block">
			<div class="block-title">
				<em>CONSULT</em>专利证书
			</div>
			<div class="block-content">
				<div class="form">
					<div class="form-left">
						<el-form ref="consult" :model="consult">
							<div class="form-left-flex">
								<div class="form-title">
									在线咨询
								</div>
								<div class="form-desc">
									我们将尽快联系您并保证信息隐私安全！
								</div>
								<div class="form-left-item">
									<el-form-item prop="name">
										<el-input placeholder="您的姓名" v-model="consult.name"></el-input>
									</el-form-item>
									<el-form-item prop="phone">
										<el-input placeholder="您的手机号" v-model="consult.phone"></el-input>
									</el-form-item>
									<el-form-item prop="type">
										<el-select v-model="consult.type" placeholder="咨询业务类型">
											<el-option v-for="(item,index) in typeList" :key="index" :label="item"
												:value="item"></el-option>
										</el-select>
									</el-form-item>
								</div>
								<div class="form-left-item">
									<el-form-item prop="remark">
										<el-input type="textarea" placeholder="您的备注" v-model="consult.remark" rows="4">
										</el-input>
									</el-form-item>
									<el-form-item>
										<el-button type="warning" @click="submitConsult"
											:loading="loading">提交留言</el-button>
									</el-form-item>
								</div>
							</div>
						</el-form>
					</div>
					<div class="form-right">
						<img src="../../../assets/consult.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				consult: {
					name: '',
					phone: '',
					type: '',
					remark: ''
				},
				address: '',
				phone: '',
				email: '',
				lines: [],
				center: {
					lng: 114.37793,
					lat: 38.077325
				},
				zoom: 16, //缩放级别
				show: true, //显示标签
				scroll: true, //地图缩放
				dragging: true, //地图拖拽
				typeList: [
					'数据恢复服务',
					'移动存取证服务',
					'司法区块链',
					'一站式版权保护服务',
					'鉴定调解一体化服务',
					'执行查封取证',
					'手机及计算机密码破解',
					'电子数据分析与鉴定',
					'信息系统分析与鉴定',
					'电子数据固定与获取',
					'电子数据离职审计',
					'区块链合约鉴定',
					'数字资产交易流向鉴定',
					'电子合同鉴定',
					'电子设计图稿司法鉴定',
					'企业技术秘密存证鉴定'
				],
				loading: false
			}
		},
		methods: {
			async fetchData() {
				let {
					data
				} = await this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/getCompanyInfo')
				if (data.code == 200) {
					let gps = data.data.coordinate.split(',')
					this.center = {
						lng: gps[0],
						lat: gps[1]
					}
					this.lines = JSON.parse(data.data.line)
					this.address = data.data.address
					this.phone = data.data.phone
					this.email = data.data.email
				}
			},
			async submitConsult() {
				if (this.consult.name == '') {
					this.$message({
						type: 'error',
						message: '请输入姓名'
					})
					return
				}
				if (!this.$ver.verify_phone(this.consult.phone)) {
					this.$message({
						type: 'error',
						message: '请输入正确的手机号'
					})
					return
				}
				if (this.consult.type == '') {
					this.$message({
						type: 'error',
						message: '请选择咨询业务类型'
					})
					return
				}
				if (this.consult.remark == '') {
					this.$message({
						type: 'error',
						message: '请输入备注'
					})
					return
				}
				this.loading = true
				let res = await this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/addConsulting', {
					phone: this.consult.phone,
					name: this.consult.name,
					consultingType: this.consult.type,
					remarks: this.consult.remark
				})
				this.loading = false
				if (res.data.code == 200) {
					this.consult = {
						phone: '',
						name: '',
						type: '',
						remark: ''
					}
					this.$alert('我们将于近期与您联系，请您耐心等待。', '咨询提交成功！', {
						confirmButtonText: '确定'
					});
				} else {
					this.$alert(res.data.msg, '发生错误！', {
						confirmButtonText: '确定'
					})
				}
			}
		},
		mounted() {
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				.flex {
					width: 100%;
					display: flex;
					justify-content: space-between;
				}

				.flex:first-child {
					.item {
						width: 505px;
						height: 490px;
						border-radius: @vue-border-radius-lg;
						box-shadow: 0px 0px 10px @vue-border-color;
					}

					.item:first-child {
						background: radial-gradient(#FFFFFF, #e2e6f4);
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.item:last-child {
						background: @vue-bg-color;
						padding: @vue-spacing-col-lg @vue-spacing-row-lg;
						box-sizing: border-box;

						.map {
							width: 100%;
							height: 100%;

							.map-title {
								width: 100%;
								height: 100px;
								padding-bottom: @vue-spacing-col-lg;
								box-sizing: border-box;
								font-size: @vue-font-size-sm + 2;

								span {
									color: @vue-text-color-gold;
								}
							}

							.map-content {
								width: 100%;
								height: calc(100% - 100px);
							}
						}
					}
				}

				.flex:last-child {
					margin: @vue-spacing-col-lg*2 0px 0px 0px;
					width: 100%;
					height: 180px;
					background: #1a3071;
					border-radius: @vue-border-radius-lg;
					padding: @vue-spacing-col-lg @vue-spacing-row-base;
					box-sizing: border-box;
					color: @vue-text-color-inverse;

					.item {
						width: 30%;
						height: 100%;
						border-right: 1px solid @vue-border-color;
						padding-left: @vue-spacing-row-base;
						box-sizing: border-box;

						.key {
							width: 100%;
							height: 50px;
							display: flex;
							align-items: center;

							img {
								width: 24px;
								height: 24px;
								margin-right: @vue-spacing-row-sm;
							}
						}

						.value {
							width: 100%;
							line-height: 36px;
						}
					}

					.item:last-child {
						width: 40%;
						border-right: none;
					}
				}

				.form {
					width: 100%;
					height: 295px;
					display: flex;
					justify-content: space-between;
					background: @vue-bg-color;
					background: #f6f8fc;

					.form-left,
					.form-right {
						width: 50%;
						height: 100%;
					}

					.form-left {
						padding: 0px @vue-spacing-row-lg;
						box-sizing: border-box;

						.form-left-flex {
							width: 100%;
							height: 100%;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;

							.form-title,
							.form-desc {
								width: 100%;
								display: flex;
								align-items: center;
								color: @vue-text-color;
							}

							.form-title {
								height: 50px;
								font-size: @vue-font-size-sm - 2;
							}

							.form-desc {
								font-size: @vue-font-size-min;
								color: @vue-text-color-grey;
								margin-bottom: @vue-spacing-col-lg;
							}

							.form-left-item {
								width: 48%;

								.el-input {

									input {
										height: 100%;
									}
								}

								.el-textarea {
									height: 102px;

									textarea {
										height: 100% !important;
									}
								}

								.el-select {
									width: 100%;
								}

								.el-button {
									width: 100%;
									height: 40px;
									font-size: @vue-font-size-sm + 2;
								}
							}
						}
					}

					.form-right {
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
</style>