<template>
	<div class="admin-page">
		<custom-table :pageConfig="pageConfig" :multipleSelection="multipleSelection" :buttonGroup="buttonGroup"
			:filterValue="filterValue" :tableFilter="tableFilter" :tableConfig="tableConfig" :tableData="tableData"
			@pageChange="pageChange" @buttonClick="buttonClick" @filterChange="filterChange"
			@scopeButtonClick="scopeButtonClick">
		</custom-table>
	</div>
</template>

<script>
	export default {
		name: 'standard',
		data() {
			return {
				pageConfig: {
					totalWord: '全部数据',
					total: 0,
					pageSize: 13,
					currentPage: 1,
				},
				multipleSelection: [],
				buttonGroup: [{
					type: 'primary',
					size: 'mini',
					plain: true,
					icon: 'el-icon-plus',
					word: '添加',
					sign: 'new'
				}],
				filterValue: {
					keyword: '',
					criteria: '',
					date: '',
					daterange: ''
				},
				tableFilter: [],
				tableConfig: {
					border: true,
					selection: false,
					showOverflowTooltip: true,
					activityMenu: false,
					tableHeader: [{
						prop: 'title',
						label: '标题',
						width: 400
					}, {
						prop: 'standardNo',
						label: '编号',
						width: 400
					}, {
						prop: 'validAt',
						label: '生效日期',
						width: 400
					}],
					tableEvent: [{
							icon: 'el-icon-edit',
							name: '编辑',
							color: 'blue',
							sign: 'edit'
						},
						{
							icon: 'el-icon-delete',
							name: '删除',
							color: 'red',
							sign: 'delete'
						}
					],
					emptyDescription: '当前没有数据',
				},
				tableData: []
			};
		},
		methods: {
			// 页面变化
			pageChange() {
				this.fetchData()
			},
			// 右上按钮
			buttonClick(sign) {
				if (sign === 'new') {
					this.$router.push({
						path: '/admin/edit',
						query: {
							id: ''
						}
					})
				}
			},
			// 筛选条件变化
			filterChange() {
				console.log(this.filterValue);
			},
			// 操作按钮
			scopeButtonClick(row, sign) {
				if (sign == 'edit') {
					this.$router.push({
						path: '/admin/edit',
						query: {
							id: row.id
						}
					})
				}
				if (sign === 'delete') {
					this.deleteStandard(row)
				}
			},
			fetchData() {
				this.$api.standardList({
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage
				}, res => {
					if (res.code == 200) {
						this.pageConfig.total = res.data.total
						this.tableData = res.data.list
					}
				})
			},
			deleteStandard(row) {
				this.$confirm('确认要删除这个鉴定标准吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.standardDelete({
						id: row.id
					}, res => {
						if (res.code == 200) {
							this.fetchData()
							this.$message({
								message: '删除成功',
								type: 'success',
								duration: 2000
							})
						}
					})
				}).catch(() => {});
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped>

</style>
