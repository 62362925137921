<template>
	<div class="article">
		<div class="title">
			{{article.title}}
		</div>
		<div class="time-source">
			<span>
				发布日期：{{article.time}}
			</span>
		</div>
		<div class="rich-text">
			<div v-html="article.nodes"></div>
		</div>
	</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				article: {
					title: '',
					time: '',
					nodes: ''
				},
				id: ''
			}
		},
		methods: {
			async fetchData() {
				let {
					data
				} = await this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/queryStandardDetail', {
					params: {
						id: this.id
					}
				})
				if (data.code == 200) {
					this.article = {
						title: data.data.title,
						time: data.data.createAt,
						nodes: data.data.content
					}
				}
			}
		},
		mounted() {
			this.id = this.$route.params.id
			this.fetchData()
		}
	}
</script>

<style lang="less">
	.article {
		width: 100%;

		.title {
			width: 100%;
			line-height: 42px;
			font-size: @vue-font-size-base+2;
			color: @vue-text-color;
			text-align: center;
			padding: @vue-spacing-col-base 0px;
			box-sizing: border-box;
		}

		.time-source {
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: @vue-font-size-sm - 2;
			border-bottom: 1px dashed @vue-border-color;

			span {
				color: @vue-text-color-grey;
			}
		}

		.rich-text {
			width: 100%;
			padding: @vue-spacing-col-base 0px @vue-spacing-col-lg*2 0px;
			box-sizing: border-box;
			text-indent: 2em;
			line-height: 1.5em;

			img {
				max-width: 100%;
				height: auto;
				display: block;
				margin: 1.5em auto;
			}
		}
	}
</style>