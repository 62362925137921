<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>QUALIFICATIONS</em>资质证书
			</div>
			<div class="block-content">
				<p>
					<img src="../../../assets/about-license-1.png" alt="">
				</p>
			</div>
		</div>
		<div class="block">
			<div class="block-title">
				<em>PATENT</em>专利证书
			</div>
			<div class="block-content">
				<div class="list">
					<div class="item" v-for="(item,index) in flow" :key="index">
						<img :src="item.imgUrl" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				flow: []
			}
		},
		methods: {
			async fetchData() {
				let {
					data
				} = await this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/getAppraisalImgList')
				if (data.code == 200) {
					this.flow = data.data
				}
			}
		},
		mounted() {
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				p {
					width: 100%;
					line-height: 32px;
					font-size: @vue-font-size-sm;
					color: @vue-text-color-grey;

					span {
						text-indent: 2em;
						display: inline-block;

						.blue {
							text-shadow: 2px 2px 2px #abd0ff;
							color: #2d6ebc;
						}

						.orange {
							text-shadow: 2px 2px 2px #ffe8b8;
							color: #b98c2f;
						}
					}

					img {
						display: block;
						margin: @vue-spacing-col-lg auto;
					}
				}

				.list {
					width: 100%;
					display: flex;
					flex-wrap: wrap;

					.item {
						width: 30%;
						height: 290px;
						background: url(../../../assets/qualifications.png) no-repeat;
						background-size: 100% 100%;
						margin-bottom: @vue-spacing-col-lg;
						margin-right: 5%;
						padding: 40px 30px 45px 23px;
						box-sizing: border-box;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.item:nth-child(3n) {
						margin-right: 0px;
					}
				}
			}
		}
	}
</style>
