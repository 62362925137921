<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>APPRAISAL</em>鉴定调解一体化服务
			</div>
			<div class="block-content">
				<p>
					<span>
						2015年8月，司法厅印发《关于做好司法鉴定与人民调解衔接联动工作的通知》，指导司法鉴定和人民调解管理部门、司法鉴定机构与人民调解组织加强联系和配合，力争把矛盾纠纷解决在初始阶段，化解在萌芽状态。
					</span>
				</p>
				<p>
					<span>
						着力打造“人民调解、司法调解、行政调解”三调联动工作模式，从而实现多方位多角度的“技术鉴定，人民调解”鉴调一体社会治理新模式。服务中心在原有服务模式基础上，还会提供远程鉴调服务，通过电话、远程音视频等多元化方式，利用互联网和大数据技术结合司法鉴定的先天优势，更早一步、更快一步将民众矛盾纠纷化解于无形。
					</span>
				</p>
				<p>
					<img src="@/assets/product-service-1.png" alt="">
				</p>
			</div>
		</div>
		<div class="block">
			<div class="block-title">
				<em>MEDIATOR</em>专职鉴调
			</div>
			<div class="block-content">
				<div class="gradient">
					<div class="list">
						<div class="list-box"
							:style="'width:'+(213*mediator.length - parseInt(mediator.length/5)*23)+'px;'">
							<div class="item" v-for="(item,index) in mediator" :key="index">
								<div class="img">
									<el-image :src="item.mediatorPhoto" style="width: 100%;height: 100%;"></el-image>
									<div class="line"></div>
								</div>
								<div class="name">
									{{item.mediatorName}}
								</div>
								<div class="job">
									<span>职务</span>
									{{item.mediatorPost}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<p>
					<img src="@/assets/product-service-2.png" alt="">
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				mediator:[]
			}
		},
		methods: {
			async fetchData(){
				let {data} = await this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/queryMediatorList')
				if(data.code == 200){
					this.mediator = data.data
				}
			}
		},
		created() {
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				p {
					width: 100%;
					line-height: 32px;
					font-size: @vue-font-size-sm;
					color: @vue-text-color-grey;

					span {
						text-indent: 2em;
						display: inline-block;
					}

					img {
						display: block;
						margin: @vue-spacing-col-lg auto;
					}
				}

				.gradient {
					width: 100%;
					padding: @vue-spacing-col-lg @vue-spacing-row-lg;
					box-sizing: border-box;
					background: linear-gradient(#dbdeee, #FFFFFF);

					.list {
						width: 100%;
						height: 325px;
						overflow-x: hidden;

						.list-box {
							height: 100%;
							display: flex;

							.item {
								width: 190px;
								height: 325px;
								background: url(@/assets/mediator.png) no-repeat;
								background-size: 100% 100%;
								margin-right: 23px;
								padding: 24px 15px 0px 15px;
								box-sizing: border-box;

								.img {
									width: 100%;
									height: 210px;
									position: relative;

									.line {
										width: 26px;
										height: 2px;
										background: #fed072;
										position: absolute;
										right: -12px;
										top: 23px;
									}

									img {
										width: 100%;
										height: 100%;
									}
								}

								.name {
									width: 110px;
									height: 36px;
									display: flex;
									justify-content: center;
									align-items: center;
									color: @vue-text-color;
									background: #fed072;
									position: relative;
									left: -15px;
									top: -24px;
								}

								.job {
									width: 100%;
									text-align: center;
									color: @vue-text-color-inverse;
									font-size: @vue-font-size-base - 4;
									position: relative;
									top: -12px;

									span {
										display: block;
										font-size: @vue-font-size-sm - 2;
										margin-bottom: @vue-spacing-col-sm / 2;
									}
								}
							}

							.item:nth-child(5n) {
								margin-right: 0px;
							}
						}
					}
				}
			}
		}
	}
</style>
