import { render, staticRenderFns } from "./block-chain.vue?vue&type=template&id=79014506&scoped=true&"
import script from "./block-chain.vue?vue&type=script&setup=true&lang=js&"
export * from "./block-chain.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./block-chain.vue?vue&type=style&index=0&id=79014506&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79014506",
  null
  
)

export default component.exports