import Vue from 'vue'
import App from './App.vue'
// router与store
import router from './router'
import store from './store'
// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 百度地图
import BaiduMap from 'vue-baidu-map';
// 全局组件
import '@/components'

Vue.use(ElementUI)
Vue.use(BaiduMap, {
	ak: 't6V2IdBoWWcPPWUumPnGkhdX6NAuzSVm'
})

import axios from 'axios'

axios.defaults.timeout = 60000

// 引入api
import api from './api/index.js'
import verify from './api/verify.js'
import urlConfig from './api/config.js'
import pub from './public/index.js'
import ver from './verification/index.js'

Vue.prototype.$api = api
Vue.prototype.$verify = verify
Vue.prototype.$urlConfig = urlConfig
Vue.prototype.$pub = pub
Vue.prototype.$ver = ver
Vue.prototype.$axios = axios

Vue.config.productionTip = false

if (pub.getStorage('adm_token')) {
	store.commit('setAdmToken', localStorage.getItem('adm_token'))
	store.state.hasLogin = true
}

router.beforeEach((to, from, next) => {
	// 设置title
	if (to.meta.title) {
		document.title = to.meta.title
	}
	window.scrollTo(0, 0);
	// 管理系统拦截无效用户
	// let manage = [
	// 	'/index', '/user', '/commodity', '/commodity/edit', '/order', '/coupon', '/coupon/edit', '/swiper',
	// 	'/charge', '/record'
	// ]
	// 如果是登录页，不要检查
	// if (manage.indexOf(to.path) >= 0 && !pub.getStorage('token')) {
	// 	router.push({
	// 		path: '/notFound'
	// 	})
	// 	return
	// }
	next()
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
