<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>APPLY</em>申请鉴定服务
			</div>
			<div class="block-content">
				<div class="form">
					<el-form ref="form" :model="form">
						<el-form-item prop="name">
							<el-input v-model="form.name" placeholder="请输入姓名"></el-input>
						</el-form-item>
						<el-form-item prop="phone">
							<el-input type="number" v-model="form.phone" placeholder="请输入联系电话"></el-input>
						</el-form-item>
						<el-form-item prop="demand">
							<el-input type="textarea" rows="8" v-model="form.demand" placeholder="请输入您的鉴定需求"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" :loading="loading" @click="submit">
								立即预约
							</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					name: '',
					phone: '',
					demand: ''
				},
				loading: false
			}
		},
		methods: {
			async submit() {
				if (this.form.name == '') {
					this.$message({
						type: 'error',
						message: '请输入姓名'
					})
					return
				}
				if (!this.$ver.verify_phone(this.form.phone)) {
					this.$message({
						type: 'error',
						message: '请输入正确的手机号'
					})
					return
				}
				if (this.form.demand == '') {
					this.$message({
						type: 'error',
						message: '请输入您的鉴定需求'
					})
					return
				}
				this.loading = true
				let res = await this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/addAppraisal', {
					phone: this.form.phone,
					name: this.form.name,
					requirement: this.form.demand
				})
				this.loading = false
				if (res.data.code == 200) {
					this.form = {
						phone: '',
						name: '',
						demand: ''
					}
					this.$alert('我们将于近期与您联系，请您耐心等待。', '咨询提交成功！', {
						confirmButtonText: '确定'
					});
				} else {
					this.$alert(res.data.msg, '发生错误！', {
						confirmButtonText: '确定'
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				.form {
					width: 600px;
					margin: @vue-spacing-col-lg*2 auto;

					.el-button {
						width: 100%;
					}
				}
			}
		}
	}
</style>
