<template>
	<div class="result" v-loading="flag == 0">
		<div class="result-icon">
			<img src="../../../assets/centre-result-success.png" alt="" v-if="flag == 1">
			<img src="../../../assets/centre-result-fail.png" alt="" v-if="flag == 2">
			<img src="../../../assets/centre-result-timeout.png" alt="" v-if="flag == 3">
		</div>
		<div class="result-word">
			<span class="result-word-success" v-if="flag == 1">
				<i class="el-icon-success"></i>电子证据已在司法区块链存证<br>
				<i class="el-icon-success"></i>电子数据包完整性校验通过，数据未被篡改
			</span>
			<span class="result-word-fail" v-if="flag == 2">
				<i class="el-icon-error"></i>信息不匹配或查询不到数据，请重新核对数据后查询
			</span>
			<span class="result-word-timeout" v-if="flag == 3">
				<i class="el-icon-warning"></i>对不起，查询超时，请稍后重试！
			</span>
		</div>
		<div class="info" v-if="flag == 1">
			<div class="line" v-if="obj.subject != ''">
				<div class="label">存证主体：</div>
				<div class="value">{{obj.subject == '' ? '暂无数据' : obj.subject}}</div>
			</div>
			<!-- <div class="line">
				<div class="label">存证文件：</div>
				<div class="value">{{obj.docName == '' ? '暂无数据' : obj.docName}}</div>
			</div> -->
			<div class="line">
				<div class="label">存证哈希：</div>
				<div class="value">{{obj.hash == '' ? '暂无数据' : obj.hash}}</div>
			</div>
			<div class="line">
				<div class="label">存证时间：</div>
				<div class="value">{{obj.date == '' ? '暂无数据' : obj.date}}</div>
			</div>
			<div class="line" v-if="obj.timeSeries != ''">
				<div class="label">可信时间认证机构：</div>
				<div class="value">{{obj.org == '' ? '暂无数据' : obj.org}}</div>
			</div>
			<div class="line" v-if="obj.timeSeries != ''">
				<div class="label">可信时间凭证编号：</div>
				<div class="value">{{obj.timeSeries == '' ? '暂无数据' : obj.timeSeries}}</div>
			</div>
			<div class="operation">
				<el-button type="primary" @click="licenseFlag = true">查看证书</el-button>
				<el-button type="primary" @click="requestReport">申请纸质报告</el-button>
			</div>
		</div>
		<el-dialog title="申请纸质报告" :visible="reportFlag" @close="reportFlag = false" width="600px">
			<el-form class="reportForm" :rules="rules" ref="reportForm" :model="reportForm" label-width="120px">
				<el-form-item prop="name" label="姓名:">
					<el-input v-model="reportForm.name" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item prop="phone" label="手机号:">
					<el-input v-model="reportForm.phone" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item prop="email" label="邮箱:">
					<el-input v-model="reportForm.email" placeholder="请输入邮箱"></el-input>
				</el-form-item>
				<el-form-item prop="content" label="申请内容:">
					<el-select multiple v-model="reportForm.content" placeholder="请选择申请内容">
						<el-option v-for="(item,index) in contentList" :key="index" :label="item.label"
							:value="item.label">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" round @click="doValidate">提交申请</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog :visible="errorFlag" title="错误提示" width="400px" @close="errorFlag = false">
			<div class="error-type">
				<span v-show="errorType == 1">申请提交失败</span>
				<span v-show="errorType == 2">申请已提交成功</span>
			</div>
			<div class="error-hint">
				<span v-show="errorType == 1">请输入正确信息</span>
				<span v-show="errorType == 2">工作人员将在3个工作日内与您联系</span>
			</div>
			<div class="error-button">
				<el-button type="primary" round @click="errorFlag = false">
					确定
				</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible="licenseFlag" title="查看证书" @close="licenseFlag = false" width="1000px">
			<iframe :src="'https://authenapi.bjzjtp.com/authen-api/api/v3/webCerts?certId='+obj.certId" frameborder="0"
				width="100%" height="900px"></iframe>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				flag: 0,
				obj: {
					subject: '',
					docName: '',
					hash: '',
					date: '',
					org: '',
					timeSeries: ''
				},
				reportFlag: false,
				reportForm: {
					name: '',
					phone: '',
					email: '',
					content: []
				},
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					email: [{
						required: true,
						message: '请输入邮箱',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请选择申请内容',
						trigger: 'change'
					}]
				},
				contentList: [{
						label: '存证报告',
						value: 1
					},
					{
						label: '取证报告',
						value: 2
					},
					{
						label: '鉴定报告',
						value: 3
					},
					{
						label: '智能合约报告',
						value: 4
					}
				],
				errorFlag: false,
				errorType: 1,
				licenseFlag: false,
				certId: ''
			}
		},
		methods: {
			doValidate() {
				this.$refs['reportForm'].validate(valid => {
					if (valid) {
						this.submitReport()
					}
				})
			},
			async submitReport() {
				let {
					data
				} = await this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/applyPaperReport', {
					applyType: this.reportForm.content.join(','),
					name: this.reportForm.name,
					phone: this.reportForm.phone,
					email: this.reportForm.email,
					certId: this.obj.certId
				})
				if (data.code == 200) {
					this.errorType = 2
				} else {
					this.errorType = 1
				}
				this.errorFlag = true
				this.reportFlag = false
			},
			requestReport() {
				this.reportFlag = true
			},
			fetchData() {
				this.$axios.get(this.$urlConfig.requestUrl + 'verificationApi/document', {
					params: {
						certificate: this.keyword
					}
				}).then(res => {
					let data = res.data
					if (data.code == 200) {
						this.obj = {
							subject: data.data.username,
							// docName: data.data.fileName,
							hash: data.data.fileHash,
							date: data.data.timeTs,
							org: '国家授时中心',
							timeSeries: data.data.timeAid,
							certId: data.data.certId
						}
						this.flag = 1
						return
					}
					this.flag = 2
				}).catch(err => {
					this.flag = 3
				})

			}
		},
		mounted() {
			this.keyword = this.$route.query.keyword
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.result {
		width: 100%;

		.result-icon {
			width: 100%;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: flex-end;

			img {
				width: 149px;
			}
		}

		.result-word {
			width: 100%;
			height: 100px;
			text-align: center;
			margin-top: @vue-spacing-col-base;
			line-height: 36px;

			i {
				margin-right: @vue-spacing-row-sm;
				font-size: @vue-font-size-sm + 2;
			}

			.result-word-success {
				i {
					color: @vue-text-color-green;
				}
			}

			.result-word-fail {
				i {
					color: @vue-text-color-red;
				}
			}

			.result-word-timeout {
				i {
					color: orange;
				}
			}
		}

		.result-table {
			padding-bottom: @vue-spacing-col-lg;

			.check,
			a {
				color: @vue-text-color-active;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		.info {
			width: 100%;
			padding: @vue-spacing-col-lg @vue-spacing-row-lg*3;
			box-sizing: border-box;

			.line {
				width: 100%;
				height: 60px;
				display: flex;
				justify-content: space-between;

				.label {
					width: 160px;
					height: 40px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}

				.value {
					width: calc(100% - 170px);
					height: 40px;
					display: flex;
					align-items: center;
					background: @vue-bg-color-grey;
					padding-left: @vue-spacing-row-sm;
					box-sizing: border-box;
					border-radius: @vue-border-radius-base;
					color: @vue-text-color-grey;
				}
			}

			.operation {
				width: 100%;
				height: 200px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: @vue-spacing-col-lg*2;
				box-sizing: border-box;

				.el-button {
					width: 43%;
					height: 50px;
					font-size: @vue-font-size-sm;
				}
			}
		}

		.reportForm {
			.el-select {
				width: 100%;
			}

			.el-button {
				width: 300px;
			}
		}

		.error-type,
		.error-hint,
		.error-button {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.error-type {
			font-size: @vue-font-size-sm + 2;
			font-weight: bold;
			color: @vue-text-color-active;
		}

		.error-hint {
			color: @vue-text-color-grey;
			font-size: @vue-font-size-sm;
		}

		.error-button {
			height: 60px;
			align-items: flex-end;

			.el-button {
				width: 140px;
			}
		}
	}
</style>