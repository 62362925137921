<template>
	<div class="pubheader">
		<div class="welcome">
			<div class="fixation">
				<div class="word">
					尊敬的客户您好，欢迎来到中经天平司法鉴定中心！
				</div>
				<div class="contact">
					<div class="item">
						<i class="el-icon-message"></i>
						{{ config.email }}
					</div>
					<div class="item">
						<i class="el-icon-phone"></i>
						{{ config.phone }}
					</div>
				</div>
			</div>
		</div>
		<div class="logo-navigate">
			<div class="fixation">
				<div class="logo">
					<router-link to="/">
						<img src="@/assets/logo.png" alt="">
					</router-link>
				</div>
				<div class="navigate">
					<div :class="'item '+(item.path == $store.state.current ? 'item-selected' : '')"
						v-for="(item,index) in navigate" :key="index">
						<router-link :to="item.path">
							{{item.name}}
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['config'],
		name: 'pubheader',
		data() {
			return {
				navigate: [{
						path: '/home',
						name: '首页'
					},
					{
						path: '/news',
						name: '新闻动态'
					},
					{
						path: '/product',
						name: '产品服务'
					},
					{
						path: '/guide',
						name: '鉴定指南'
					},
					{
						path: '/centre',
						name: '核验中心'
					},
					{
						path: '/about',
						name: '关于我们'
					}
				]
			}
		},
		watch: {
			$route(to, from) {
				let array = ['/home', '/news', '/product', '/guide', '/centre', '/about', '/case', '/package']
				let current = to.matched[1].path
				if (array.indexOf(current) >= 0) {
					this.current = current
					this.$store.state.current = current
				}
			}
		},
	}
</script>

<style lang="less">
	.pubheader {
		width: 100%;

		.fixation {
			display: flex;
			justify-content: space-between;
		}
	}

	.welcome {
		width: 100%;
		height: 50px;
		background: @vue-bg-color-grey;
		color: @vue-text-color-grey;
		font-size: @vue-font-size-sm - 2;

		.word {
			height: 100%;
			display: flex;
			align-items: center;
		}

		.contact {
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.item {
				margin-left: @vue-spacing-row-base;
			}

			.item:first-child {
				padding-right: @vue-spacing-row-base;
				border-right: 1px solid @vue-border-color;
			}
		}
	}

	.logo-navigate {
		width: 100%;
		height: 100px;

		.logo {
			height: 100%;
			display: flex;
			align-items: center;
		}

		.navigate {
			height: 100%;
			display: flex;
			justify-content: flex-end;

			.item {
				width: 130px;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				a {
					width: 100px;
					height: 36px;
					border-radius: 18px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: @vue-text-color;
				}

				a:hover {
					background: rgba(@vue-bg-color-active, 0.5);
					color: @vue-text-color-inverse;
				}
			}

			.item-selected {
				a {
					background: @vue-bg-color-active;
					color: @vue-text-color-inverse;
				}
			}
		}
	}
</style>