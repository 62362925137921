<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>PROCESS</em>服务流程
			</div>
			<div class="block-content">
				<el-table ref="singleTable" :data="tableData" highlight-current-row
					@current-change="handleCurrentChange" style="width: 100%"
					:header-cell-style="{background:'#1a3071',color:'#FFFFFF'}">
					<el-table-column type="index" width="80" label="序号" align="center">
					</el-table-column>
					<el-table-column property="title" label="名称" align="center">
					</el-table-column>
					<el-table-column property="standardNo" label="编号" align="center">
					</el-table-column>
					<el-table-column property="validAt" label="生效日期" width="200" align="center">
					</el-table-column>
				</el-table>
				<div class="pager">
					<el-pagination background layout="total, prev, pager, next, jumper" :total="pageConfig.total"
						:pager-count="11" :page-size="pageConfig.pageSize" :current-page="pageConfig.currentPage"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pageConfig: {
					total: 0,
					pageSize: 12,
					currentPage: 1
				},
				tableData: [],
				currentRow: {}
			}
		},
		methods: {
			handleCurrentChange(row) {
				this.currentRow = row
				this.$router.push({
					path: '/guide/' + row.id
				})
			},
			pageChange(e) {
				this.pageConfig.currentPage = e
				this.fetchData()
			},
			async fetchData() {
				let {
					data
				} = await this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/queryStandardList', {
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage
				})
				if (data.code == 200) {
					this.tableData = data.data.list
					this.pageConfig.total = data.data.total
				}
			}
		},
		mounted() {
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				.pager {
					width: 100%;
					height: 80px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}
			}
		}
	}
</style>