<template>
	<div class="index">
		<div class="about">
			<div class="fixation">
				<div class="left">
					<div class="cont">
						<div class="line"></div>
						<em>ABOUT US</em>
						<span>关于我们</span>
					</div>
				</div>
				<div class="right">
					<div class="title">
						中经天平司法鉴定
					</div>
					<div class="cont">
						<div class="word-number">
							<div class="word">
								本司是经河北省司法厅核准登记设立，在中华人民共和国司法部备案，面向社会服务的电子物证司法鉴定机构。中心拥有先进的检测仪器、完善的法人管理结构及经验丰富的电子数据鉴定专家。面向社会提供司法存证、数字资产追溯、隐私数据保护等电子数据鉴定服务。
							</div>
							<div class="number">
								<div class="item">
									<div class="num">
										8+
										<span>\年\</span>
									</div>
									<div class="desc">
										8+年的历史沉淀
									</div>
								</div>
								<div class="item">
									<div class="num">
										20+
										<span>\名\</span>
									</div>
									<div class="desc">
										20+名高学位资深鉴定专家
									</div>
								</div>
								<div class="item">
									<div class="num">
										100+
										<span>\家\</span>
									</div>
									<div class="desc">
										100+家合作高级中级人民法院
									</div>
								</div>
							</div>
						</div>
						<div class="image">
							<img src="../../assets/about-us.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="range">
			<div class="fixation">
				<div class="title">
					<em>RANGE</em>服务范围
				</div>
				<ul>
					<li>
						<div class="series">
							<img src="../../assets/range-number-1.png" alt="">
						</div>
						<div class="name">
							电子数据存在性鉴定
						</div>
						<div class="desc">
							对电子数据的提取、固定与恢复，以及对电子数据的形成与关联分析。
						</div>
						<div class="line">
							<img src="../../assets/range-icon-1.png" alt="">
							存储介质中的数据提取与固定
						</div>
						<div class="line">
							<img src="../../assets/range-icon-1.png" alt="">
							电子设备中的数据提取、固定及恢复
						</div>
						<div class="line">
							<img src="../../assets/range-icon-1.png" alt="">
							网络数据的提取和固定
						</div>
					</li>
					<li>
						<div class="series">
							<img src="../../assets/range-number-2.png" alt="">
						</div>
						<div class="name">
							电子数据真实性鉴定
						</div>
						<div class="desc">
							对特定形式的电子数据的真实性或修改情况进行鉴定。
						</div>
						<div class="line">
							<img src="../../assets/range-icon-2.png" alt="">
							电子邮件真实性鉴定
						</div>
						<div class="line">
							<img src="../../assets/range-icon-2.png" alt="">
							电子文档真实性鉴定
						</div>
						<div class="line">
							<img src="../../assets/range-icon-2.png" alt="">
							电子签名/电子印章验证
						</div>
					</li>
					<li>
						<div class="series">
							<img src="../../assets/range-number-3.png" alt="">
						</div>
						<div class="name">
							电子数据功能性鉴定
						</div>
						<div class="desc">
							对软件、电子设备、计算机信息系统和破坏性程序的功能进行鉴定。
						</div>
						<div class="line">
							<img src="../../assets/range-icon-3.png" alt="">
							软件功能的分析
						</div>
						<div class="line">
							<img src="../../assets/range-icon-3.png" alt="">
							恶意代码分析
						</div>
						<div class="line">
							<img src="../../assets/range-icon-3.png" alt="">
							软件行为分析
						</div>
					</li>
					<li>
						<div class="series">
							<img src="../../assets/range-number-4.png" alt="">
						</div>
						<div class="name">
							电子数据相似性鉴定
						</div>
						<div class="desc">
							对软件（含代码）、数据库、电子文档等的相似程度进行鉴定；对集成电路布图设计的相似程度进行鉴定。
						</div>
						<div class="line">
							<img src="../../assets/range-icon-4.png" alt="">
							软件相似性鉴定
						</div>
						<div class="line">
							<img src="../../assets/range-icon-4.png" alt="">
							电子文档相似性鉴定
						</div>
						<div class="line">
							<img src="../../assets/range-icon-4.png" alt="">
							司法鉴定出证
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="project">
			<div class="fixation">
				<div class="title">
					<em>PROJECT</em>服务项目
				</div>
				<div class="block">
					<div class="block-1">
						<div class="block-1-top">
							<div class="link">
								<router-link to="/">
									电子合同鉴定
								</router-link>
							</div>
							<div class="block-grey"></div>
						</div>
						<div class="block-1-bottom">
							<div class="block-1-left">
								<div class="block-grey-tint"></div>
								<div class="block-grey"></div>
								<div class="block-deep-blue">
									<div class="link">
										<router-link to="/">
											企业技术秘密存证鉴定
										</router-link>
									</div>
								</div>
							</div>
							<div class="block-1-right">
								<div class="block-1-right-top">
									<div class="link">
										<router-link to="/">
											电子数据<br>
											分析与鉴定
										</router-link>
									</div>
								</div>
								<div class="block-1-right-btm">
									<div class="block-1-r-b-item">
										<div class="block-grey-tint"></div>
										<div class="block-grey"></div>
									</div>
									<div class="block-1-r-b-item">
										<div class="link">
											<router-link to="/">
												数据恢复
											</router-link>
										</div>
										<div class="block-grey"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="block-2">
						<div class="block-2-item">
							<div class="link">
								<router-link to="/">
									信息系统<br>
									分析与鉴定
								</router-link>
							</div>
						</div>
						<div class="block-2-item">
							<div class="link">
								<router-link to="/">
									电子数据固定与获取
								</router-link>
							</div>
						</div>
					</div>
					<div class="block-3">
						<div class="link">
							<router-link to="/product">
								<h3>服务项目</h3>
								<h4>全国数据类鉴定业务平台</h4>
								<img src="../../assets/project-right-arrow.png" alt="">
							</router-link>
						</div>
					</div>
					<div class="block-4">
						<div class="block-4-top">
							<div class="block-grey-tint"></div>
							<div class="block-grey"></div>
							<div class="block-grey"></div>
						</div>
						<div class="block-4-mid">
							<div class="link">
								<router-link to="/">
									电子设计稿鉴定
								</router-link>
							</div>
						</div>
						<div class="block-4-btm">
							<div class="link">
								<router-link to="/">
									区块链<br>
									合约鉴定
								</router-link>
							</div>
						</div>
					</div>
					<div class="block-5">
						<div class="block-5-top">
							<div class="block-5-left">
								<div class="link">
									<router-link to="/">
										一站式<br>
										版权服务鉴定
									</router-link>
								</div>
								<div class="link">
									<router-link to="/">
										手机及计算机<br>
										密码破解
									</router-link>
								</div>
								<div class="block-5-l-b">
									<div class="block-grey"></div>
									<div class="block-blue"></div>
								</div>
							</div>
							<div class="block-5-right">
								<div class="block-grey-tint"></div>
								<div class="block-grey"></div>
								<div class="block-grey-tint"></div>
							</div>
						</div>
						<div class="block-5-btm">
							<div class="link">
								<router-link to="/">
									数字资产交易流向鉴定
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flow">
			<div class="fixation">
				<div class="title">
					<em>PROCESS</em>鉴定流程
				</div>
			</div>
			<div class="flow-box">
				<img src="../../assets/process.png" alt="">
			</div>
		</div>
		<div class="qualifications">
			<div class="fixation">
				<div class="title">
					<em>QUALIFICATIONS</em>鉴定资质
				</div>
				<div class="list">
					<div class="item" v-for="(item,index) in flow" :key="index">
						<img :src="item.imgUrl" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="mediator">
			<div class="fixation">
				<div class="title">
					<em>MEDIATOR</em>专职鉴调
				</div>
				<div class="list">
					<div class="list-box" :style="'width:'+mediatorWidth+'px;margin-left:-'+mediatorLeft+'px;'">
						<div class="list-item" v-for="(item,index) in mediator" :key="index">
							<div class="item" v-for="(tab,tabIndex) in item" :key="tabIndex">
								<div class="img">
									<el-image :src="tab.mediatorPhoto" style="width: 100%;height: 100%;"></el-image>
									<div class="line"></div>
								</div>
								<div class="name">
									{{tab.mediatorName}}
								</div>
								<div class="job">
									<span>职务</span>
									{{tab.mediatorPost}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pager" v-if="mediator.length>1">
					<div class="prev" @click="prevPage">
						<i class="el-icon-back"></i>
					</div>
					<div class="next" @click="nextPage">
						<i class="el-icon-right"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="query">
			<div class="fixation">
				<div class="query-data">
					<div class="query-box">
						<div class="title">
							<em>QUERY</em>金融类电子证据查询
						</div>
						<div class="query-area">
							<div class="input">
								<input type="text" v-model="keyword" placeholder="请输入身份证号/手机号进行查询">
								<button @click="financialQuery">查询</button>
							</div>
						</div>
					</div>
					<div class="data">
						<div class="title">
							<em>DATA</em>资料下载
						</div>
						<ul>
							<li v-for="(item,index) in fileList" :key="index">
								<a :href="item.fileUrl" target="_blank" rel="noopener noreferrer">
									<img src="../../assets/data-icon.png" alt="">{{item.fileName}}
								</a>
							</li>
							<div class="more">
								<router-link to="/guide/download">
									<img src="../../assets/data-right.png" alt="">
								</router-link>
							</div>
						</ul>
					</div>
				</div>
				<div class="case">
					<div class="title">
						<em>CASE</em>行业案例
					</div>
					<ul>
						<li>
							<router-link to="/case?id=0">
								<img src="../../assets/case-img-1.png" alt="">
							</router-link>
						</li>
						<li>
							<router-link to="/case?id=1">
								<img src="../../assets/case-img-2.png" alt="">
							</router-link>
						</li>
						<li>
							<router-link to="/case?id=2">
								<img src="../../assets/case-img-3.png" alt="">
							</router-link>
						</li>
					</ul>
				</div>
				<div class="news">
					<div class="title">
						<em>NEWS</em>新闻动态
					</div>
					<ul>
						<li v-for="(item,index) in news" :key="index">
							<router-link :to="{path:'/news/'+item.id}">
								<div class="time">
									<div class="day">
										{{item.day}}
										<div class="line"></div>
									</div>
									<div class="year-month">
										{{item.year_month}}
									</div>
								</div>
								<div class="right">
									<div class="news-title">
										{{item.newsTitle}}
									</div>
									<div class="arrow">
										<i class="el-icon-right"></i>
									</div>
								</div>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="consult">
			<div class="fixation">
				<div class="title">
					<em>CONSULT</em>咨询留言
				</div>
				<div class="form">
					<div class="form-left">
						<el-form ref="consult" :model="consult">
							<div class="form-left-flex">
								<div class="form-title">
									在线咨询
								</div>
								<div class="form-desc">
									我们将尽快联系您并保证信息隐私安全！
								</div>
								<div class="form-left-item">
									<el-form-item prop="name">
										<el-input placeholder="您的姓名" v-model="consult.name"></el-input>
									</el-form-item>
									<el-form-item prop="phone">
										<el-input placeholder="您的手机号" v-model="consult.phone"></el-input>
									</el-form-item>
									<el-form-item prop="type">
										<el-select v-model="consult.type" placeholder="咨询业务类型">
											<el-option v-for="(item,index) in typeList" :key="index" :label="item"
												:value="item"></el-option>
										</el-select>
									</el-form-item>
								</div>
								<div class="form-left-item">
									<el-form-item prop="remark">
										<el-input type="textarea" rows="5" placeholder="您的备注" v-model="consult.remark">
										</el-input>
									</el-form-item>
									<el-form-item>
										<el-button type="warning" @click="submitConsult()">提交留言</el-button>
									</el-form-item>
								</div>
							</div>
						</el-form>
					</div>
					<div class="form-right">
						<img src="../../assets/consult.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'IndexPage',
		data() {
			return {
				news: [],
				fileList: [],
				flow: [],
				mediator: [],
				consult: {
					name: '',
					phone: '',
					type: '',
					remark: ''
				},
				keyword: '',
				mediatorWidth: 0,
				mediatorLeft: 0,
				mediatorPage: 1,
				flag: false,
				typeList: [
					'数据恢复服务',
					'移动存取证服务',
					'司法区块链',
					'一站式版权保护服务',
					'鉴定调解一体化服务',
					'执行查封取证',
					'手机及计算机密码破解',
					'电子数据分析与鉴定',
					'信息系统分析与鉴定',
					'电子数据固定与获取',
					'电子数据离职审计',
					'区块链合约鉴定',
					'数字资产交易流向鉴定',
					'电子合同鉴定',
					'电子设计图稿司法鉴定',
					'企业技术秘密存证鉴定'
				]
			}
		},
		methods: {
			prevPage() {
				if (this.mediatorPage > 1 && !this.flag) {
					this.mediatorPage--
					this.play(false)
				}
			},
			nextPage() {
				if (this.mediatorPage < this.mediator.length && !this.flag) {
					this.mediatorPage++
					this.play(true)
				}
			},
			play(flag) {
				this.flag = true
				let timer = 0
				let interval = setInterval(() => {
					if (timer < 600) {
						if (flag) {
							this.mediatorLeft = this.mediatorLeft += (1440 / 60)
						} else {
							this.mediatorLeft = this.mediatorLeft -= (1440 / 60)
						}
						timer += 10
					} else {
						timer = 0
						clearInterval(interval)
						this.flag = false
					}
				}, 10)
			},
			financialQuery() {
				this.$router.push({
					path: '/centre/result-1',
					query: {
						keyword: this.keyword
					}
				})
			},
			async submitConsult() {
				if (this.consult.name == '') {
					this.$message({
						type: 'error',
						message: '请输入姓名'
					})
					return
				}
				if (!this.$ver.verify_phone(this.consult.phone)) {
					this.$message({
						type: 'error',
						message: '请输入正确的手机号'
					})
					return
				}
				if (this.consult.type == '') {
					this.$message({
						type: 'error',
						message: '请选择咨询业务类型'
					})
					return
				}
				if (this.consult.remark == '') {
					this.$message({
						type: 'error',
						message: '请输入备注'
					})
					return
				}
				let res = await this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/addConsulting', {
					phone: this.consult.phone,
					name: this.consult.name,
					consultingType: this.consult.type,
					remarks: this.consult.remark
				})
				if (res.data.code == 200) {
					this.consult = {
						phone: '',
						name: '',
						type: '',
						remark: ''
					}
					this.$alert('我们将于近期与您联系，请您耐心等待。', '咨询提交成功！', {
						confirmButtonText: '确定'
					});
				} else {
					this.$alert(res.data.msg, '发生错误！', {
						confirmButtonText: '确定'
					})
				}
			},
			async fetchData() {
				let [news, mediator, fileList, certify] = await Promise.all([
					this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/findNewsList', {
						pageSize: 3,
						pageNum: 1
					}),
					this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/queryMediatorList'),
					this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/queryDownloadList', {
						pageSize: 2,
						pageNum: 1
					}),
					this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/getAppraisalImgList')
				])
				if (news.data.code == 200) {
					let newsList = news.data.data.list
					for (let i = 0; i < newsList.length; i++) {
						let str = newsList[i]['createAt'].split(' ')[0].split('-')
						let day = str[2]
						let year_month = str[0] + '-' + str[1]
						newsList[i]['day'] = day
						newsList[i]['year_month'] = year_month
					}
					this.news = newsList
				}
				if (fileList.data.code == 200) {
					this.fileList = fileList.data.data.list
				}
				if (mediator.data.code == 200) {
					let list = mediator.data.data
					let array = new Array()
					for (let i = 0; i < list.length; i += 5) {
						array.push(list.slice(i, i + 5))
					}
					this.mediator = array
					this.mediatorWidth = 1440 * array.length
				}
				if (certify.data.code == 200) {
					this.flow = certify.data.data
				}
			}
		},
		mounted() {
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped>
	@keyframes large {
		form {
			scale: 1;
		}

		to {
			scale: 1.1;
		}
	}

	.about {
		width: 100%;

		.fixation {
			display: flex;
			justify-content: space-between;

			.left {
				width: 365px;
				height: 445px;
				background: #1a3070;
				display: flex;
				align-items: flex-end;
				justify-content: center;

				.cont {
					width: 255px;
					padding-bottom: @vue-spacing-col-lg*3;

					.line {
						width: 66px;
						height: 6px;
						background: @vue-bg-color;
						margin-bottom: @vue-spacing-col-lg*2;
					}

					em {
						font-size: @vue-font-size-base*2;
						color: @vue-text-color-inverse;
						font-weight: bold;
					}

					span {
						display: block;
						font-size: @vue-font-size-base+2;
						letter-spacing: 4px;
						color: @vue-text-color-inverse;
						margin-top: @vue-spacing-col-base;
					}
				}
			}

			.right {
				width: calc(100% - 365px);
				height: 100%;
				padding: @vue-spacing-col-lg @vue-spacing-row-lg;
				box-sizing: border-box;

				.title {
					width: 100%;
					height: 90px;
					display: flex;
					align-items: center;
					font-size: @vue-font-size-lg;
					color: @vue-text-color;
				}

				.cont {
					width: 100%;
					display: flex;
					justify-content: space-between;

					.word-number {
						width: calc(100% - 445px);

						.word {
							width: 100%;
							line-height: 30px;
							font-size: @vue-font-size-sm;
							color: @vue-text-color;
						}

						.number {
							width: 100%;
							margin-top: @vue-spacing-col-sm*5;
							display: flex;
							justify-content: space-between;

							.item {
								width: calc(100% / 3);
								height: 100%;
								padding-right: @vue-spacing-row-lg;
								box-sizing: border-box;

								.num {
									width: 100%;
									height: 80px;
									display: flex;
									justify-content: space-between;
									align-items: center;
									font-size: @vue-font-size-lg + 4;
									font-weight: bold;
									color: #1a3070;

									span {
										font-size: @vue-font-size-sm - 2;
										font-weight: normal;
										letter-spacing: 4px;
									}
								}

								.desc {
									width: 100%;
									font-size: @vue-font-size-min;
									color: @vue-text-color-grey;
								}
							}
						}
					}

					.image {
						width: 415px;
						height: 260px;

						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}

	.range {
		width: 100%;
		background: #f6f8fc;
		padding-bottom: 40px;

		.fixation {
			.title {
				width: 100%;
				height: 165px;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base*1.2;
				color: #0a2242;

				em {
					font-size: @vue-font-size-lg*1.6;
					margin-right: @vue-spacing-row-base;
					font-family: '宋体';
					font-weight: bold;
					letter-spacing: 3px;
				}
			}

			ul {
				width: 100%;
				display: flex;
				justify-content: space-between;

				li {
					width: 340px;
					height: 450px;
					background: url(../../assets/range.png) no-repeat;
					background-size: 100% 100%;

					.series {
						width: 100%;
						height: 130px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-top: @vue-spacing-col-lg;
					}

					.name {
						width: 100%;
						display: flex;
						justify-content: center;
						font-size: @vue-font-size-sm + 2;
						font-weight: bold;
					}

					.desc {
						width: 100%;
						height: 125px;
						padding: 0px @vue-spacing-row-base;
						box-sizing: border-box;
						margin-top: @vue-spacing-col-base;
						line-height: 32px;
						color: @vue-text-color;
					}

					.line {
						width: 100%;
						height: 40px;
						padding: 0px @vue-spacing-row-base;
						box-sizing: border-box;
						display: flex;
						align-items: center;

						img {
							margin-right: @vue-spacing-row-sm;
						}
					}
				}
			}
		}
	}

	.query {
		width: 100%;
		padding: @vue-spacing-col-lg*2 0px;
		background: #f6f8fc;
		box-sizing: border-box;

		.fixation {
			display: flex;
			justify-content: space-between;

			.title {
				width: 100%;
				height: 100px;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base*1.2;
				color: #0a2242;

				em {
					font-size: @vue-font-size-lg*1.6;
					margin-right: @vue-spacing-row-base;
					font-family: '宋体';
					font-weight: bold;
					letter-spacing: 3px;
				}
			}

			.query-data {
				width: 620px;

				.query-box {
					width: 100%;

					.query-area {
						width: 100%;
						height: 176px;
						background: #1a3070;
						padding: 0px @vue-spacing-row-lg;
						box-sizing: border-box;
						display: flex;
						align-items: center;

						.input {
							width: 100%;
							height: 55px;
							background: @vue-bg-color;
							border-radius: @vue-border-radius-base;
							overflow: hidden;
							display: flex;
							justify-content: space-between;

							input,
							button {
								height: 100%;
								border: none;
								box-sizing: border-box;
							}

							input {
								width: calc(100% - 130px);
								padding-left: @vue-spacing-row-base;
								box-sizing: border-box;
							}

							button {
								width: 130px;
								background: #ffd171;
							}
						}
					}
				}

				.data {
					width: 100%;

					ul {
						width: 100%;
						background: @vue-bg-color;
						padding: 0px @vue-spacing-row-base;
						box-sizing: border-box;

						li {
							width: 100%;
							height: 75px;

							a {
								width: 100%;
								height: 100%;
								display: flex;
								align-items: center;
								color: @vue-text-color;

								img {
									margin-right: @vue-spacing-row-sm;
								}
							}
						}

						li:first-child {
							border-bottom: 1px dashed #1a3070;
						}

						.more {
							width: 100%;
							height: 30px;
							display: flex;
							justify-content: flex-end;
						}
					}
				}
			}

			.case,
			.news {
				width: 380px;
			}

			.case {
				ul {
					width: 100%;

					li {
						width: 100%;
						height: 146px;
						margin-bottom: 7px;

						a,
						img {
							width: 100%;
							display: block;
						}
					}

					li:hover {
						animation: large 0.3s forwards;
					}

					li:last-child {
						margin-bottom: 0px;
					}
				}
			}

			.news {
				ul {
					width: 100%;

					li {
						width: 100%;
						height: 146px;
						margin-bottom: 7px;
						background: @vue-bg-color;
						padding: @vue-spacing-col-lg @vue-spacing-row-lg;
						box-sizing: border-box;

						a {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: space-between;
							color: @vue-text-color-grey;

							.time {
								width: 80px;
								height: 100%;
								border-right: 1px solid @vue-border-color;
								box-sizing: border-box;

								.day {
									width: 100%;
									height: 60px;
									display: flex;
									align-items: center;
									font-size: @vue-font-size-lg;
									position: relative;

									.line {
										width: 20px;
										height: 2px;
										background: @vue-text-color-grey;
										position: absolute;
										left: 0px;
										bottom: 0px;
									}
								}

								.year-month {
									width: 100%;
									height: calc(100% - 60px);
									display: flex;
									align-items: center;
								}
							}

							.right {
								width: calc(100% - 80px);
								height: 100%;
								padding-left: @vue-spacing-row-base;
								box-sizing: border-box;

								.news-title {
									width: 100%;
									height: calc(100% - 40px);
									line-height: 30px;
								}

								.arrow {
									width: 100%;
									height: 40px;
									display: flex;
									align-items: center;
								}
							}
						}
					}
				}
			}
		}
	}

	.project {
		width: 100%;
		padding-bottom: 50px;

		.fixation {
			.title {
				width: 100%;
				height: 165px;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base*1.2;
				color: #0a2242;

				em {
					font-size: @vue-font-size-lg*1.6;
					margin-right: @vue-spacing-row-base;
					font-family: '宋体';
					font-weight: bold;
					letter-spacing: 3px;
				}
			}

			.block-grey {
				background: #dddddd;
			}

			.block-grey-tint {
				background: #f6f6f6;
			}

			.block-blue {
				background: #1c4384;
			}

			.link,
			.block-grey,
			.block-grey-tint,
			.block-blue {
				border-radius: @vue-border-radius-base;
				overflow: hidden;
			}

			.block-deep-blue {
				font-size: 14px;

				.link {
					width: 100%;
					height: 100%;
					background: #0b2242;
					padding: 0px 15px;
					box-sizing: border-box;

					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
				}

				a {
					color: #FFFFFF;
				}
			}

			.link:hover {
				animation: large 0.3s forwards;
			}

			.block {
				width: 100%;
				height: 420px;
				display: flex;
				justify-content: space-between;

				.block-1 {
					width: 290px;
					height: 100%;

					.block-1-top {
						width: 100%;
						height: 80px;
						display: flex;
						justify-content: space-between;

						.link {
							width: 190px;
							height: 100%;
							background: #0b2242;

							a {
								width: 100%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								color: @vue-text-color-inverse;
								font-size: @vue-font-size-sm;
							}
						}

						.block-grey {
							width: 90px;
							height: 100%;
						}
					}

					.block-1-bottom {
						width: 100%;
						height: 330px;
						display: flex;
						justify-content: space-between;
						margin-top: 10px;

						.block-1-left {
							width: 90px;
							height: 100%;
							display: flex;
							align-content: space-between;
							flex-wrap: wrap;

							.block-grey-tint,
							.block-deep-blue {
								width: 100%;
								height: 110px;
							}

							.block-grey {
								width: 100%;
								height: 80px;
							}
						}

						.block-1-right {
							width: 190px;
							height: 100%;

							.block-1-right-top {
								width: 100%;
								height: 150px;

								.link {
									width: 100%;
									height: 100%;
									background: url(../../assets/project-link-1.png) no-repeat;
									background-size: 100% 100%;

									a {
										width: 100%;
										height: 100%;
										display: flex;
										justify-content: center;
										align-items: center;
										text-align: center;
										color: @vue-text-color-inverse;
										font-size: @vue-font-size-sm;
									}
								}
							}

							.block-1-right-btm {
								width: 100%;
								height: 170px;
								display: flex;
								justify-content: space-between;
								margin-top: 10px;

								.block-1-r-b-item {
									width: 90px;
									height: 100%;
								}

								.block-1-r-b-item:first-child {
									.block-grey-tint {
										width: 100%;
										height: 120px;
									}

									.block-grey {
										width: 100%;
										height: 40px;
										margin-top: 10px;
									}
								}

								.block-1-r-b-item:last-child {
									.link {
										width: 100%;
										height: 80px;
										background: #1c4384;

										a {
											width: 100%;
											height: 100%;
											display: flex;
											justify-content: center;
											align-items: center;
											color: @vue-text-color-inverse;
										}
									}

									.block-grey {
										width: 100%;
										height: 80px;
										margin-top: 10px;
									}
								}
							}
						}
					}
				}

				.block-2 {
					width: 210px;
					height: 100%;

					.block-2-item {
						width: 100%;
						height: 205px;

						.link {
							width: 100%;
							height: 100%;

							a {
								width: 100%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								color: @vue-text-color-inverse;
							}
						}
					}

					.block-2-item:first-child {
						.link {
							background: url(../../assets/project-link-2.png) no-repeat;
							background-size: 100% 100%;
						}
					}

					.block-2-item:last-child {
						margin-top: 10px;

						.link {
							background: #0b2242;
						}
					}
				}

				.block-3 {
					width: 410px;
					height: 100%;

					.link {
						width: 100%;
						height: 100%;
						background: url(../../assets/project-link-3.png) no-repeat;
						background-size: 100% 100%;

						a {
							width: 100%;
							height: 100%;
							color: @vue-text-color-inverse;
							padding: 0px @vue-spacing-row-base*2;
							box-sizing: border-box;
							display: block;

							h3 {
								font-size: @vue-font-size-base;
								font-weight: normal;
								margin-top: @vue-spacing-col-lg*4;
							}

							h4 {
								font-size: @vue-font-size-base - 4;
								font-weight: normal;
								margin-top: @vue-spacing-col-base*2;
							}

							img {
								width: 32px;
								height: 32px;
								margin-top: @vue-spacing-col-lg*6;
							}
						}
					}
				}

				.block-4 {
					width: 210px;

					.block-4-top {
						width: 100%;
						height: 80px;

						.block-grey-tint {
							height: 100%;
							width: 100px;
							float: left;
						}

						.block-grey {
							float: right;
						}

						.block-grey:nth-child(2) {
							width: 100px;
							height: 55px;
						}

						.block-grey:last-child {
							width: 100px;
							height: 15px;
							margin-top: 10px;
						}
					}

					.block-4-mid {
						width: 100%;
						height: 110px;
						margin-top: 10px;

						.link {
							width: 100%;
							height: 100%;

							a {
								width: 100%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								background: #0b2242;
								color: @vue-text-color-inverse;
								font-size: @vue-font-size-sm;
							}
						}
					}

					.block-4-btm {
						width: 100%;
						height: 210px;
						margin-top: 10px;

						.link {
							width: 100%;
							height: 100%;
							background: url(../../assets/project-link-4.png) no-repeat;
							background-size: 100% 100%;

							a {
								width: 100%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								color: @vue-text-color-inverse;
								font-size: @vue-font-size-sm;
							}
						}
					}
				}

				.block-5 {
					width: 290px;

					.block-5-top {
						width: 100%;
						height: 325px;
						display: flex;
						justify-content: space-between;

						.block-5-left {
							width: 180px;
							height: 100%;

							.link {
								width: 100%;
							}

							.link:first-child {
								height: 170px;
								background: url(../../assets/project-link-5.png) no-repeat;
								background-size: 100% 100%;

								a {
									width: 100%;
									height: 100%;
									display: flex;
									justify-content: center;
									align-items: center;
									text-align: center;
									color: @vue-text-color-inverse;
									font-size: @vue-font-size-sm;
								}
							}

							.link:nth-child(2) {
								width: 100%;
								height: 80px;
								background: #0b2242;
								margin-top: 10px;

								a {
									width: 100%;
									height: 100%;
									display: flex;
									justify-content: center;
									align-items: center;
									text-align: center;
									color: @vue-text-color-inverse;
									font-size: @vue-font-size-sm;
								}
							}

							.block-5-l-b {
								width: 100%;
								height: 55px;
								margin-top: 10px;
								display: flex;
								justify-content: space-between;

								.block-grey,
								.block-blue {
									width: 85px;
									height: 100%;
								}
							}
						}

						.block-5-right {
							width: 100px;
							height: 100%;

							.block-grey-tint {
								width: 100%;
								height: 110px;
							}

							.block-grey {
								width: 100%;
								height: 85px;
								margin: 10px 0px;
							}
						}
					}

					.block-5-btm {
						width: 100%;
						height: 85px;
						margin-top: 10px;

						.link {
							width: 100%;
							height: 100%;
							background: #0b2242;

							a {
								width: 100%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: @vue-font-size-sm;
								color: @vue-text-color-inverse;
							}
						}
					}
				}
			}
		}
	}

	.flow {
		width: 100%;
		background: #f6f8fc;

		.fixation {
			.title {
				width: 100%;
				height: 165px;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base*1.2;
				color: #0a2242;
				letter-spacing: 3px;

				em {
					font-size: @vue-font-size-lg*1.6;
					margin-right: @vue-spacing-row-base;
					font-family: '宋体';
					font-weight: bold;
				}
			}
		}

		.flow-box {
			width: 100%;
			display: flex;
			justify-content: center;

			img {
				display: block;
			}
		}
	}

	.qualifications {
		width: 100%;

		.fixation {
			.title {
				width: 100%;
				height: 165px;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base*1.2;
				color: #0a2242;

				em {
					font-size: @vue-font-size-lg*1.6;
					margin-right: @vue-spacing-row-base;
					font-family: '宋体';
					font-weight: bold;
					letter-spacing: 3px;
				}
			}

			.list {
				width: 100%;
				display: flex;
				flex-wrap: wrap;

				.item {
					width: 330px;
					height: 290px;
					background: url(../../assets/qualifications.png) no-repeat;
					background-size: 100% 100%;
					margin-bottom: @vue-spacing-col-lg;
					margin-right: 40px;
					padding: 40px 30px 45px 23px;
					box-sizing: border-box;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.item:nth-child(4n) {
					margin-right: 0px;
				}
			}
		}
	}

	.mediator {
		width: 100%;
		background: #0b2242;
		padding-bottom: 50px;

		.fixation {
			.title {
				width: 100%;
				height: 165px;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base*1.2;
				color: @vue-text-color-inverse;

				em {
					font-size: @vue-font-size-lg*1.6;
					margin-right: @vue-spacing-row-base;
					color: @vue-text-color-gold;
					font-family: '宋体';
					font-weight: bold;
					letter-spacing: 3px;
				}
			}

			.list {
				width: 100%;
				height: 460px;
				overflow-x: hidden;

				.list-box {
					height: 100%;
					display: flex;

					.list-item {
						width: 1440px;
						display: flex;

						.item {
							width: 260px;
							height: 460px;
							background: url(../../assets/mediator.png) no-repeat;
							background-size: 100% 100%;
							margin-right: 40px;
							padding: 24px 15px 0px 15px;
							box-sizing: border-box;

							.img {
								width: 100%;
								height: 294px;
								position: relative;

								.line {
									width: 26px;
									height: 2px;
									background: #fed072;
									position: absolute;
									right: -12px;
									top: 23px;
								}

								img {
									width: 100%;
									height: 100%;
								}
							}

							.name {
								width: 110px;
								height: 36px;
								display: flex;
								justify-content: center;
								align-items: center;
								color: @vue-text-color;
								background: #fed072;
								position: relative;
								left: -15px;
								top: -24px;
							}

							.job {
								width: 100%;
								text-align: center;
								color: @vue-text-color-inverse;
								font-size: @vue-font-size-base - 4;
								margin-top: @vue-spacing-col-sm;

								span {
									display: block;
									font-size: @vue-font-size-sm - 2;
									margin-bottom: @vue-spacing-col-sm;
								}
							}
						}

						.item:last-child {
							margin-right: 0px;
						}
					}
				}
			}

			.pager {
				width: 100%;
				height: 90px;
				display: flex;
				justify-content: center;
				align-items: flex-end;

				.prev,
				.next {
					width: 50px;
					height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: @vue-text-color-inverse;
					cursor: pointer;
				}

				.prev {
					background: #005fc5;
				}

				.next {
					background: #1c4384;
				}
			}
		}
	}

	.consult {
		width: 100%;
		padding-bottom: @vue-spacing-col-lg*4;

		.fixation {
			.title {
				width: 100%;
				height: 165px;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base*1.2;
				color: #0a2242;

				em {
					font-size: @vue-font-size-lg*1.6;
					margin-right: @vue-spacing-row-base;
					font-family: '宋体';
					font-weight: bold;
					letter-spacing: 3px;
				}
			}

			.form {
				width: 100%;
				height: 390px;
				display: flex;
				justify-content: space-between;
				background: @vue-bg-color;

				.form-left,
				.form-right {
					width: 50%;
					height: 100%;
				}

				.form-left {
					padding: @vue-spacing-col-lg @vue-spacing-row-lg;
					box-sizing: border-box;

					.form-left-flex {
						width: 100%;
						height: 100%;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;

						.form-title,
						.form-desc {
							width: 100%;
							display: flex;
							align-items: center;
							color: @vue-text-color;
						}

						.form-title {
							height: 50px;
							font-size: @vue-font-size-sm - 2;
						}

						.form-desc {
							font-size: @vue-font-size-min;
							color: @vue-text-color-grey;
							margin-bottom: @vue-spacing-col-lg;
						}

						.form-left-item {
							width: 48%;

							.el-input {
								height: 60px;

								input {
									height: 100%;
								}
							}

							.el-textarea {
								height: 142px;

								textarea {
									height: 142px;
								}
							}

							.el-button {
								width: 100%;
								height: 40px;
								font-size: @vue-font-size-sm + 2;
							}

							.el-select {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
</style>
