<template>
	<div class="pubfooter">
		<div class="fixation">
			<div class="link-contact">
				<div class="link">
					<ul>
						<div class="title">
							关于我们
						</div>
						<li v-for="(item,index) in about" :key="index">
							<router-link :to="item.url">
								{{item.title}}
							</router-link>
						</li>
					</ul>
					<ul>
						<div class="title">
							产品方案
						</div>
						<li v-for="(item,index) in caseList" :key="index">
							<router-link :to="item.url">
								{{item.title}}
							</router-link>
						</li>
					</ul>
					<ul>
						<div class="title">
							鉴定指南
						</div>
						<li v-for="(item,index) in fingerpost" :key="index">
							<router-link :to="item.url">
								{{item.title}}
							</router-link>
						</li>
					</ul>
					<div class="qrcode">
						<img :src="config.erCodeOne" alt=""><br>
						关注公众号
					</div>
					<div class="qrcode">
						<img :src="config.erCodeTwo" alt=""><br>
						企业微信客服
					</div>
				</div>
				<div class="contact">
					<div class="name">
						中经天平司法鉴定中心
					</div>
					<div class="item">
						<div class="icon">
							<img src="@/assets/footer-icon-mail.png" alt="">
						</div>
						<div class="right">
							<div class="key">
								服务邮箱
							</div>
							<div class="value">
								{{config.email}}
							</div>
						</div>
					</div>
					<div class="item">
						<div class="icon">
							<img src="@/assets/footer-icon-phone.png" alt="">
						</div>
						<div class="right">
							<div class="key">
								联系电话（工作日9:30-17:30）
							</div>
							<div class="value">
								{{config.phone}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="copyright">
				<!-- <a href="http://" target="_blank" rel="noopener noreferrer nofollow">
					<img src="@/assets/footer-icon-police.png" alt="">京网公安备案：{{config.recordNoJwga}}号
				</a>
				<span>京网信备：{{config.recordNoJwxb}}号</span> -->
				<a href="https://beian.miit.gov.cn" target="_blank"
					rel="nofollow">冀ICP备：{{config.recordNoJicp}}</a>
				<br>
				友情链接：<a class="friend" :href="item.link" v-for="(item,index) in JSON.parse(config.friendlyLink)" :key="index"
					:rel="item.follow ? '' : 'nofollow'">{{item.name}}</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['config'],
		name: 'pubfooter',
		data() {
			return {
				about: [{
					title: '中心简介',
					url: '/about'
				}, {
					title: '资质证书',
					url: '/about/license'
				}, {
					title: '联系我们',
					url: '/about/contact'
				}],
				caseList: [{
						title: '数据恢复',
						url: '/product'
					},
					{
						title: '移动取证',
						url: '/product/mobile'
					},
					{
						title: '司法区块链',
						url: '/product/blockchain'
					},
					{
						title: '电子证据云平台',
						url: '/product/cloud'
					}
				],
				fingerpost: [{
						title: '服务流程',
						url: '/guide'
					},
					{
						title: '鉴定标准',
						url: '/guide/standard'
					}
				]
			}
		}
	}
</script>

<style lang="less">
	.pubfooter {
		width: 100%;
		background: #23293b;
		padding: @vue-spacing-col-lg*2 0px @vue-spacing-col-lg 0px;
		box-sizing: border-box;

		.link-contact {
			width: 100%;
			height: 210px;
			display: flex;
			justify-content: space-between;

			.link {
				width: 790px;
				display: flex;
				justify-content: space-between;
				border-right: 1px solid @vue-border-color;
				padding-right: @vue-spacing-row-lg*2;
				box-sizing: border-box;

				ul {
					.title {
						width: 100%;
						height: 40px;
						display: flex;
						align-items: center;
						color: @vue-text-color-inverse;
						font-size: @vue-font-size-sm + 2;
						font-weight: bold;
					}

					li {
						line-height: 34px;

						a {
							color: @vue-text-color-inverse;
						}
					}
				}

				.qrcode {
					padding-top: @vue-spacing-col-sm + 2;
					box-sizing: border-box;
					text-align: center;
					color: @vue-text-color-inverse;

					img {
						width: 130px;
						margin-bottom: @vue-spacing-col-sm;
					}
				}
			}

			.contact {
				width: calc(100% - 790px);
				height: 100%;
				padding-left: @vue-spacing-row-lg*2;
				box-sizing: border-box;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;

				.name {
					width: 100%;
					height: 50px;
					display: flex;
					align-items: center;
					font-size: @vue-font-size-base;
					color: @vue-text-color-inverse;
				}

				.item {
					height: 100px;
					display: flex;
					margin-right: @vue-spacing-row-lg;

					.icon {
						width: 50px;
						height: 50px;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.right {
						width: calc(100% - 50px);
						height: 100%;

						.key {
							width: 100%;
							height: 50px;
							display: flex;
							align-items: center;
							font-size: @vue-font-size-sm;
							color: @vue-text-color-inverse;
						}

						.value {
							line-height: 30px;
							color: @vue-text-color-inverse;
						}
					}
				}
			}
		}

		.copyright {
			width: 100%;
			height: 80px;
			border-top: 1px solid @vue-border-color;
			text-align: center;
			align-items: center;
			color: @vue-text-color-inverse;
			padding-top: 20px;
			box-sizing: border-box;
			line-height: 30px;
			margin-top: @vue-spacing-col-lg;

			span,
			a {
				margin-right: @vue-spacing-row-sm;
				padding-right: @vue-spacing-row-sm;

				img {
					margin-right: @vue-spacing-row-sm / 2;
					position: relative;
					top: 3px;
				}
			}

			a {
				color: @vue-text-color-inverse;
				margin-right: @vue-spacing-row-sm;
				padding-right: @vue-spacing-row-sm;
			}
			
			.friend{
				border-right: 1px solid @vue-border-color;
			}

			a:last-child {
				border: none;
			}
		}
	}
</style>
