<template>
	<div class="admin-page">
		<custom-table :pageConfig="pageConfig" :multipleSelection="multipleSelection" :buttonGroup="buttonGroup"
			:filterValue="filterValue" :tableFilter="tableFilter" :tableConfig="tableConfig" :tableData="tableData"
			@pageChange="pageChange" @buttonClick="buttonClick" @filterChange="filterChange"
			@scopeButtonClick="scopeButtonClick">
		</custom-table>
		<el-dialog :visible="formFlag" title="编辑证书" @close="closeForm">
			<el-form ref="elForm" :model="formData" size="medium" label-width="100px">
				<el-form-item label="证书照片" prop="imgUrl" required>
					<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="formData.imgUrl!=''" :src="formData.imgUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item size="large">
					<el-button type="primary" @click="submitForm">提交</el-button>
					<el-button @click="resetForm">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'clerk',
		data() {
			return {
				pageConfig: {
					totalWord: '资质证书',
					total: 0,
					pageSize: 5,
					currentPage: 1,
				},
				multipleSelection: [],
				buttonGroup: [{
					type: 'primary',
					size: 'mini',
					plain: true,
					icon: 'el-icon-edit',
					sign: 'new',
					word: '添加证书'
				}],
				filterValue: {},
				tableFilter: [],
				tableConfig: {
					border: true,
					selection: false,
					showOverflowTooltip: true,
					tableHeader: [{
							prop: 'imgUrl',
							label: '证书图片',
							width: 300,
							template: true
						},
						{
							prop: 'createAt',
							label: '创建时间'
						}
					],
					tableEvent: [{
							icon: 'el-icon-edit',
							name: '编辑',
							color: 'green',
							sign: 'edit'

						},
						{
							icon: 'el-icon-delete',
							name: '删除',
							color: 'red',
							sign: 'delete'
						}

					],
					emptyDescription: '当前没有数据',
				},
				tableData: [],
				formFlag: false,
				formData: {
					imgUrl: '',
					id: ''
				},
				uploadUrl: ''
			};
		},
		methods: {
			pageChange() {
				this.fetchData()
			},
			buttonClick(sign) {
				if (sign == 'new') {
					this.formFlag = true
				}
			},
			filterChange(e) {
				this.fetchData()
			},
			scopeButtonClick(row, sign) {
				if (sign == 'delete') {
					this.certifyDelete(row.id)
				}
				if (sign == 'edit') {
					this.formData = row
					this.formFlag = true
				}
			},
			//删除证书
			certifyDelete(id) {
				this.$confirm('此操作将删除该证书, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.certifyDelete({
						id: id
					}, res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.fetchData()
						}
					})
				}).catch(() => {});
			},
			fetchData() {
				this.$api.certification({
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage
				}, res => {
					if (res.code == 200) {
						this.pageConfig.total = res.data.total
						this.tableData = res.data.list
					}
				})
			},
			submitForm() {
				this.$refs['elForm'].validate(valid => {
					if (valid) {
						this.certifySave()
					}
				})
			},
			certifySave() {
				this.$api.certifySave(this.formData, res => {
					if (res.code == 200) {
						this.fetchData()
						let message = ''
						if (this.formData.id == '') {
							message = '添加成功'
						} else {
							message = '修改成功'
						}
						this.$message({
							message: message,
							type: 'success',
							duration: 2000
						})
						this.closeForm()
					}
				})
			},
			resetForm() {
				this.$refs['elForm'].resetFields()
			},
			handleAvatarSuccess(res) {
				if (res.code == 200) {
					this.formData.imgUrl = res.data.fileUrl
				}
			},
			beforeAvatarUpload(file) {
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return isLt5M;
			},
			closeForm() {
				this.formData = {
					imgUrl: '',
					id: ''
				}
				this.formFlag = false
			}
		},
		mounted() {
			this.uploadUrl = this.$api.requestUrl + 'uploadImg'
			this.fetchData()
		}
	}
</script>

<style lang="less" scoped="scoped">
	.avatar-uploader .el-upload {
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
		border: 1px dashed #d9d9d9;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
