<template>
	<div class="admin-page">
		<custom-table :pageConfig="pageConfig" :multipleSelection="multipleSelection" :buttonGroup="buttonGroup"
			:filterValue="filterValue" :tableFilter="tableFilter" :tableConfig="tableConfig" :tableData="tableData"
			@pageChange="pageChange" @buttonClick="buttonClick" @filterChange="filterChange"
			@scopeButtonClick="scopeButtonClick">
		</custom-table>
		<el-dialog :visible="visibleFlag" title="申请详情" @close="visibleFlag = false">
			<div class="line">
				<span class="key">留言姓名：</span>
				<span class="value">{{obj.name}}</span>
			</div>
			<div class="line">
				<span class="key">留言电话：</span>
				<span class="value">{{obj.phone}}</span>
			</div>
			<div class="line">
				<span class="key">咨询业务类型：</span>
				<span class="value">{{obj.consultingType}}</span>
			</div>
			<div class="line">
				<span class="key">留言时间：</span>
				<span class="value">{{obj.createAt}}</span>
			</div>
			<div class="line">
				<span class="key">反馈状态：</span>
				<span class="value">{{obj.status == 1 ? '已处理' : '未处理'}}</span>
			</div>
			<div class="line">
				<span class="key">备注：</span>
				<span class="value">{{obj.remarks}}</span>
			</div>
			<div class="button">
				<el-button type="primary" round @click="disposeConsult(obj.id)" :disabled="obj.status == 1">
					{{obj.status == 1 ? '已处理' : '标记为已处理'}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'apply',
		data() {
			return {
				pageConfig: {
					totalWord: '全部数据',
					total: 0,
					pageSize: 13,
					currentPage: 1,
				},
				multipleSelection: [],
				buttonGroup: [{
					type: 'primary',
					size: 'mini',
					plain: true,
					icon: 'el-icon-plus',
					word: '标记全部已处理',
					sign: 'dispose'
				}],
				filterValue: {
					keyword: '',
					criteria: '',
					date: '',
					daterange: ''
				},
				tableFilter: [],
				tableConfig: {
					border: true,
					selection: false,
					showOverflowTooltip: true,
					auctionMenu: true,
					tableHeader: [{
						prop: 'name',
						label: '申请人名称',
						width: 150
					}, {
						prop: 'phone',
						label: '申请人电话',
						width: 150
					}, {
						prop: 'consultingType',
						label: '咨询业务类型',
						width: 200
					}, {
						prop: 'createAt',
						label: '申请时间',
						width: 230
					}, {
						prop: 'consultStatus',
						label: '反馈状态',
						width: 100,
						template: true
					}, {
						prop: 'remarks',
						label: '备注',
						width: 500
					}],
					tableEvent: [{
							icon: 'el-icon-search',
							name: '查看',
							color: 'blue',
							sign: 'search'
						}, {
							icon: 'el-icon-edit',
							name: '标记已处理',
							color: 'blue',
							sign: 'dispose'
						},
						{
							icon: 'el-icon-delete',
							name: '删除',
							color: 'red',
							sign: 'delete'
						}
					],
					emptyDescription: '当前没有数据',
				},
				tableData: [],
				obj: {},
				visibleFlag: false
			};
		},
		methods: {
			// 页面变化
			pageChange() {
				this.fetchData()
			},
			// 右上按钮
			buttonClick(sign) {
				if (sign === 'dispose') {
					this.disposeConsultAll()
				}
			},
			// 筛选条件变化
			filterChange() {
				console.log(this.filterValue);
			},
			// 操作按钮
			scopeButtonClick(row, sign) {
				if (sign == 'search') {
					this.obj = row
					this.visibleFlag = true
				}
				if (sign === 'delete') {
					this.deleteConsult(row)
				}
				if (sign == 'dispose') {
					this.disposeConsult(row.id)
				}
			},
			disposeConsult(id) {
				this.$api.consultDispose({
					id: id
				}, res => {
					if (res.code == 200) {
						this.$message({
							message: '处理成功！',
							duration: 2000,
							type: 'success'
						})
						this.fetchData()
						if (this.visibleFlag) {
							this.visibleFlag = false
						}
					}
				})
			},
			disposeConsultAll() {
				this.$api.consultDisposeAll({}, res => {
					if (res.code == 200) {
						this.$message({
							message: '全部处理成功！',
							duration: 2000,
							type: 'success'
						})
						this.fetchData()
					}
				})
			},
			fetchData() {
				this.$api.consultList({
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage
				}, res => {
					if (res.code == 200) {
						this.pageConfig.total = res.data.total
						this.tableData = res.data.list
					}
				})
			},
			deleteConsult(row) {
				this.$confirm('确认要删除这个咨询吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.consultDelete({
						id: row.id
					}, res => {
						if (res.code == 200) {
							this.fetchData()
							this.$message({
								message: '删除成功',
								type: 'success',
								duration: 2000
							})
						}
					})
				}).catch(() => {});
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped>
	.line {
		width: 100%;
		padding: 7px;
		line-height: 36px;
	}

	.button {
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;

		.el-button {
			width: 200px;
		}
	}
</style>
