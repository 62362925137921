<template>
	<div class="login" @keydown.enter="keydownMethod">
		<div class="login-content">
			<div class="login-title">
				中经天平司法鉴定中心后台管理系统
			</div>
			<el-form ref="loginForm" :model="loginForm" label-width="80px" :rules="rules">
				<el-form-item label="账号" prop="account">
					<el-input v-model="loginForm.account" placeholder="请输入账号" clearable></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input type="password" v-model="loginForm.password" placeholder="请输入密码" clearable></el-input>
				</el-form-item>
				<div class="login-button">
					<el-button type="primary" :loading="loading" @click="doLogin()">立即登录</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex'
	export default {
		data() {
			let verify_account = (rule, value, callback) => {
				if (this.loginForm.account == '') {
					callback(new Error('请输入账号'))
				} else {
					callback()
				}
			}
			let verify_password = (rule, value, callback) => {
				if (!this.$ver.verify_password(this.loginForm.password)) {
					callback(new Error('请输入5-18位密码'))
				} else {
					callback()
				}
			}
			return {
				loginForm: {
					account: '',
					password: ''
				},
				rules: {
					account: [{
						required: true,
						trigger: 'blur',
						validator: verify_account
					}],
					password: [{
						required: true,
						trigger: 'blur',
						validator: verify_password
					}]
				},
				loading: false
			}
		},
		mounted() {
			localStorage.clear()
		},
		methods: {
			...mapMutations(['setAdmToken']),
			doLogin() {
				this.$refs.loginForm.validate(valid => {
					if (valid) {
						this.submitFormData()
					}
				})
			},
			keydownMethod() {
				this.doLogin()
			},
			submitFormData() {
				this.loading = true
				let params = {
					username: this.loginForm.account,
					password: this.loginForm.password
				}
				this.$api.admlogin(params, res => {
					this.loading = false
					if (res.code == 200) {
						this.setAdmToken(res.data.token)
						this.$notify({
							title: '成功',
							message: '登录成功，即将为您跳转',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.$router.push({
									path: '/admin'
								})
							}
						})
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.login {
		width: 100vw;
		height: 100vh;
		min-width: 1600px;
		min-height: 860px;
		background: url(../assets/manage-back.jpg);
		display: flex;
		justify-content: center;
		align-items: center;

		.login-content {
			width: 500px;
			background: @vue-bg-color;
			border-radius: @vue-border-radius-lg;
			box-shadow: 0px 0px 0px @vue-border-color;
			padding: @vue-spacing-row-lg @vue-spacing-col-lg;

			.login-title {
				width: 100%;
				height: 60px;
				display: flex;
				justify-content: center;
				font-size: @vue-font-size-base;
				font-weight: bold;
			}

			.login-button {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-top: @vue-spacing-col-lg;

				.el-button {
					width: 150px;
				}
			}
		}
	}
</style>
