import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '@/views/index.vue'
import home from '@/views/index/index.vue'
import news from '@/views/index/news/index.vue'
import newsId from '@/views/index/news/_id.vue'
import product from '@/views/index/product.vue'
import recover from '@/views/index/product/index.vue'
import mobile from '@/views/index/product/mobile-evidence.vue'
import blockchain from '@/views/index/product/block-chain.vue'
import cloud from '@/views/index/product/cloud.vue'
import copyright from '@/views/index/product/copyright.vue'
import service from '@/views/index/product/service.vue'
import seal from '@/views/index/product/seal-up.vue'
import guide from '@/views/index/guide.vue'
import process from '@/views/index/guide/index.vue'
import way from '@/views/index/guide/way.vue'
import content from '@/views/index/guide/content.vue'
import standard from '@/views/index/guide/standard.vue'
import standardId from '@/views/index/guide/_id.vue'
import download from '@/views/index/guide/download.vue'
import apply from '@/views/index/guide/apply.vue'
import centre from '@/views/index/centre.vue'
import judicial from '@/views/index/centre/index.vue'
import doc from '@/views/index/centre/document.vue'
import result_1 from '@/views/index/centre/result-1.vue'
import result_2 from '@/views/index/centre/result-2.vue'
import result_3 from '@/views/index/centre/result-3.vue'
import result_4 from '@/views/index/centre/result-4.vue'
import packet from '@/views/index/package.vue'
import about from '@/views/index/about.vue'
import desc from '@/views/index/about/index.vue'
import license from '@/views/index/about/license.vue'
import contact from '@/views/index/about/contact.vue'
import caseList from '@/views/index/case/index.vue'
import caseId from '@/views/index/case/_id.vue'

import manage from '@/views/manage.vue'
import admin from '@/views/admin.vue'
import news_admin from '@/views/admin/index.vue'
import news_issue from '@/views/admin/issue.vue'
import standard_admin from '@/views/admin/standard.vue'
import standard_edit from '@/views/admin/edit.vue'
import data_admin from '@/views/admin/data.vue'
import case_admin from '@/views/admin/case.vue'
import case_upload from '@/views/admin/upload.vue'
import certification_admin from '@/views/admin/certification.vue'
import clerk_admin from '@/views/admin/clerk.vue'
import apply_admin from '@/views/admin/apply.vue'
import message_admin from '@/views/admin/message.vue'
import paper_admin from '@/views/admin/paper.vue'
import swiper_admin from '@/views/admin/swiper.vue'
import config_admin from '@/views/admin/config.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'index',
	component: index,
	redirect: '/home',
	children: [{
		path: '/home',
		name: 'home',
		component: home,
		meta: {
			title: '河北中经天平司法鉴定中心-首页',
			name: '首页'
		}
	}, {
		path: '/news',
		name: 'news',
		component: news,
		meta: {
			title: '河北中经天平司法鉴定中心-新闻动态',
			name: '新闻动态'
		}
	}, {
		path: '/news/:id',
		name: 'news-content',
		component: newsId,
		meta: {
			title: '河北中经天平司法鉴定中心-新闻动态',
			name: '新闻动态'
		}
	}, {
		path: '/product',
		name: 'product',
		component: product,
		redirect: '/product/recover',
		children: [{
			path: '/product/recover',
			name: 'recover',
			component: recover,
			meta: {
				title: '河北中经天平司法鉴定中心-数据恢复服务',
				name: '数据恢复服务'
			}
		}, {
			path: '/product/mobile',
			name: 'mobile',
			component: mobile,
			meta: {
				title: '河北中经天平司法鉴定中心-移动存取证服务',
				name: '移动存取证服务'
			}
		}, {
			path: '/product/blockchain',
			name: 'blockchain',
			component: blockchain,
			meta: {
				title: '河北中经天平司法鉴定中心-司法区块链',
				name: '司法区块链'
			}
		}, {
			path: '/product/cloud',
			name: 'cloud',
			component: cloud,
			meta: {
				title: '河北中经天平司法鉴定中心-司法电子证据云平台',
				name: '司法电子证据云平台'
			}
		}, {
			path: '/product/copyright',
			name: 'copyright',
			component: copyright,
			meta: {
				title: '河北中经天平司法鉴定中心-一站式版权保护服务',
				name: '一站式版权保护服务'
			}
		}, {
			path: '/product/service',
			name: 'service',
			component: service,
			meta: {
				title: '河北中经天平司法鉴定中心-鉴定调解一体化服务',
				name: '鉴定调解一体化服务'
			}
		}, {
			path: '/product/seal',
			name: 'seal',
			component: seal,
			meta: {
				title: '河北中经天平司法鉴定中心-执行查封取证服务',
				name: '执行查封取证服务'
			}
		}]
	}, {
		path: '/guide',
		name: 'guide',
		component: guide,
		redirect: '/guide/process',
		children: [{
			path: '/guide/process',
			name: 'process',
			component: process,
			meta: {
				title: '河北中经天平司法鉴定中心-服务流程',
				name: '服务流程'
			}
		}, {
			path: '/guide/way',
			name: 'way',
			component: way,
			meta: {
				title: '河北中经天平司法鉴定中心-服务方式',
				name: '服务方式'
			}
		}, {
			path: '/guide/content',
			name: 'content',
			component: content,
			meta: {
				title: '河北中经天平司法鉴定中心-服务内容',
				name: '服务内容'
			}
		}, {
			path: '/guide/standard',
			name: 'standard',
			component: standard,
			meta: {
				title: '河北中经天平司法鉴定中心-鉴定标准',
				name: '鉴定标准'
			}
		}, {
			path: '/guide/download',
			name: 'download',
			component: download,
			meta: {
				title: '河北中经天平司法鉴定中心-资料下载',
				name: '资料下载'
			}
		}, {
			path: '/guide/apply',
			name: 'apply',
			component: apply,
			meta: {
				title: '河北中经天平司法鉴定中心-鉴定申请',
				name: '鉴定申请'
			}
		}, {
			path: '/guide/:id',
			name: 'guideContent',
			component: standardId,
			meta: {
				title: '河北中经天平司法鉴定中心-鉴定标准',
				name: '鉴定标准'
			}
		}]
	}, {
		path: '/centre',
		name: 'centre',
		component: centre,
		redirect: '/centre/judicial',
		children: [{
			path: '/centre/judicial',
			name: 'judicial',
			component: judicial,
			meta: {
				title: '河北中经天平司法鉴定中心-司法存证验证',
				name: '司法存证验证'
			}
		}, {
			path: '/centre/document',
			name: 'document',
			component: doc,
			meta: {
				title: '河北中经天平司法鉴定中心-文件内容验证',
				name: '文件内容验证'
			}
		}, {
			path: '/centre/result-1',
			name: 'result-1',
			component: result_1,
			meta: {
				title: '河北中经天平司法鉴定中心-金融调解核验',
				name: '金融调解核验'
			}
		}, {
			path: '/centre/result-2',
			name: 'result-2',
			component: result_2,
			meta: {
				title: '河北中经天平司法鉴定中心-区块链与证书验证',
				name: '区块链验证'
			}
		}, {
			path: '/centre/result-3',
			name: 'result-3',
			component: result_3,
			meta: {
				title: '河北中经天平司法鉴定中心-DCI验证',
				name: 'DCI验证'
			}
		}, {
			path: '/centre/result-4',
			name: 'result-4',
			component: result_4,
			meta: {
				title: '河北中经天平司法鉴定中心-电子签名核验',
				name: '电子签名核验'
			}
		}]
	}, {
		path: '/package',
		name: 'package',
		component: packet,
		meta: {
			title: '河北中经天平司法鉴定中心-期望还款方案',
			name: '期望还款方案'
		}
	}, {
		path: '/about',
		name: 'about',
		component: about,
		redirect: '/about/desc',
		children: [{
			path: '/about/desc',
			name: 'desc',
			component: desc,
			meta: {
				title: '河北中经天平司法鉴定中心-中心简介',
				name: '中心简介'
			}
		}, {
			path: '/about/license',
			name: 'license',
			component: license,
			meta: {
				title: '河北中经天平司法鉴定中心-资质证书',
				name: '资质证书'
			}
		}, {
			path: '/about/contact',
			name: 'contact',
			component: contact,
			meta: {
				title: '河北中经天平司法鉴定中心-联系我们',
				name: '联系我们'
			}
		}]
	}, {
		path: '/about',
		name: 'about',
		component: about,
		redirect: '/about/desc',
		children: [{
			path: '/about/desc',
			name: 'desc',
			component: desc,
			meta: {
				title: '河北中经天平司法鉴定中心-中心简介',
				name: '中心简介'
			}
		}, {
			path: '/about/license',
			name: 'license',
			component: license,
			meta: {
				title: '河北中经天平司法鉴定中心-资质证书',
				name: '资质证书'
			}
		}, {
			path: '/about/contact',
			name: 'contact',
			component: contact,
			meta: {
				title: '河北中经天平司法鉴定中心-联系我们',
				name: '联系我们'
			}
		}]
	}, {
		path: '/case',
		name: 'case',
		component: caseList,
		meta: {
			title: '河北中经天平司法鉴定中心-行业案例',
			name: '行业案例'
		}
	}, {
		path: '/case/:id',
		name: 'case-id',
		component: caseId,
		meta: {
			title: '河北中经天平司法鉴定中心-行业案例详情',
			name: '行业案例详情'
		}
	}]
}, {
	path: '/manage',
	name: 'manage',
	component: manage,
	meta: {
		title: '河北中经天平司法鉴定中心-后台管理-登录',
		name: '后台管理-登录'
	}
}, {
	path: '/admin',
	name: 'admin',
	component: admin,
	meta: {
		title: '河北中经天平司法鉴定中心-后台管理',
		name: '后台管理'
	},
	redirect: '/admin/news',
	children: [{
		path: '/admin/news',
		name: 'news',
		component: news_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-新闻动态管理',
			name: '新闻动态管理'
		}
	}, {
		path: '/admin/issue',
		name: 'issue',
		component: news_issue,
		meta: {
			title: '河北中经天平司法鉴定中心-新闻动态发布',
			name: '新闻动态发布'
		}
	}, {
		path: '/admin/standard',
		name: 'standard',
		component: standard_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-鉴定标准管理',
			name: '鉴定标准管理'
		}
	}, {
		path: '/admin/edit',
		name: 'edit',
		component: standard_edit,
		meta: {
			title: '河北中经天平司法鉴定中心-鉴定标准编辑',
			name: '鉴定标准编辑'
		}
	}, {
		path: '/admin/data',
		name: 'data',
		component: data_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-资料下载管理',
			name: '资料下载管理'
		}
	}, {
		path: '/admin/case',
		name: 'case',
		component: case_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-行业案例管理',
			name: '行业案例管理'
		}
	}, {
		path: '/admin/upload',
		name: 'upload',
		component: case_upload,
		meta: {
			title: '河北中经天平司法鉴定中心-行业案例编辑',
			name: '行业案例编辑'
		}
	}, {
		path: '/admin/certification',
		name: 'certification',
		component: certification_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-资质证书管理',
			name: '资质证书管理'
		}
	}, {
		path: '/admin/clerk',
		name: 'clerk',
		component: clerk_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-调解员管理',
			name: '调解员管理'
		}
	}, {
		path: '/admin/apply',
		name: 'apply',
		component: apply_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-鉴定申请管理',
			name: '鉴定申请管理'
		}
	}, {
		path: '/admin/message',
		name: 'message',
		component: message_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-咨询留言管理',
			name: '咨询留言管理'
		}
	}, {
		path: '/admin/paper',
		name: 'paper',
		component: paper_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-报告申请管理',
			name: '报告申请管理'
		}
	}, {
		path: '/admin/swiper',
		name: 'swiper',
		component: swiper_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-轮播图管理',
			name: '轮播图管理'
		}
	}, {
		path: '/admin/config',
		name: 'config',
		component: config_admin,
		meta: {
			title: '河北中经天平司法鉴定中心-网站设置',
			name: '网站设置'
		}
	}]
}]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
