<template>
	<div class="custom-table">
		<div class="page-operation" :style="tableFilter.length > 0?'':'border:none;'">
			<div class="page-number">
				{{pageConfig.totalWord}}（{{pageConfig.total}}）
			</div>
			<div class="page-button" v-if="buttonGroup.length>0">
				<div class="page-button-item" v-for="(item,index) in buttonGroup" :key="index">
					<el-button :type="item.type" :size="item.size" :plain="item.plain" :icon="item.icon"
						@click="buttonClick(item.sign)">{{item.word}}</el-button>
				</div>
			</div>
		</div>
		<div class="page-filtrate" v-if="tableFilter.length>0">
			<el-form ref="filterForm" :model="filterValue">
				<el-form-item v-for="(item,index) in tableFilter" :key="index" :prop="item.prop">
					<div :class="'page-filtrate-item '+(item.size=='small'?'page-filtrate-small':'')">
						<el-input v-if="item.type=='el-input'" type="text" v-model="filterValue[item.prop]"
							:placeholder="item.placeholder" suffix-icon="el-icon-search" clearable
							@input="filterChange">
						</el-input>
						<el-select v-if="item.type=='el-select'" v-model="filterValue[item.prop]"
							:placeholder="item.placeholder" clearable @change="filterChange">
							<el-option v-for="(tab,tabIndex) in item['selectFilter']" :key="tabIndex" :label="tab.label"
								:value="tab.value">
							</el-option>
						</el-select>
						<el-date-picker v-if="item.type=='date-picker'" v-model="filterValue[item.prop]" type="date"
							:placeholder="item.placeholder" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable
							@change="filterChange">
						</el-date-picker>
						<el-date-picker v-if="item.type=='date-range'" v-model="filterValue[item.prop]" type="daterange"
							range-separator="至" :start-placeholder="item.startPlaceholder"
							:end-placeholder="item.endPlaceholder" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
							@change="filterChange">
						</el-date-picker>
					</div>
				</el-form-item>
				<el-button type="primary" @click="filterFormReset">重置</el-button>
			</el-form>
		</div>
		<div class="page-table" v-if="tableData.length>0" :style="'height: '+tableHeight+'px;'">
			<el-table ref="filterTable" :data="tableData" style="width: 100%" :border="tableConfig.border"
				v-if="tableHeight > 0" :height="tableHeight" @selection-change="handleSelectionChange"
				:header-cell-style="{background:'#eaf0fa'}">
				<el-table-column type="selection" width="55" v-if="tableConfig['selection']">
				</el-table-column>
				<el-table-column v-for="(item,index) in tableConfig['tableHeader']" :key="index" :prop="item.prop"
					:label="item.label" :width="item.width" :show-overflow-tooltip="tableConfig.showOverflowTooltip">
					<template v-slot="scope">
						<slot :row="scope.row" :column="scope.column">
							<span v-if="!item.template">{{scope.row[item.prop]}}</span>
							<div class="scope-image-breviary" v-if="item.prop==='carouselImg'">
								<el-image style="height: 95px;" :src="scope.row.carouselImg"
									:preview-src-list="[scope.row.carouselImg]">
								</el-image>
							</div>
							<div class="scope-image-breviary" v-if="item.prop==='mediatorPhoto'">
								<el-image style="height: 95px;" :src="scope.row.mediatorPhoto"
									:preview-src-list="[scope.row.mediatorPhoto]">
								</el-image>
							</div>
							<div class="scope-image-breviary" v-if="item.prop==='imgUrl'">
								<el-image style="height: 95px;" v-for="(tab,tabIndex) in scope.row.imgUrl.split(',')"
									:key="tabIndex" :src="tab" :preview-src-list="scope.row.imgUrl.split(',')">
								</el-image>
							</div>
							<div class="scope-image-breviary" v-if="item.prop==='videoUrl'">
								<video :src="scope.row.videoUrl" width="240px" height="95px" controls></video>
							</div>
							<span v-if="item.prop==='consultStatus'">
								{{scope.row.status == 1 ?'已处理':'未处理'}}
							</span>
							<span v-if="item.prop==='applyStatus'">
								{{scope.row.status == 1 ?'已处理':'未处理'}}
							</span>
							<span v-if="item.prop==='paperStatus'">
								{{scope.row.status == 1 ?'已处理':'未处理'}}
							</span>
						</slot>
					</template>
				</el-table-column>
				<el-table-column label="操作" v-if="tableConfig['tableEvent'].length>0">
					<template slot-scope="scope">
						<div class="scope-button" v-if="tableConfig.auctionMenu">
							<div :class="'scope-button-item scope-button-'+(!item.color?'bue':item.color)"
								v-for="(item,index) in tableConfig['tableEvent']" :key="index"
								@click="scopeButtonClick(scope.row,item.sign)"
								v-if="(item.sign == 'dispose' && scope.row.status == 0) || item.sign!='dispose'">
								<i :class="item.icon"></i>
								{{item.name}}
							</div>
						</div>
						<div class="scope-button" v-else>
							<div :class="'scope-button-item scope-button-'+(!item.color?'bue':item.color)"
								v-for="(item,index) in tableConfig['tableEvent']" :key="index"
								@click="scopeButtonClick(scope.row,item.sign)">
								<i :class="item.icon"></i>
								{{item.name}}
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="page-table" :style="'height: '+tableHeight+'px;'" v-else>
			<div class="empty-content">
				<el-empty :description="tableConfig.emptyDescription">
				</el-empty>
			</div>
		</div>
		<div class="page-pager" v-if="pageConfig.total>0">
			<el-pagination background layout="total, prev, pager, next" :total="pageConfig.total" :pager-count="11"
				:page-size="pageConfig.pageSize" :current-page="pageConfig.currentPage" @current-change="pageChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'custom-table',
		props: ['pageConfig', 'multipleSelection', 'buttonGroup', 'filterValue', 'tableFilter', 'tableConfig',
			'tableData'
		],
		data() {
			return {
				tableHeight: 0
			};
		},
		methods: {
			pageChange(e) {
				this.$props.pageConfig.currentPage = e
				this.$emit('pageChange')
			},
			filterChange() {
				this.$emit('filterChange')
			},
			buttonClick(sign) {
				this.$emit('buttonClick', sign)
			},
			scopeButtonClick(row, sign) {
				this.$emit('scopeButtonClick', row, sign)
			},
			handleSelectionChange(val) {
				this.$props.multipleSelection = val
			},
			filterFormReset() {
				this.$refs.filterForm.resetFields()
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			let obj = document.getElementsByClassName('custom-table')[0]
			let height = obj.offsetHeight
			this.tableHeight = height - (this.$props.tableFilter.length > 0 ? 180 : 130)
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.custom-table {
		width: 100%;
		height: 100%;
	}

	.page-operation,
	.page-filtrate,
	.page-pager {
		width: 100%;
		height: 60px;
		display: flex;
		box-sizing: border-box;
		padding: 0px @vue-spacing-row-base;
	}

	.page-operation {
		justify-content: space-between;
		border-bottom: 1px solid @vue-border-color;

		.page-number {
			height: 100%;
			display: flex;
			align-items: center;
		}

		.page-button {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.page-button-item {
				margin-left: @vue-spacing-row-sm;
			}
		}
	}

	.page-filtrate {
		.el-form {
			display: flex;
			align-items: center;

			.el-form-item {
				margin: 0px;
			}

			.el-button {
				height: 39px;
			}
		}

		.page-filtrate-item {
			height: 100%;
			display: flex;
			align-items: center;
			margin-right: @vue-spacing-row-sm;

			.el-input {
				width: 325px;
				font-size: @vue-font-size-min;
			}

			.el-select {
				width: 230px;
				font-size: @vue-font-size-min;

				.el-input {
					width: 230px;
					font-size: @vue-font-size-min;
				}
			}

			.el-date-editor {
				width: 240px;
				font-size: @vue-font-size-min;
			}
		}

		.page-filtrate-small {
			.el-select {
				width: 170px;

				.el-input {
					width: 170px;
				}
			}

			.el-input {
				width: 230px;
			}
		}

		.page-filtrate-residue {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;

			.page-filtrate-times {
				height: 100%;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-sm - 2;
				color: @vue-text-color-grey;

				span {
					color: @vue-text-color-active;
					font-size: @vue-font-size-sm + 2;
				}
			}

			.page-filtrate-choice {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				margin-left: @vue-spacing-row-sm;

				.page-filtrate-choice-image {
					width: 36px;
					height: 36px;
					border-radius: @vue-border-radius-circle;
					background: #f0f5ff;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 20px;
						height: 20px;
					}
				}

				.page-filtrate-choice-list {
					width: 350px;
					background: @vue-bg-color;
					position: absolute;
					top: 60px;
					right: 0px;
					z-index: 999;
					box-shadow: 0px 0px 10px @vue-border-color;
					border-radius: @vue-border-radius-lg;
					display: none;

					.page-filtrate-choice-item {
						width: 100%;
						height: 50px;
						border-bottom: 1px solid @vue-border-color;
						display: flex;
						justify-content: space-between;
						padding: 0px @vue-spacing-row-sm;
						box-sizing: border-box;

						.page-filtrate-choice-icon {
							width: 50px;
							height: 50px;
							// background: url(./assets/choice-1.png) no-repeat;
							background-size: 32px 32px;
							background-position: center center;
							display: flex;
							justify-content: center;
							align-items: center;
							color: @vue-text-color-inverse;
						}

						.page-filtrate-choice-name,
						.page-filtrate-choice-number {
							height: 100%;
							display: flex;
							align-items: center;
							font-size: @vue-font-size-sm - 2;
						}

						.page-filtrate-choice-name {
							width: 170px;
							color: @vue-text-color;
						}

						.page-filtrate-choice-number {
							width: 130px;
							color: @vue-text-color-grey;
						}
					}

					.page-filtrate-choice-item:last-child {
						border: none;
					}

					.page-filtrate-choice-item:hover {
						background: @vue-bg-color-grey;
						cursor: pointer;
					}

					.page-filtrate-record {
						width: 100%;
						padding: @vue-spacing-col-base @vue-spacing-row-base;
						box-sizing: border-box;

						a {
							width: 120px;
							height: 36px;
							background: @vue-bg-color-active;
							border-radius: 18px;
							display: flex;
							justify-content: center;
							align-items: center;
							color: @vue-text-color-inverse;
							font-size: @vue-font-size-sm - 2;
						}
					}
				}
			}

			.page-filtrate-choice:hover {
				cursor: pointer;

				.page-filtrate-choice-list {
					display: block;
				}
			}
		}
	}

	.page-table {
		width: 100%;
		box-sizing: border-box;
		padding: 0px @vue-spacing-row-base;
		overflow: auto;

		.empty-content {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: @vue-spacing-col-lg*2;
			box-sizing: border-box;

			.empty-cont {
				font-size: @vue-font-size-min + 2;
				color: @vue-text-color-grey;

				.el-button {
					margin-left: @vue-spacing-row-base;
				}
			}
		}
	}

	.page-pager {
		justify-content: flex-end;
		align-items: center;
	}

	.scope-normal {
		color: @vue-text-color;
	}

	.scope-blue {
		color: @vue-text-color-active;
		text-decoration: underline;
		cursor: pointer;
	}

	.scope-grey {
		color: @vue-text-color-grey;
	}

	.scope-green {
		color: #53c41a;
	}

	.scope-red {
		color: #bd0101;
	}

	.scope-step {
		height: 100%;
		display: flex;
		align-items: center;

		img {
			width: 14px;
			height: 14px;
			margin-right: @vue-spacing-row-sm;
		}

		.scope-point {
			width: 14px;
			height: 14px;
			border-radius: @vue-border-radius-circle;
			margin-right: @vue-spacing-row-sm;
		}

		.scope-point-blue {
			background: @vue-bg-color-active;
		}

		.scope-point-green {
			background: @vue-bg-color-green;
		}

		.scope-point-red {
			background: @vue-bg-color-red;
		}

	}

	.scope-button {
		display: flex;

		.scope-button-item {
			cursor: pointer;
			margin-right: @vue-spacing-row-base;
		}

		.scope-button-grey {
			color: @vue-text-color-grey;
		}

		.scope-button-blue {
			color: @vue-text-color-active;
		}

		.scope-button-green {
			color: @vue-text-color-green;
		}

		.scope-button-red {
			color: @vue-text-color-red;
		}
	}

	.space-button {
		width: 100%;

		.space-button-item {
			width: 100%;
			height: 20px;
			display: flex;
			align-items: center;
			cursor: pointer;

			i {
				margin-right: @vue-spacing-row-sm / 2;
			}
		}

		.space-button-grey {
			color: @vue-text-color-grey;
		}

		.space-button-blue {
			color: @vue-text-color-active;
		}

		.space-button-green {
			color: @vue-text-color-green;
		}

		.space-button-red {
			color: @vue-text-color-red;
		}
	}

	.scope-image-breviary {
		width: 380px;
		display: block;
	}
</style>
