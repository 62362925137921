<template>
	<div class="page">
		<pubheader :config="config"></pubheader>
		<banner v-if="$store.state.current == '/home'" :swiper="swiper" :blockChainHeight="blockChainHeight"
			:blockChainList="blockChainList"></banner>
		<router-view />
		<pubfooter :config="config"></pubfooter>
		<tools :config="config"></tools>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				swiper: [],
				blockChainHeight: [],
				blockChainList: [],
				config: {
					erCodeOne: '',
					erCodeTwo: '',
					email: '',
					phone: '',
					recordNoJwga: '',
					recordNoJwxb: '',
					recordNoJicp: '',
					friendlyLink: '[]'
				}
			}
		},
		methods: {
			async fetchData() {
				let [swiper, blockChainHeight, blockChainList, config] = await Promise.all([
					this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/queryCarouselList'),
					this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/queryLedger'),
					this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/queryTransactionList'),
					this.$axios.get(this.$urlConfig.requestUrl + 'indexApi/getCompanyInfo')
				])
				if (swiper.data.code == 200) {
					this.swiper = swiper.data.data
				}
				if (blockChainHeight.data.code == 200) {
					this.blockChainHeight = blockChainHeight.data.data.seq.split('')
				}
				if (blockChainList.data.code == 200) {
					this.blockChainList = blockChainList.data.data
				}
				if (config.data.code == 200) {
					this.config = config.data.data
				}
			}
		},
		mounted() {
			this.fetchData()
		}
	}
</script>
<style lang="less">

</style>
