<template>
	<div class="news-page">
		<div class="fixation">
			<div class="category">
				<div class="title">
					<span>新闻</span>动态
				</div>
				<ul v-if="category.length>0">
					<li :class="categoryIndex == index ? 'category-selected' : ''" v-for="(item,index) in category"
						:key="index" @click="changeCategory(index)">
						<div class="selected-line" v-show="categoryIndex == index"></div>
						{{item.name}}
					</li>
				</ul>
			</div>
			<div class="content">
				<div class="bread">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>{{category[categoryIndex]['name']}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="article">
					<ul v-if="list.length>0">
						<li v-for="(item,index) in list" :key="index">
							<div class="pointer"></div>
							<router-link :to="{path:'/news/'+item.id}">
								<span>{{item.newsTitle}}</span>
								<span>{{item.createAt}}</span>
							</router-link>
						</li>
					</ul>
					<div class="pagination">
						<el-pagination background @current-change="handleCurrentChange" :current-page="currentPage4"
							layout="total, prev, pager, next, jumper" :total="total" :page-size="pageSize">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'IndexPage',
		data() {
			return {
				category: [{
					name: ''
				}],
				categoryIndex: 0,
				list: [],
				total: 0,
				pageSize: 10,
				currentPage4: 1
			}
		},
		methods: {
			handleCurrentChange(e) {
				this.currentPage4 = e
				this.fetchData()
			},
			async fetchCategory() {
				let {
					data
				} = await this.$axios.get(this.$urlConfig.requestUrl + 'findSystemDictonaryList', {
					params: {
						dictType: 3
					}
				})
				let category
				if (data.code == 200) {
					this.category = data.data
					this.fetchData()
				}
			},
			async fetchData() {
				let {
					data
				} = await this.$axios.post(this.$urlConfig.requestUrl + 'indexApi/findNewsList', {
					newsType: this.category[this.categoryIndex]['id'],
					pageNum: this.currentPage4,
					pageSize: this.pageSize
				})
				if (data.code == 200) {
					this.list = data.data.list
					this.total = data.data.total
				}
			},
			changeCategory(index) {
				this.categoryIndex = index
				this.currentPage4 = 1
				this.fetchData()
			}
		},
		mounted() {
			this.fetchCategory()
		}
	}
</script>

<style lang="less" scoped>
	.news-page {
		width: 100%;
		padding: @vue-spacing-col-lg*2 0px;
		box-sizing: border-box;
		background: #f6f8fc;
	}

	.fixation {
		display: flex;
		justify-content: space-between;

		.category {
			width: 240px;

			.title {
				width: 100%;
				height: 50px;
				background: #1a3071;
				border-radius: @vue-border-radius-base @vue-border-radius-base 0px 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: @vue-text-color-inverse;
				font-size: @vue-font-size-sm;
				font-weight: bold;
				letter-spacing: 2px;

				span {
					color: #d3b071;
				}
			}

			ul {
				width: 100%;

				li {
					width: 100%;
					height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #ebeef5;
					font-size: @vue-font-size-sm;
					position: relative;
					color: @vue-text-color;
					margin-bottom: @vue-spacing-col-sm;
					cursor: pointer;

					.selected-line {
						width: 8px;
						height: 40px;
						position: absolute;
						left: 0px;
						top: 5px;
						background: #1a3071;
						z-index: 1;
						border-radius: 0px 8px 8px 0px;
					}
				}

				.category-selected {
					color: #1a3071;
				}
			}
		}

		.content {
			width: calc(100% - 290px);
			height: 100%;
			background: @vue-bg-color;
			border-radius: @vue-border-radius-base;
			padding: 0px @vue-spacing-col-lg;
			box-sizing: border-box;
			box-shadow: 0px 0px 10px @vue-border-color;

			.bread {
				width: 100%;
				height: 50px;
				border-bottom: 1px solid @vue-border-color;
				display: flex;
				align-items: center;
			}

			.article {
				width: 100%;

				ul {
					width: 100%;

					li {
						width: 100%;
						height: 50px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.pointer {
							width: 8px;
							height: 8px;
							background: @vue-bg-color-gold;
							border-radius: @vue-border-radius-circle;
						}

						a {
							width: calc(100% - 20px);
							height: 100%;
							display: flex;
							justify-content: space-between;
							align-items: center;
							font-size: @vue-font-size-sm - 2;

							span:first-child {
								color: @vue-text-color;
							}

							span:last-child {
								color: @vue-text-color-grey;
							}
						}

						a:hover {
							span {
								color: @vue-text-color-active;
							}
						}
					}
				}

				.pagination {
					width: 100%;
					height: 100px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}
			}
		}
	}
</style>
