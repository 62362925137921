<template>
	<div class="product-content">
		<div class="block">
			<div class="block-title">
				<em>QUERY</em>司法存证验证
			</div>
			<div class="block-content">
				<p>
					<span>
						通过司法电子证据云、天平金信金融债权存证平台、天平物业管理平台、电子封条进行存证、取证保全的数据，将同步到法院电子证据平台、中经天平司法鉴定中心等区块节点。通过相应查询条件可以在法院电子证据平台查询到事前存证的证据，并对相应证据提供纸质报告。
					</span>
				</p>
				<div class="tabList">
					<div :class="'tab '+(tabIndex == index ? 'tab-selected' : '')" v-for="(item,index) in tabList"
						:key="index" @click="tabIndex = index, keyword = ''">
						{{item}}
					</div>
				</div>
				<div class="query">
					<div class="query-box">
						<el-input type="text" v-model="keyword"
							:placeholder="(tabIndex == 0 ? '请输入身份证或手机号' : (tabIndex == 1 ? '请输入区块链ID(地址)、存证HASH 或 交易HASH' : '请输入证书编号或凭证编号'))">
						</el-input>
					</div>
					<div class="query-hint">
						<span v-show="tabIndex == 0">
							温馨提示：<br>
							1、验证内容为：通过天平金信金融债权存证平台、天平物业管理平台进行存证的电子数据是否被篡改。<br>
							2、验证方式为：输入当事人身份证号或手机号，点击开始验证。<br>
							3、通过验证的条件为：当事人身份证号或手机号能匹配且在司法区块链节点中可以查到的存证数据。
						</span>
						<span v-show="tabIndex == 1">
							温馨提示：<br>
							1、验证内容为：通过中经天平司法链、至信链、音乐蜜蜂、像素蜜蜂、天平溯源进行存证的电子数据是否被篡改。<br>
							2、验证方式为：输入区块链ID(地址)/存证哈希/交易哈希，点击开始验证。<br>
							3、通过验证的条件为：区块链ID(地址)/存证哈希/交易哈希能匹配且在区块链节点中可以查到的存证数据。
						</span>
						<span v-show="tabIndex == 2">
							温馨提示：<br>
							1、验证内容为：通过国家授时中心、司法电子证据平台进行存证的数据是否被篡改。<br>
							2、验证方式为：输入证书编号或凭证编号，点击开始验证。<br>
							3、通过验证的条件为：证书编号或凭证编号能匹配且在区块链节点中可以查到的存证数据。
						</span>
					</div>
					<div class="query-button">
						<el-button type="primary" round @click="submit">开始验证</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="block">
			<div class="block-title">
				<em>VERIFICATION</em>法院核验入口
			</div>
			<div class="block-content">
				<div class="court">
					<div class="court-item" v-for="(item,index) in court" :key="index">
						<div class="court-province">
							{{item.province}}省核验平台
						</div>
						<el-select v-model="item.value" @change="courtChange" filterable clearable>
							<el-option v-for="(tab,tabIndex) in item['data']" :key="tabIndex" :label="tab.court"
								:value="tab.id">
								{{tab.court}}
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
		</div> -->
		<el-dialog :visible="flag" title="安全验证" width="600px" @close="flag = false">
			<el-form ref="elForm" :model="verification" label-width="120px">
				<el-form-item prop="identity" label="身份证号:">
					<el-input type="text" v-model="verification.identity" placeholder="请输入身份证号后六位"></el-input>
				</el-form-item>
				<el-form-item prop="code" label="核验码:" v-if="tabIndex == 0">
					<el-input type="text" v-model="verification.code" placeholder="请输入短信中的核验码"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" round @click="submitVerificationInfo">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog :visible="errorFlag" title="错误提示" width="400px" @close="errorFlag = false">
			<div class="error-type">
				<span v-show="errorType == 1">无法完成操作</span>
				<span v-show="errorType == 2">错误次数已达3次</span>
				<span v-show="errorType == 3">当日验证次数达到上限</span>
			</div>
			<div class="error-hint">
				<span v-show="errorType == 1">请输入验证信息</span>
				<span v-show="errorType == 2">今日禁止查询</span>
				<span v-show="errorType == 3">请明天再试</span>
			</div>
			<div class="error-button">
				<el-button type="primary" round @click="errorFlag = false">
					确定
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import court from '@/public/court.json'
	let map = {}
	for (let i = 0; i < court.length; i++) {
		let ai = court[i]
		if (!map[ai.province]) {
			map[ai.province] = [ai]
		} else {
			map[ai.province].push(ai)
		}
	}
	let courtArray = []
	Object.keys(map).forEach(key => {
		courtArray.push({
			province: key,
			data: map[key],
		})
	})
	export default {
		data() {
			return {
				tabList: [
					'金融/物业调解验证', '区块链验证', '证书验证'
				],
				tabIndex: 0,
				keyword: '',
				court: courtArray,
				flag: false,
				verification: {
					identity: '',
					code: ''
				},
				errorFlag: false,
				errorType: 1
			}
		},
		methods: {
			submit() {
				if (this.keyword == '') {
					if (this.tabIndex == 0) {
						this.$message({
							type: 'error',
							message: '请输入身份证或手机号',
							duration: 2000
						})
					}
					if (this.tabIndex == 1) {
						this.$message({
							type: 'error',
							message: '请输入区块链ID(地址)、存证HASH 或 交易HASH',
							duration: 2000
						})
					}
					if (this.tabIndex == 2) {
						this.$message({
							type: 'error',
							message: '请输入证书编号或凭证编号',
							duration: 2000
						})
					}
					return
				}
				if (this.tabIndex == 0) {
					this.$router.push({
						path: '/centre/result-1',
						query: {
							keyword: this.keyword
						}
					})
					return
				} else {
					this.$router.push({
						path: '/centre/result-2',
						query: {
							keyword: this.keyword
						}
					})
				}
				// this.flag = true
			},
			courtChange(e) {
				window.open(court[parseInt(e)]['url'])
			},
			submitVerificationInfo() {
				if (this.tabIndex == 0) {
					this.$router.push({
						path: '/centre/result-1',
						query: {
							keyword: this.keyword
						}
					})
				} else {
					this.$router.push({
						path: '/centre/result-2',
						query: {
							keyword: this.keyword
						}
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.product-content {
		width: 100%;

		.block {
			width: 100%;
			padding-bottom: @vue-spacing-col-lg;

			.block-title {
				width: 100%;
				height: 80px;
				display: flex;
				align-items: center;
				color: #0a2242;
				font-size: @vue-font-size-base;

				em {
					font-size: @vue-font-size-lg + 8;
					font-family: '宋体';
					font-weight: bold;
					margin-right: @vue-spacing-row-sm;
				}
			}

			.block-content {
				width: 100%;

				p {
					width: 100%;
					line-height: 32px;
					font-size: @vue-font-size-sm;
					color: @vue-text-color-grey;

					span {
						text-indent: 2em;
						display: inline-block;
					}

					img {
						display: block;
						margin: @vue-spacing-col-lg auto;
					}
				}

				.tabList {
					width: 100%;
					height: 50px;
					display: flex;
					justify-content: space-between;
					margin: @vue-spacing-col-lg*2 0px @vue-spacing-col-lg 0px;
					background: #f6f8fc;
					border-radius: @vue-border-radius-base;

					.tab {
						width: 33.3%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
					}

					.tab-selected {
						border-radius: @vue-border-radius-base;
						background: #1a3071;
						color: @vue-text-color-gold;
					}
				}

				.query {
					width: 100%;
					height: 370px;
					background: url(../../../assets/centre-query-1.png) no-repeat;
					background-size: 100% 100%;
					padding: @vue-spacing-col-lg*3 @vue-spacing-row-lg*5 0px @vue-spacing-row-lg*5;
					box-sizing: border-box;

					.query-box,
					.query-hint,
					.query-button {
						width: 100%;
						margin-bottom: @vue-spacing-col-lg;
					}

					.query-hint {
						line-height: 32px;
						font-size: @vue-font-size-sm - 2;
						color: @vue-text-color-grey;
					}

					.query-button {
						display: flex;
						justify-content: center;

						.el-button {
							width: 300px;
						}
					}
				}

				.court {
					width: 100%;
					margin-top: @vue-spacing-col-lg;
					display: flex;
					flex-wrap: wrap;

					.court-item {
						width: 30%;
						height: 170px;
						background: url(../../../assets/centre-query-2.jpg) no-repeat;
						background-size: 100% 100%;
						margin-right: 5%;
						margin-bottom: @vue-spacing-col-base*2;
						padding: 0px @vue-spacing-row-lg @vue-spacing-col-base @vue-spacing-row-lg;
						box-sizing: border-box;
						display: flex;
						align-items: flex-end;
						flex-wrap: wrap;
						align-content: flex-end;
						border-radius: 12px;
						overflow: hidden;

						.court-province {
							width: 100%;
							height: 40px;
							display: flex;
							justify-content: center;
							align-items: center;
							color: #333333;
						}

						.el-select {
							width: 100%;
						}
					}

					.court-item:nth-child(3n) {
						margin-right: 0px;
					}
				}
			}
		}

		.error-type,
		.error-hint,
		.error-button {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.error-type {
			font-size: @vue-font-size-sm + 2;
			font-weight: bold;
			color: @vue-text-color-active;
		}

		.error-hint {
			color: @vue-text-color-grey;
			font-size: @vue-font-size-sm;
		}

		.error-button {
			height: 60px;
			align-items: flex-end;

			.el-button {
				width: 140px;
			}
		}
	}
</style>