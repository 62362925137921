<template>
	<div class="admin-page">
		<custom-table :pageConfig="pageConfig" :multipleSelection="multipleSelection" :buttonGroup="buttonGroup"
			:filterValue="filterValue" :tableFilter="tableFilter" :tableConfig="tableConfig" :tableData="tableData"
			@pageChange="pageChange" @buttonClick="buttonClick" @filterChange="filterChange"
			@scopeButtonClick="scopeButtonClick">
		</custom-table>
		<el-dialog :visible="formFlag" title="编辑轮播图" @close="closeForm">
			<el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
				<el-form-item label="轮播图片" prop="carouselImg" required>
					<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="formData.carouselImg!=''" :src="formData.carouselImg" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="排序" prop="sortNo">
					<el-input type="number" placeholder="请输入排序" v-model="formData.sortNo"></el-input>
				</el-form-item>
				<el-form-item size="large">
					<el-button type="primary" @click="submitForm">提交</el-button>
					<el-button @click="resetForm">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'swiper',
		data() {
			return {
				pageConfig: {
					totalWord: '轮播图管理',
					total: 0,
					pageSize: 5,
					currentPage: 1,
				},
				multipleSelection: [],
				buttonGroup: [{
					type: 'primary',
					size: 'mini',
					plain: true,
					icon: 'el-icon-edit',
					sign: 'new',
					word: '添加轮播图'
				}],
				filterValue: {},
				tableFilter: [],
				tableConfig: {
					border: true,
					selection: false,
					showOverflowTooltip: true,
					tableHeader: [{
							prop: 'carouselImg',
							label: '轮播图片',
							width: 500,
							template: true
						},
						{
							prop: 'sortNo',
							label: '排序',
							width: 150
						},
						{
							prop: 'createAt',
							label: '创建时间'
						}
					],
					tableEvent: [{
							icon: 'el-icon-edit',
							name: '编辑',
							color: 'green',
							sign: 'edit'

						},
						{
							icon: 'el-icon-delete',
							name: '删除',
							color: 'red',
							sign: 'delete'
						}

					],
					emptyDescription: '当前没有数据',
				},
				tableData: [],
				formFlag: false,
				formData: {
					carouselImg: '',
					goodsId: '',
					sortNo: '',
					id: ''
				},
				rules: {
					goodsId: [],
				},
				goodsIdOptions: [],
				uploadUrl: ''
			};
		},
		methods: {
			submitForm() {
				this.$refs['elForm'].validate(valid => {
					if (valid) {
						this.saveSwiperInfo()
					}
				})
			},
			saveSwiperInfo() {
				this.$api.addBanner(this.formData, res => {
					if (res.code == 200) {
						this.fetchData()
						let message = ''
						if (this.formData.id == '') {
							message = '添加成功'
						} else {
							message = '修改成功'
						}
						this.$message({
							message: message,
							type: 'success',
							duration: 2000
						})
						this.closeForm()
					}
				})
			},
			resetForm() {
				this.$refs['elForm'].resetFields()
			},
			handleAvatarSuccess(res) {
				if (res.code == 200) {
					this.formData.carouselImg = res.data.fileUrl
				}
			},
			beforeAvatarUpload(file) {
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isLt5M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isLt5M;
			},
			pageChange() {
				this.fetchData()
			},
			buttonClick(sign) {
				if (sign == 'new') {
					this.formFlag = true
				}
			},
			filterChange(e) {
				this.fetchData()
			},
			scopeButtonClick(row, sign) {
				if (sign == 'delete') {
					this.deleteAbs(row.id)
				}
				if (sign == 'edit') {
					this.formData = row
					this.formFlag = true
				}
			},
			//删除轮播图
			deleteAbs(id) {
				this.$confirm('此操作将删除该轮播图, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.deleteBanner({
						id: id
					}, res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.fetchData()
						}
					})
				}).catch(() => {});
			},
			// 获取轮播图列表
			fetchData() {
				this.$api.bannerList({
					pageSize: this.pageConfig.pageSize,
					pageNum: this.pageConfig.currentPage
				}, res => {
					if (res.code == 200) {
						this.pageConfig.total = res.data.total
						this.tableData = res.data.list
					}
				})
			},
			closeForm() {
				this.formData = {
					url: '',
					goodsId: '',
					sortNo: ''
				}
				this.formFlag = false
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.uploadUrl = this.$api.requestUrl + 'uploadImg'
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.avatar-uploader .el-upload {
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
		border: 1px dashed #d9d9d9;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.line {
		width: 100%;
		height: 60px;
		border-bottom: 1px dashed @vue-border-color;
		display: flex;
		align-items: center;
		font-size: 16px;
	}

	.img-line {
		width: 100%;
		height: 300px;
		border-bottom: 1px dashed @vue-border-color;
		display: flex;
		align-items: center;
		font-size: 16px;
	}

	.address-list {
		width: 100%;

		.address-title {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			font-size: 16px;
		}

		.item {
			border: 1px solid @vue-border-color;
			box-sizing: border-box;
			padding: 0px 20px;
			margin-bottom: 20px;

			.item-name {
				width: 100%;
				height: 50px;
				display: flex;
				align-items: center;
			}

			.item-detail {
				width: 100%;
				line-height: 30px;
				padding-bottom: 10px;
				box-sizing: border-box;
			}
		}
	}
</style>
