<template>
	<div class="compile admin-page">
		<div class="page-title">
			案例信息编辑
		</div>
		<div class="page-content custom-scroll-bar">
			<el-form :model="caseForm" :rules="rules" ref="caseForm" label-width="150px" class="ruleForm">
				<el-form-item label="所属栏目" required>
					<el-cascader v-model="column" :props="props" :options="options" @change="handleChange"
						style="width: 400px;">
					</el-cascader>
				</el-form-item>
				<el-form-item label="案例名称" prop="title">
					<el-input v-model="caseForm.title" type="text" placeholder="请输入案例名称"></el-input>
				</el-form-item>
				<div class="upload-video">
					<div class="upload-key">
						<span>*</span>上传视频
					</div>
					<div class="upload-value">
						<el-upload class="avatar-uploader" :action="uploadFileUrl" :show-file-list="false"
							:on-success="handleVideoSuccess" :before-upload="beforeVideoUpload"
							:on-progress="onProgress" accept=".mp4">
							<video v-if="caseForm.videoUrl!=''" :src="caseForm.videoUrl" controls class="video"></video>
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<el-progress style="width: 150px;" v-if="percent>0" :percentage="percent"></el-progress>
					</div>
				</div>
				<div class="upload-image">
					<div class="upload-key">
						<span>*</span>上传图片
					</div>
					<div class="upload-value">
						<el-upload :action="uploadFileUrl" list-type="picture-card" :on-success="handleImageSuccess"
							:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="fileList"
							:multiple="true" accept="image/*">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</div>
				</div>
				<div class="rich-text">
					<div class="key">
						<span>*</span>案例介绍：
					</div>
					<div class="value">
						<tinymce id="richtext" v-model="richText" :other_options="init"></tinymce>
					</div>
				</div>
				<div class="login-button">
					<el-button type="primary" :loading="loading" @click="saveCaseInfo">保存案例信息</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import tinymce from 'vue-tinymce-editor'
	export default {
		name: 'upload',
		components:{
			tinymce
		},
		data() {
			return {
				column: [],
				options: [],
				props: {
					label: 'name',
					value: 'id',
					children: 'childrens'
				},
				caseForm: {
					title: '',
					videoUrl: '',
					image_list: []
				},
				percent: 0,
				richText: '',
				rules: {
					title: [{
						required: true,
						trigger: 'blur',
						message: '请输入案例名称'
					}]
				},
				uploadFileUrl: '',
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				loading: false,
				init: {
					language_url: "https://sfjdzx.org.cn/zh_CN.js", // 语言包的路径
					language: "zh_CN", //语言P
					height: 600, //编辑器高度
					// plugins: this.plugins,
					toolbar: [
						'searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample',
						'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'
					],
					branding: false,
					fontsize_formats: "8px 10px 12px 13px 14px 15px 16px 17px 18px 19px 20px 24px 36px",
					font_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings;NotoSansSC-Regular=NotoSansSC-Regular",
					automatic_uploads: true,
					images_upload_credentials: true,
					images_upload_handler: (blobInfo, success, failure, progress) => {
						var xhr, formData;
						xhr = new XMLHttpRequest();
						xhr.withCredentials = false;
						xhr.open('POST', this.$api.requestUrl + 'uploadImg');
					
						xhr.upload.onprogress = function(e) {
							progress(e.loaded / e.total * 100);
						}
					
						xhr.onload = function() {
							var json;
							if (xhr.status == 403) {
								failure('HTTP Error: ' + xhr.status, {
									remove: true
								});
								return;
							}
							if (xhr.status < 200 || xhr.status >= 300) {
								failure('HTTP Error: ' + xhr.status);
								return;
							}
							json = JSON.parse(xhr.responseText);
							if (!json || typeof json.data.fileUrl != 'string') {
								failure('Invalid JSON: ' + xhr.responseText);
								return;
							}
							success(json.data.fileUrl);
						};
					
						xhr.onerror = function() {
							failure('Image upload failed due to a XHR Transport error. Code: ' + xhr
								.status);
						}
					
						formData = new FormData();
						formData.append('file', blobInfo.blob(), blobInfo.filename());
					
						xhr.send(formData);
					},
					fileList: []
				}
			};
		},
		methods: {
			// 上传视频成功
			handleVideoSuccess(res, file) {
				if (res.code == 200) {
					this.caseForm.videoUrl = res.data.fileUrl
				}
			},
			// 上传视频前
			beforeVideoUpload(file) {
				const isLt300M = file.size / 1024 / 1024 < 300;
				if (!isLt300M) {
					this.$message.error('上传视频大小不能超过 300MB!');
				}
				return isLt300M;
			},
			// 图片列表移除
			handleRemove(file, fileList) {
				this.disposeFileList(fileList)
			},
			// 图片列表预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleImageSuccess(res, file, fileList) {
				if (res.code == 200) {
					this.disposeFileList(fileList)
				}
			},
			disposeFileList(fileList) {
				this.fileList = fileList
				let array = new Array()
				for (var i = 0; i < fileList.length; i++) {
					if (fileList[i].response.data) {
						array.push(fileList[i].response.data.fileUrl)
					}
				}
				this.caseForm.image_list = array
			},
			// 上传图片前
			beforeImageUpload(file) {
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isLt5M) {
					this.$message.error('上传藏品大小不能超过 5MB!');
				}
				return isLt5M;
			},
			onProgress(e) {
				this.percent = parseInt(e.percent)
			},
			saveCaseInfo() {
				this.$refs.caseForm.validate(valid => {
					if (valid) {
						this.loading = true
						this.$api.caseSave({
							id: this.id,
							category: this.column[0],
							title: this.caseForm.title,
							videoUrl: this.caseForm.videoUrl,
							imgUrl: this.caseForm.image_list.join(','),
							content: this.richText
						}, res => {
							this.loading = false
							if (res.code == 200) {
								this.$message({
									type: 'success',
									duration: 2000,
									message: (this.id == '' ? '新增' : '编辑') + '案例成功！',
									onClose: () => {
										this.$router.back()
									}
								})
							}
						})
					}
				})
			},
			handleChange() {

			},
			fetchCategory() {
				this.$api.findSystemDictonaryList({
					dictType: 5
				}, res => {
					if (res.code == 200) {
						this.options = res.data
					}
				})
			},
			fetchData() {
				this.$api.caseDetail({
					id: this.id
				}, res => {
					if (res.code == 200) {
						this.column = [res.data.category]
						let imgList = res.data.imgUrl.split(',')
						this.caseForm = {
							title: res.data.title,
							videoUrl: res.data.videoUrl,
							image_list: imgList
						}
						this.richText = res.data.content
						let array = new Array()
						for (let i = 0; i < imgList.length; i++) {
							let obj = {
								url: imgList[i]
							}
							array.push(obj)
						}
						this.fileList = array
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.id = this.$route.query.id
			this.uploadFileUrl = this.$api.requestUrl + 'uploadImg'
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchCategory()
			this.$message({
				type: 'warning',
				message: '内容编辑器第一次加载需要时间，请稍候...'
			})
			if (this.id != '') {
				this.fetchData()
			} else {
				this.flag = true
			}
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.compile {
		overflow: hidden;
	}

	.page-title {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		padding: 0px @vue-spacing-row-sm;
		box-sizing: border-box;
		background: @vue-bg-color-grey;
	}

	.page-content {
		width: 100%;
		height: calc(100% - 50px);
		padding: @vue-spacing-col-base @vue-spacing-row-base;
		box-sizing: border-box;
		overflow: auto;
	}

	.upload-video,
	.upload-image,
	.upload-datum,
	.rich-text {
		display: flex;
		margin-bottom: @vue-spacing-col-lg;
	}

	.upload-key,
	.key {
		width: 150px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: @vue-spacing-row-sm + 4;
		box-sizing: border-box;
		font-size: @vue-font-size-sm - 2;
		color: @vue-text-color;

		span {
			color: @vue-text-color-red;
		}
	}

	.upload-value,
	.value {
		width: calc(100% - 450px);
	}

	.el-input,
	.el-select,
	.el-date-editor {
		width: 400px;
	}

	.avatar-uploader .el-upload {
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
		border: 1px dashed #d9d9d9;
		box-sizing: border-box;
		border-radius: @vue-border-radius-base;
		background: #fbfdff;
	}

	.avatar-uploader-icon:hover {
		border-color: #409EFF;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}

	.video {
		width: 148px;
		height: 148px;
		display: block;
	}

	.login-button {
		padding-left: 150px;
		box-sizing: border-box;
		margin-bottom: @vue-spacing-col-lg*2;

		.el-button {
			width: 200px;
		}
	}
</style>
