import Vue from 'vue'
import Vuex from 'vuex'
import pubFunction from '@/public/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: '',
		hasLogin: false,
		admToken: '',
		admLoginFlag: false,
		current:'/home'
	},
	getters: {},
	mutations: {
		setToken(token) {
			state.hasLogin = true
			state.token = token
			pubFunction.setStorage('page_token', token)
		},
		setAdmToken(state, admToken) {
			state.admLoginFlag = true
			state.admToken = admToken
			pubFunction.setStorage('adm_token', admToken)
		},
		logout() {
			state.hasLogin = false
			state.token = ''
			localStorage.removeItem('page_token')
		},
		admLogout(state) {
			state.admLoginFlag = false
			state.admToken = ''
			localStorage.removeItem('adm_token')
			window.location.href = window.location.origin + '/manage'
			window.reload()
		}
	},
	actions: {},
	modules: {}
})
